import { configureStore } from '@reduxjs/toolkit';
import vibesetReducer from './slice/vibeset';
import createaccountReducer from './slice/createaccount';
import paymentdetailReducer from './slice/paymentdetail';
import billingDetailReducer from './slice/billingdetail';
import loginReducer from './slice/login';
import toastReducer from './slice/toastSlice';
import subscriptiondetailsReducer from './slice/subscriptiondetails';
import profileReducer from './slice/profile';
import clickedRouteReducer from './slice/clickedRoute';

export const store = configureStore({
  reducer: {
    vibeset: vibesetReducer,
    createaccount: createaccountReducer,
    paymentdetail: paymentdetailReducer,
    billingdetail: billingDetailReducer,
    login: loginReducer,
    toast: toastReducer,
    subscriptiondetails: subscriptiondetailsReducer,
    profile: profileReducer,
    clickedRoute: clickedRouteReducer
  }
});
