import { createSlice } from '@reduxjs/toolkit';
import apiConfig from '../../composable/api.json';
import { createAPIUtils } from './apiUtils';

const initialStateValue = {
  isLoading: false,
  formData: {
    mobile: '',
    dob: '',
    dj: ''
  },
  isError: false,
  tokenID: '',
  loading: false,
  errorObject: '',
  error: null,
  fieldErrors: ''
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: { ...initialStateValue },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    updateLoader: (state, action) => {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setFieldError(state, action) {
      const fieldName = action.payload;
      state.fieldErrors = fieldName;
    },
    resetFieldErrors(state) {
      state.fieldErrors = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isError = true;
    });
  }
});

export const {
  updateFormData,
  updateLoader,
  setError,
  setFieldError,
  resetFieldErrors,
  setFromSubscriptionPage
} = profileSlice.actions;

const apiUtils = createAPIUtils({
  setFieldError
});

const { createAPIAsyncThunk } = apiUtils;

export const login = createAPIAsyncThunk('login', apiConfig?.login);
export const retrieveProfile = createAPIAsyncThunk('retrieveProfile', apiConfig?.retrieveProfile);
export const updateProfile = createAPIAsyncThunk('updateProfile', apiConfig?.updateProfile);
export default profileSlice.reducer;