import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { addGuidedMix, setCurrentPage } from '@/redux/slice/vibeset';

import { Box, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import happyDescription from '@/assets/styles/img/happy_description.svg';
import happyFace from '@/assets/styles/img/happy_face.svg';
import dashfaceOutline from '@/assets/styles/img/dashface-outline.svg';
import dashface from '@/assets/styles/img/dash-face.svg';
import skullOutline from '@/assets/styles/img/skull-outline.svg';
import skull from '@/assets/styles/img/skull.svg';
import hipsterOutline from '@/assets/styles/img/hipster-outline.svg';
import hipsterFace from '@/assets/styles/img/hipster-face.svg';
import confusedOutline from '@/assets/styles/img/confused-outline.svg';
import confusedFace from '@/assets/styles/img/confused-face.svg';

import Letsvibebutton from '@/components/layout/Button/Letsvibe';
import Text from '@/components/new/Text';

import { BPM_RANGE, BUILD_SET, LIST_NATURAL, QUICK_MIX } from '@/constants/PageName';

function FeelItOut() {
  const dispatch = useDispatch();
  const vibeSetGuidedMix = useSelector((state) => state.vibeset?.guidedMix);
  const vibeSetMode = useSelector((state) => state.vibeset?.mode);
  const [feel, setFeel] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    setFeel(vibeSetGuidedMix?.valence);
  }, []);
  const handleLetsVibeClick = (val) => {
    if (vibeSetMode == QUICK_MIX) {
      dispatch(setCurrentPage(LIST_NATURAL));
    } else {
      dispatch(setCurrentPage(BUILD_SET));
    }
  };

  // Function to navigate to a specific page
  const goBackHandler = () => {
    dispatch(setCurrentPage(BPM_RANGE));
  };

  // Function to handle setting feel value
  const setFeelHandler = (value) => {
    setFeel(value);
    const dataGuided = {
      genre: vibeSetGuidedMix?.genre,
      bpm: vibeSetGuidedMix?.bpm,
      email: vibeSetGuidedMix?.email,
      song_artist: vibeSetGuidedMix?.song_artist,
      valence: value.toString()
    };
    dispatch(addGuidedMix(dataGuided));
  };
  return (
    // <>
    <Box className='child-main fade-in one text-white'>
      <Box className='feel-it' sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} className='feel-it-main'>
          <Grid item xs={12} md={6}>
            <Box>
              <Box>
                <Text.Title tag="h1" variant='md'>{t('setlist-guided.feel')}</Text.Title>
                <Text.Lead>{t('setlist-guided.whatsTheVibe')}</Text.Lead>
              </Box>
              <Box className='mt-btn-box d-flex'>
                <Letsvibebutton
                  className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
                  onClick={() => goBackHandler()}
                >
                  {t('common.back')}
                </Letsvibebutton>
                <Letsvibebutton
                  onClick={handleLetsVibeClick}
                  className={`btn-text-blue btn-bg-teal-blue primary ${feel > 0 ? '' : 'btn-color-change'}`}
                  disabled={feel > 0 ? false : true}
                >
                  {t('setlist-guided.match')} <ArrowForwardIcon />
                </Letsvibebutton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='mood'>
              <div className='grid-container'>
                {Array.from({ length: 168 }, (_, index) => (
                  <div key={index} className='grid-item'></div>
                ))}
              </div>
              <Box className='feel-it-out-checkbox'>
                <Box className='valence-text rotate'>
                  <Box className='front-box'>
                    <Box className='innerbox' onClick={() => setFeelHandler(1)}>
                      <img loading='lazy' className='outer-line' src={happyDescription} alt='' />
                      <img
                        loading='lazy'
                        className={`happy-face ${feel == 1 ? 'selected-mood' : ''}`}
                        src={happyFace}
                        alt=''
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className='ethreal rotate'>
                  <Box className='front-box'>
                    <Box className='innerbox' onClick={() => setFeelHandler(2)}>
                      <img loading='lazy' className='outer-line' src={dashfaceOutline} alt='' />
                      <img
                        loading='lazy'
                        className={`happy-face ${feel == 2 ? 'selected-mood' : ''}`}
                        src={dashface}
                        alt=''
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className='moody-wrapper rotate'>
                  <Box className='front-box'>
                    <Box className='innerbox' onClick={() => setFeelHandler(3)}>
                      <img loading='lazy' className='outer-line' src={skullOutline} alt='' />
                      <img
                        loading='lazy'
                        className={`happy-face ${feel == 3 ? 'selected-mood' : ''}`}
                        src={skull}
                        alt=''
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className='vibey-wrapper rotate'>
                  <Box className='front-box'>
                    <Box className='innerbox' onClick={() => setFeelHandler(4)}>
                      <img loading='lazy' className='outer-line' src={hipsterOutline} alt='' />
                      <img
                        loading='lazy'
                        className={`happy-face ${feel == 4 ? 'selected-mood' : ''}`}
                        src={hipsterFace}
                        alt=''
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className='intense-wrapper rotate'>
                  <Box className='front-box'>
                    <Box className='innerbox' onClick={() => setFeelHandler(5)}>
                      <img loading='lazy' className='outer-line' src={confusedOutline} alt='' />
                      <img
                        loading='lazy'
                        className={`happy-face ${feel == 5 ? 'selected-mood' : ''}`}
                        src={confusedFace}
                        alt=''
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
    //  </>
  );
}

export default FeelItOut;
