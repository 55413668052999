import React from 'react';
import PropTypes from 'prop-types';

const Exclusive = ({ className }) => (
  <svg viewBox="0 0 44 45" fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path d='M6 10.6014C7.06087 10.6014 8.07828 10.18 8.82843 9.42981C9.57857 8.67966 10 7.66225 10 6.60138C10 5.54051 9.57857 4.5231 8.82843 3.77295C8.07828 3.02281 7.06087 2.60138 6 2.60138C4.93913 2.60138 3.92172 3.02281 3.17157 3.77295C2.42143 4.5231 2 5.54051 2 6.60138C2 7.66225 2.42143 8.67966 3.17157 9.42981C3.92172 10.18 4.93913 10.6014 6 10.6014ZM8 40.6014C9.5913 40.6014 11.1174 39.9692 12.2426 38.844C13.3679 37.7188 14 36.1927 14 34.6014C14 33.0101 13.3679 31.484 12.2426 30.3587C11.1174 29.2335 9.5913 28.6014 8 28.6014C6.4087 28.6014 4.88258 29.2335 3.75736 30.3587C2.63214 31.484 2 33.0101 2 34.6014C2 36.1927 2.63214 37.7188 3.75736 38.844C4.88258 39.9692 6.4087 40.6014 8 40.6014ZM36 42.6014C37.5913 42.6014 39.1174 41.9692 40.2426 40.844C41.3679 39.7188 42 38.1927 42 36.6014C42 35.0101 41.3679 33.484 40.2426 32.3587C39.1174 31.2335 37.5913 30.6014 36 30.6014C34.4087 30.6014 32.8826 31.2335 31.7574 32.3587C30.6321 33.484 30 35.0101 30 36.6014C30 38.1927 30.6321 39.7188 31.7574 40.844C32.8826 41.9692 34.4087 42.6014 36 42.6014ZM20 26.6014C22.1217 26.6014 24.1566 25.7585 25.6569 24.2582C27.1571 22.7579 28 20.7231 28 18.6014C28 16.4796 27.1571 14.4448 25.6569 12.9445C24.1566 11.4442 22.1217 10.6014 20 10.6014C17.8783 10.6014 15.8434 11.4442 14.3431 12.9445C12.8429 14.4448 12 16.4796 12 18.6014C12 20.7231 12.8429 22.7579 14.3431 24.2582C15.8434 25.7585 17.8783 26.6014 20 26.6014ZM32 10.6014C33.0609 10.6014 34.0783 10.18 34.8284 9.42981C35.5786 8.67966 36 7.66225 36 6.60138C36 5.54051 35.5786 4.5231 34.8284 3.77295C34.0783 3.02281 33.0609 2.60138 32 2.60138C30.9391 2.60138 29.9217 3.02281 29.1716 3.77295C28.4214 4.5231 28 5.54051 28 6.60138C28 7.66225 28.4214 8.67966 29.1716 9.42981C29.9217 10.18 30.9391 10.6014 32 10.6014Z' stroke='currentColor' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9 9.60138L13 13.6014M28 10.6014L26 12.6014M32 32.1014L26 24.6014M12 29.6014L16 25.6014' stroke='currentColor' strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

Exclusive.propTypes = {
  className: PropTypes.string
};

export default Exclusive;
