import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { updateLoader, retrieveProfile, updateProfile } from '@/redux/slice/profile';
import { resetFieldErrors, updateLoginCredential } from '@/redux/slice/login';
import { showToast } from '@/redux/slice/toastSlice';

import { ProfileUpdateValidationSchema } from '@/validation/Formvalidation';

import dayjs from 'dayjs';

import { Box, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';
import Ribbon from '@/components/new/Ribbon';

import { SUBSCRIPTION_PLANS, CHANGE_PASSWORD, SONG_INFO_HISTORY, PLAN_INFO_HISTORY } from '@/constants/PageName';

import clsx from 'clsx';

function MyProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.login.loginCred);
  const errorObject = useSelector((state) => state.login.fieldErrors);
  const loginData = useSelector((state) => state.login?.loginCred);
  const navigate = useNavigate();
  const data = useSelector((state) => state);
  const validationSchema = ProfileUpdateValidationSchema;

  const [isEditing, setIsEditing] = useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: profileDetails?.first_name,
      last_name: profileDetails?.last_name,
      dob: profileDetails?.dob,
      dj: profileDetails?.dj,
    },
    validationSchema: validationSchema,
    onSubmit: (e, evwent) => {
      handleUpdate();
    },
  });

  // Function to handle redirection to the path specified in parameter
  const redirectHandler = (path) => {
    navigate(path);
  };

  const handleUpdate = async () => {
    try {
      dispatch(updateLoader(true));
      // let date = dayjs(formik.values.dob).$d.toISOString().split('T')[0];
      let date = dayjs(formik.values.dob).format('YYYY-MM-DD');
      const action = await dispatch(updateProfile({ ...formik.values, dob: date }));

      let responseData = { ...formik.values, dob: date };
      const response = action.payload;
      if (response) {
        dispatch(
          showToast({
            message: 'User Profile Updated Successfully!',
            type: 'success',
          })
        );
        dispatch(updateLoginCredential({ ...loginData, ...responseData }));
        dispatch(updateLoader(false));
        setIsEditing(!isEditing);
      } else {
        // only settimeout will be removed in future, not dispatch one's
        setTimeout(() => {
          dispatch(updateLoader(false));
        }, 1000);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(retrieveProfile())
        .then((result) => {
          dispatch(
            updateLoginCredential({
              ...loginData,
              ...{ subscription_details: result.payload.subscription_details },
            })
          );
        })
        .catch((error) => {
          dispatch(updateLoader(false));
        });
    }, 5000);
  }, []);

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  useEffect(() => {
    dispatch(resetFieldErrors());
  }, [formik.values, dispatch]);

  return (
    <div className='mt-40'>
      <section>
        <Container>
          <Title size={5} className='mx-auto mb-16 max-w-screen-lg text-center font-semibold'>
            <h2>
              <Ribbon className='dance'>My profile</Ribbon>
            </h2>
          </Title>
          <Box className='profile-card' spacing={1}>
            <div className='content-profile'>
              <div className='grid grid-cols-1 gap-12 lg:grid-cols-2'>
                <div className='rounded-6 border p-6 lg:row-span-2'>
                  <hgroup className='mb-12 flex justify-between'>
                    <Title size={1} className='font-semibold'>
                      <h3>My info</h3>
                    </Title>
                    {!isEditing && (
                      <button className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' onClick={() => setIsEditing(!isEditing)}>
                        Edit details
                      </button>
                    )}
                  </hgroup>
                  <form
                    className={clsx(!isEditing && 'opacity-50')}
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit(e);
                    }}
                  >
                    <div className='lg grid grid-cols-1 gap-6 md:grid-cols-2'>
                      <TextField placeholder='First name' name='first_name' onChange={handleChange} value={formik.values?.first_name} disabled={!isEditing} error={(formik.touched?.first_name && Boolean(formik.errors?.first_name)) || errorObject?.first_name} helperText={(formik.touched?.first_name && formik.errors?.first_name) || errorObject?.first_name} />

                      <TextField placeholder='Last name' name='last_name' onChange={handleChange} value={formik.values?.last_name} disabled={!isEditing} error={(formik.touched?.last_name && Boolean(formik.errors?.last_name)) || errorObject?.last_name} helperText={(formik.touched?.last_name && formik.errors?.last_name) || errorObject?.last_name} />

                      <TextField className={clsx(isEditing && 'opacity-50', 'col-span-2')} placeholder={t('sign-up.placeholder.email')} name='username' value={profileDetails?.username} disabled />

                      <div className='col-span-2'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <div className='custom-date-picker grid w-full grid-cols-3 gap-4'>
                              <DatePicker name='month' openTo='month' views={['month']} value={formik.values.dob ? dayjs(formik.values.dob) : null} onChange={(newValue) => formik.setFieldValue('dob', newValue)} className='grow-1' disabled={!isEditing} error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob} helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob} />
                              <DatePicker name='day' openTo='day' value={formik.values.dob ? dayjs(formik.values.dob) : null} onChange={(newValue) => formik.setFieldValue('dob', newValue)} views={['day']} className='grow-1' disabled={!isEditing} error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob} helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob} />
                              <DatePicker name='year' className='grow-1' openTo='year' views={['year']} value={formik.values.dob ? dayjs(formik.values.dob) : null} onChange={(newValue) => formik.setFieldValue('dob', newValue)} disabled={!isEditing} error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob} helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob} disableFuture />
                            </div>
                          </DemoContainer>
                          <span className='text-danger'> {formik?.errors?.dob || errorObject?.dob}</span>
                        </LocalizationProvider>
                      </div>

                      <TextField className='col-span-2' placeholder={t('sign-up.placeholder.dj')} name='dj' onChange={handleChange} value={formik.values?.dj} disabled={!isEditing} error={(formik.touched?.dj && Boolean(formik.errors?.dj)) || errorObject?.dj} helperText={(formik.touched?.dj && formik.errors?.dj) || errorObject?.dj} />

                      {isEditing && (
                        <div className='col-span-2 flex justify-center gap-6'>
                          <button type='submit' className='rounded-full bg-valence px-6 py-3 font-semibold text-hard'>
                            {t('my-profile.update')}
                          </button>
                          <button type='button' className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' onClick={() => setIsEditing(!isEditing)}>
                            {t('my-profile.cancel')}
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>

                <div className='space-y-6 rounded-6 border p-6'>
                  <hgroup className='flex justify-between'>
                    <Title size={1} className='font-semibold'>
                      <h3>My subscription plan</h3>
                    </Title>
                  </hgroup>
                  {data?.login?.loginCred?.subscription_details != null ?
                    <>
                      <Lead className='text-xl'>
                        <p>Active Plan - {data?.login?.loginCred?.subscription_details?.plan}</p>
                      </Lead>
                      <Lead className='text-xl'>
                        <p>Date of expiry - {data?.login?.loginCred?.subscription_details?.subscription_end_date}</p>
                      </Lead>
                    </>
                  : <Lead className='text-xl'>
                      <p>You haven&apos;t subscribed to any plan yet, please subscribe to continue</p>
                    </Lead>
                  }

                  <div className='flex justify-between'>
                    <button className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' size='small' onClick={() => redirectHandler(SUBSCRIPTION_PLANS)}>
                      {data?.login?.loginCred?.subscription_details && Object.keys(data?.login?.loginCred?.subscription_details).length ? 'Upgrade' : 'Subscribe'}
                    </button>
                    <button className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' onClick={() => redirectHandler(PLAN_INFO_HISTORY)}>
                      {t('my-profile.history')}
                    </button>
                  </div>
                </div>

                <div className='rounded-6 border p-6'>
                  <Title size={1} className='font-semibold'>
                    <h3>Update</h3>
                  </Title>
                  <div className='col-span-2 mt-6 flex gap-6'>
                    <button className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' onClick={() => redirectHandler(CHANGE_PASSWORD)}>
                      {t('my-profile.changePassword')}
                    </button>
                    <button className='rounded-full bg-valence px-6 py-3 font-semibold text-hard' onClick={() => redirectHandler(SONG_INFO_HISTORY)}>
                      {t('my-profile.exploredSetlist')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Container>
      </section>
    </div>
  );
}

export default MyProfile;
