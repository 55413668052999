import { Box, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import dataJSON from '../assets/JSON/test_data.json';
import Button from '../components/layout/Button/Letsvibe';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { BPM_RANGE, LIST_NATURAL, QUICK_MIX } from '../constants/PageName';
import { addGuidedMix, setCurrentPage } from '../redux/slice/vibeset';
import { SetlistGuidedValidationSchema } from '../validation/Formvalidation';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

function SetlistGuided() {
  const dispatch = useDispatch();
  const vibeSetMode = useSelector((state) => state.vibeset.mode);
  const guidedMixType = useSelector((state) => state.vibeset.guidedMix);
  const { t } = useTranslation();

  const validationSchema = SetlistGuidedValidationSchema;

  const formik = useFormik({
    initialValues: {
      selectedPills: guidedMixType?.genre || { ...dataJSON.musicStyles }
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (vibeSetMode === QUICK_MIX) {
        dispatch(setCurrentPage(LIST_NATURAL));
      } else {
        const dataGuided = {
          genre: {
            ...values.selectedPills
          },
          bpm: guidedMixType?.bpm,
          email: guidedMixType?.email,
          song_artist: guidedMixType?.song_artist,
          valence: guidedMixType?.valence
        };

        dispatch(addGuidedMix(dataGuided));
        dispatch(setCurrentPage(BPM_RANGE));
      }
      // Submit logic can be added here if needed
    }
  });

  return (
    <Box className='child-main SetListGuided fade-in one'>
      <Box className='select-guided-flex-box'>
        <Box>
          <Typography className='is-h2' variant='h3' component='h3' gutterBottom>
            {t('setlist-guided.select')}
          </Typography>

          <Typography className='para-txt' variant='body1'>
            {t('setlist-guided.pick')}
          </Typography>
        </Box>
        <Box className='chip-content'>
          <Stack direction='row' spacing={1} className='select-guided-chip'>
            {Object.keys(dataJSON.musicStyles).map((pillKey) => (
              <Chip
                key={pillKey}
                label={pillKey}
                clickable
                name='selectedPills'
                className={
                  formik.values.selectedPills[pillKey] == 'true'
                    ? 'guided-pill chip-selected'
                    : 'guided-pill'
                }
                onClick={() => {
                  formik.setFieldValue(
                    'selectedPills',
                    formik.values.selectedPills[pillKey] == 'true'
                      ? { ...formik.values.selectedPills, [pillKey]: 'false' }
                      : { ...formik.values.selectedPills, [pillKey]: 'true' }
                  );
                }}
                variant={formik.values.selectedPills[pillKey] == 'true' ? 'filled' : 'outlined'}
              />
            ))}
          </Stack>
        </Box>
        <div className=''>
          <Button
            onClick={formik.handleSubmit}
            // className='select-bpm-range'
            className={`btn-bg-teal-blue primary ${Object.values(formik.values.selectedPills).some((e) => e == 'true') > 0 ? '' : 'btn-color-change'}`}
            disabled={!Object.values(formik.values.selectedPills).some((e) => e == 'true')}
          >
            <span className='select-bpm-button'>{t('setlist-guided.selectBpm')}</span>
            <ArrowForwardIcon />
          </Button>
        </div>
      </Box>
    </Box>
  );
}

export default SetlistGuided;
