import { createSlice } from '@reduxjs/toolkit';
import apiConfig from '../../composable/api.json';
import { createAPIUtils } from './apiUtils';
import { showToast } from '../slice/toastSlice';
import { resetClickedRoute, setRedirectedRoute } from '../slice/clickedRoute';

const initialStateValue = {
  isLoading: false,
  formData: {
    email: '',
    password: ''
  },
  resetPasswordForm: {
    password: '',
    confirm_password: ''
  },
  loginCred: JSON.parse(localStorage.getItem('login')),
  isError: false,
  tokenID: '',
  loading: false,
  errorObject: '',
  error: null,
  fieldErrors: '',
  headerHooterVisible: false,
  demoUsed: false,
  unAuth: false,
  vibeForFreeFlag: JSON.parse(localStorage.getItem('vibeForFree'))
};

const loginSlice = createSlice({
  name: 'login',
  initialState: { ...initialStateValue },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialStateValue.formData;
    },
    updateLoginCredential: (state, action) => {
      state.loginCred = action.payload;
      localStorage.setItem('login', JSON.stringify(action.payload));
    },
    updateLoader: (state, action) => {
      state.loading = action.payload;
    },
    updateUnAuth: (state, action) => {
      state.unAuth = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setFieldError(state, action) {
      const fieldName = action.payload;
      state.fieldErrors = fieldName;
    },
    resetFieldErrors(state) {
      state.fieldErrors = {};
    },
    setHeaderFooterVisible(state, action) {
      state.headerHooterVisible = action.payload;
    },
    setVibeForFreeFlag(state, action) {
      state.vibeForFreeFlag = action.payload;
      localStorage.setItem('vibeForFree', JSON.stringify(action.payload));
    },
    setDemoUsedFlag(state, action) {
      state.demoUsed = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.playlist = action.payload;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.isError = true;
    });
  }
});

export const {
  updateFormData,
  resetFormData,
  updateLoginCredential,
  updateLoader,
  setError,
  setFieldError,
  resetFieldErrors,
  setHeaderFooterVisible,
  setVibeForFreeFlag,
  updateUnAuth,
  setDemoUsedFlag
} = loginSlice.actions;

const apiUtils = createAPIUtils({
  showToast,
  updateLoginCredential,
  resetClickedRoute,
  updateUnAuth,
  setRedirectedRoute,
  setFieldError
});

const { createAPIAsyncThunk } = apiUtils;

export const login = createAPIAsyncThunk('login', apiConfig?.login);
export const resetpassword = createAPIAsyncThunk('resetpassword', apiConfig?.resetpassword);
export const forgotpassword = createAPIAsyncThunk('forgotpassword', apiConfig?.forgotpassword);
export const logout = createAPIAsyncThunk('logout', apiConfig?.logout);
export const changepassword = createAPIAsyncThunk('changepassword', apiConfig?.changepassword);

export default loginSlice.reducer;