import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Slider from '@radix-ui/react-slider';

import clsx from 'clsx';

const BPMSliderSimple = React.memo(({ min = 65, max = 185, start = 65, onChange, step = 1 }) => {
  const [value, setValue] = useState([start]);

  const handleValueChange = useCallback(
    (newValue) => {
      setValue(newValue);
      if (onChange) {
        const bpm = newValue[0];
        const percentage = ((bpm - min) / (max - min)) * 100;
        onChange(bpm, percentage);
      }
    },
    [onChange, min, max]
  );

  useEffect(() => {
    setValue([start]);
  }, [start]);

  const getPipsConfig = useCallback(() => {
    const width = window.innerWidth;
    if (width < 600) return { count: 10 };
    if (width < 1301) return { count: 12 };
    if (width < 1451) return { count: 14 };
    return { count: 16 };
  }, []);

  const [pips, setPips] = useState(getPipsConfig());

  useEffect(() => {
    const handleResize = () => setPips(getPipsConfig());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getPipsConfig]);

  const renderPips = () => {
    const pipElements = [];
    for (let i = 0; i <= pips.count; i++) {
      const pipPosition = `${(i / pips.count) * 100}%`;
      pipElements.push(
        <div key={i} className='absolute -translate-x-1/2 transform' style={{ left: pipPosition, top: '0' }}>
          <div className={clsx(i % 2 === 0 ? 'w-[2.5px]' : 'w-[5px]', 'h-40 rounded-full bg-hard-950')} />
        </div>
      );
    }
    return pipElements;
  };

  return (
    <div className='grid-stack'>
      <Slider.Root className='relative z-10 flex h-40 w-full cursor-ew-resize touch-none select-none items-center' value={value} min={min} max={max} step={step} onValueChange={handleValueChange}>
        <Slider.Track className='relative h-[5px] grow rounded-full bg-hard-950'>
          <Slider.Range className='absolute h-full rounded-full bg-off-white' />
        </Slider.Track>
        <Slider.Thumb aria-label='BPM' asChild>
          <div className='grid h-32 w-16 cursor-grab place-content-center rounded-2 bg-hard font-mono text-2xl font-bold shadow-md ring-off-white hover:ring-2 focus:bg-hard-600 focus:outline-none focus:ring-2 active:cursor-grabbing'>{value[0]}</div>
        </Slider.Thumb>
      </Slider.Root>
      <div className='relative z-0 mx-8 h-full [clip-path:polygon(-1%_-1%,_101%_-1%,_101%_55px,_-1%_55px,_-1%_calc(101%_-_55px),_101%_calc(101%_-_55px),_101%_101%,_-1%_101%)]'>{renderPips()}</div>
    </div>
  );
});

BPMSliderSimple.displayName = 'BPMSliderSimple';

BPMSliderSimple.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  start: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
};

export default BPMSliderSimple;