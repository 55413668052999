import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../redux/slice/vibeset';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import createAC from '../assets/styles/img/createAC.png';
import { LOGIN } from '../constants/PageName';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { resetPasswordValidationSchema } from '../validation/Formvalidation';
import { resetpassword, updateLoader, resetFieldErrors } from '../redux/slice/login';
import { showToast } from '../redux/slice/toastSlice';
import Loader from '../components/Loader';
import { InputBox } from '../components/mui-components/Account';

function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.login.loading);
  const navigate = useNavigate();
  const errorObject = useSelector((state) => state.login.fieldErrors);

  const { id: tokenID } = useParams();

  const validationSchema = resetPasswordValidationSchema;

  const goToPageHandler = (page) => {
    dispatch(setCurrentPage(page));
  };

  // Function to handle the next click event
  const handleNextClick = async () => {
    try {
      dispatch(updateLoader(true));
      const action = await dispatch(resetpassword({ ...formik.values, tokenID }));

      const response = action.payload;
      if (response) {
        dispatch(updateLoader(false));
        navigate(LOGIN);
        dispatch(
          showToast({
            message: response.message,
            type: 'success'
          })
        );
      } else {
        // only settimeout will be removed in future, not dispatch one's
        setTimeout(() => {
          dispatch(updateLoader(false));
        }, 1000);
      }
    } catch (error) {
      // only settimeout will be removed in future, not dispatch one's
      setTimeout(() => {
        dispatch(updateLoader(false));
      }, 100000);
      return error;
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleNextClick();
    }
  });

  useEffect(() => {
    dispatch(resetFieldErrors());
  }, [formik.values, dispatch]);

  return (
    <div>
      {loader ? <Loader /> : ''}
      <div className='child-main create-account-main fade-in one'>
        <Grid container spacing={10} alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <div className='create-account-img'>
              <img srcSet={createAC} alt={t('login.alt.create')} />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className='create-account authorize-pages'>
              <div className='heading'>
                <Box className='centerright'>
                  <Typography variant='h1' component='h1' className='ish1 text-upper '>
                    <Box className='pinktape_cya'></Box>
                    {t('login.passwordReset')}
                  </Typography>
                  <Typography variant='h2' component='h2' className='ish1below  text-white'>
                    {t('login.resetpassworddescription')}
                  </Typography>
                </Box>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <InputBox
                      className={
                        (formik.touched.password && Boolean(formik.errors.password)) ||
                        errorObject?.password
                          ? 'error-border form-input-email text-white'
                          : 'form-input-email text-white'
                      }
                      placeholder={t('login.placeholder.password')}
                      variant='outlined'
                      name='password'
                      type='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        (formik.touched.password && Boolean(formik.errors.password)) ||
                        errorObject?.password
                      }
                      helperText={
                        (formik.touched.password && formik.errors.password) || errorObject?.password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <InputBox
                      className={
                        (formik.touched.confirm_password &&
                          Boolean(formik.errors.confirm_password)) ||
                        errorObject?.confirm_password
                          ? 'error-border form-input-email text-white'
                          : 'form-input-email text-white'
                      }
                      placeholder={t('sign-up.placeholder.confirmPassword')}
                      variant='outlined'
                      type='password'
                      name='confirm_password'
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      error={
                        (formik.touched.confirm_password &&
                          Boolean(formik.errors.confirm_password)) ||
                        errorObject?.confirm_password
                      }
                      helperText={
                        (formik.touched.confirm_password && formik.errors.confirm_password) ||
                        errorObject?.confirm_password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className='flex footer-button customer-footer-button-login'>
                      <Link to={LOGIN} variant='outlined' className='outline-button'>
                        {t('common.back')}
                      </Link>
                      <p className='text-danger'>{errorObject.non_field_errors}</p>
                      <Button
                        type='submit'
                        className='btn-text-blue btn-bg-teal-blue primary button btn-med-white link-button-effect mr-15'
                        rel='noopener noreferrer'
                      >
                        {t('common.update')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ResetPassword;
