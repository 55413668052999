import { useMemo } from 'react';

const useChartValues = (playlistData) => {
  return useMemo(() => {
    if (playlistData.length === 0) {
      return {
        bpm: [],
        valence: [],
        dance: [],
        bpmAvg: null,
        valenceAvg: null,
        danceAvg: null,
        minBpm: 0,
        minValence: 0,
        minDance: 0
      };
    }

    const bpm = playlistData.map((song) => song.tempo);
    const valence = playlistData.map((song) => song.valence);
    const dance = playlistData.map((song) => song.danceability);

    return {
      bpm,
      valence,
      dance,
      bpmAvg: Math.floor(bpm.reduce((acc, val) => acc + val, 0) / bpm.length),
      valenceAvg: (valence.reduce((acc, val) => acc + val, 0) / valence.length).toFixed(1),
      danceAvg: (dance.reduce((acc, val) => acc + val, 0) / dance.length).toFixed(1),
      minBpm: Math.min(...bpm),
      minValence: Math.min(...valence),
      minDance: Math.min(...dance)
    };
  }, [playlistData]);
};

export default useChartValues;