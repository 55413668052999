import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setVibeForFreeFlag, updateLoginCredential } from '@/redux/slice/login';
import { showToast } from '@/redux/slice/toastSlice';
import { retrieveProfile } from '@/redux/slice/profile';
import { fetchSubscriptionPlans, subscriptionPayment, setPlanId, setSubscriptionPlans, updateLoader } from '@/redux/slice/subscriptiondetails';

import Text from '@/components/new/Text';
import Ribbon from '@/components/new/Ribbon';
import MotionButton from '@/components/new/MotionButton';

import { ArrowRight } from '@phosphor-icons/react';

import SetGen from './icons/SetGen';
import Exclusive from './icons/Exclusive';
import Connect from './icons/Connect';
import Limited from './icons/Limited';

import { ALLINONE, PROFILE } from '@/constants/PageName';

import waveBlue from './waveBlue.png';
import wavePurple from './wavePurple.png';

import styles from './styles.module.scss';
import clsx from 'clsx';

const SubscriptionPlans = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setVibeForFreeFlag(true));
    navigate(ALLINONE);
  };

  useEffect(() => {
    if (data.subscriptiondetails.subscriptionPlans.length == 0) {
      dispatch(updateLoader(true));
      dispatch(fetchSubscriptionPlans())
        .then((result) => {
          if (result.payload.data.length) {
            dispatch(updateLoader(false));
            dispatch(setSubscriptionPlans(result.payload.data));
          }
        })
        .catch((error) => {
          throw error;
        });
    }
    dispatch(retrieveProfile()).then((result) => {
      if (result.payload.subscription_details != null) {
        const loginData = JSON.parse(JSON.stringify(data.login));
        loginData.loginCred.subscription_details = result.payload.subscription_details;
        dispatch(updateLoginCredential(loginData.loginCred));
      }
    });
  }, []);

  const redirectHandler = (planId) => {
    if (planId) {
      dispatch(updateLoader(true));
      dispatch(setPlanId(planId));
      let payload = {
        plan_id: planId,
      };
      dispatch(subscriptionPayment(payload))
        .then((result) => {
          dispatch(updateLoader(false));
          if (result?.payload?.is_link == true) {
            window.open(result.payload.url, '_self');
          } else if (result?.payload?.is_link == false) {
            dispatch(
              showToast({
                message: t('payment.upgradePlanSuccess'),
                type: 'success',
              })
            );
            navigate(PROFILE);
          }
        })
        .catch((error) => {
          dispatch(updateLoader(false));
          throw error;
        });
    }
  };

  const proPlan = data?.subscriptiondetails?.subscriptionPlans.find((plan) => plan.metadata.plan_code === 'PRO');

  const basicPlan = data?.subscriptiondetails?.subscriptionPlans.find((plan) => plan.metadata.plan_code === 'BASIC');

  return (
    <div className='fade-in one mt-32 text-off-white'>
      <section>
        <div className='container mx-auto px-4'>
          <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1'>
            <hgroup className='self-center'>
              <Text.Title tag='h1' variant='md'>
                Choose your plan to get started
              </Text.Title>
              <Text.Lead>Tap into your creativity with one of our subscriptions</Text.Lead>
            </hgroup>

            <div className='space-y-8'>
              <div className={styles.subCard}>
                <div className={styles.subCard__back}>
                  <img src={wavePurple} alt='' />
                </div>
                <div className={styles.subCard__front}>
                  <Text.Lead className='uppercase'>Play like a pro</Text.Lead>
                  <Text.Title tag='h2' variant='md'>
                    <Ribbon className={clsx(styles.ribbon, 'dance', 'z-index')}>Premium</Ribbon> Access
                  </Text.Title>
                  <div className={styles.iconList}>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <SetGen className={styles.good_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Generate up to 20 sets</div>
                    </div>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <Exclusive className={styles.good_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Exclusive features to customize sets</div>
                    </div>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <Connect className={styles.good_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Connect to Spotify, Tidal, Apple music, Youtube music</div>
                    </div>
                  </div>
                  <MotionButton bgColors={['rgba(255, 255, 255, 0)', 'var(--color-valence)']} borderColors={['#FFF', 'var(--color-valence)']} borderRadii={['0.5em', '1.75em']} colors={['#FFF', '#000']} size='large' className={clsx('uppercase', styles.subCard__button)} onClick={() => redirectHandler(proPlan?.default_price)}>
                    {[
                      <React.Fragment key='initial'>
                        Sounds good? <ArrowRight weight='bold' />
                      </React.Fragment>,
                      <React.Fragment key='hover'>
                        Subscribe now <ArrowRight weight='bold' />
                      </React.Fragment>,
                    ]}
                  </MotionButton>
                </div>
              </div>
              <div className={styles.subCard}>
                <div className={styles.subCard__back}>
                  <img src={waveBlue} alt='' />
                </div>
                <div className={styles.subCard__front}>
                  <Text.Lead className='uppercase'>Get down to the basics</Text.Lead>
                  <Text.Title tag='h2' variant='md'>
                    <Ribbon className={clsx(styles.ribbon, 'acid-jazz', 'z-index')}>Basic</Ribbon> Access
                  </Text.Title>
                  <div className={styles.iconList}>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <SetGen className={styles.good_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Generate up to 10 sets</div>
                    </div>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <Limited className={styles.bad_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Limited customization</div>
                    </div>
                    <div className={styles.iconList__item}>
                      <div className={styles.iconList__item__icon}>
                        <Limited className={styles.bad_icon} />
                      </div>
                      <div className={styles.iconList__item__content}>Connect to Spotify only</div>
                    </div>
                  </div>
                  <MotionButton bgColors={['rgba(255, 255, 255, 0)', 'var(--color-valence)']} borderColors={['#FFF', 'var(--color-valence)']} borderRadii={['0.5em', '1.75em']} colors={['#FFF', '#000']} size='large' className={clsx('uppercase', styles.subCard__button)} onClick={() => redirectHandler(basicPlan?.default_price)}>
                    {[
                      <React.Fragment key='initial'>
                        Sounds good? <ArrowRight weight='bold' />
                      </React.Fragment>,
                      <React.Fragment key='hover'>
                        Subscribe now <ArrowRight weight='bold' />
                      </React.Fragment>,
                    ]}
                  </MotionButton>
                </div>
              </div>

              <MotionButton bgColors={['rgba(255, 255, 255, 0)', 'var(--color-valence)']} borderColors={['#FFF', 'var(--color-valence)']} borderRadii={['0.5em', '1.75em']} colors={['#FFF', '#000']} size='large' className={styles.free_demo__button} onClick={handleButtonClick}>
                {[
                  <React.Fragment key='initial'>
                    <strong className='uppercase'>Demo</strong>
                    <small>Generate 1 set with basic access</small>
                  </React.Fragment>,
                  <React.Fragment key='hover'>
                    <strong className='uppercase'>Demo</strong>
                    <small>Play for free</small>
                    <ArrowRight weight='bold' />
                  </React.Fragment>,
                ]}
              </MotionButton>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionPlans;
