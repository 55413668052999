import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles.module.scss';
import useKnob from './useKnob';

const CustomKnob = ({ min, max, steps, size = 120, onChange, initialValue = min }) => {
  const { value, knobRef, dialRef, updateKnob } = useKnob(min, max, steps, onChange);
  const range = max - min;

  useEffect(() => {
    updateKnob(initialValue, 0);
  }, [initialValue, updateKnob]);

  const handleStepClick = useCallback((step) => {
    updateKnob(step);
  }, [updateKnob]);

  return (
    <div className={styles.knob__wrapper} style={{ '--knob-size': `${size}px` }}>
      <div className={styles.knob} ref={knobRef}>
        <div className={styles.knob__dial} ref={dialRef} />
        {steps.map((step, index) => {
          const stepPercentage = ((step - min - 45) / range) * 100;
          return (
            <React.Fragment key={index}>
              <div className={styles.knob__notch} style={{ '--step-percent': `${stepPercentage * 1.8}deg` }} />
              <div 
                className={clsx(styles.knob__step, step === value && styles.knob__step__current)} 
                style={{ '--step-percent': `${stepPercentage * 1.8}deg` }} 
                onClick={() => handleStepClick(step)}
              >
                <span>{step}</span>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

CustomKnob.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  size: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.number,
};

export default CustomKnob;