import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { resetVibeData, setCurrentPage, setVibeMode, resetCountLimit } from '@/redux/slice/vibeset';
import { updateLoginCredential, logout, updateLoader, setVibeForFreeFlag, setHeaderFooterVisible, setDemoUsedFlag } from '@/redux/slice/login';
import { showToast } from '@/redux/slice/toastSlice';
import { resetClickedRoute, setRedirectedRoute } from '@/redux/slice/clickedRoute';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Box, Menu, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import logo from '/logo.svg';

import Loader from '@/components/Loader';

import { GUIDED_MIX, LANDING_PAGE, LIST_NATURAL, QUICK_MIX, QUICK_MIX_HOME_PAGE, ACCOUNT, SIGN_UP_ACCOUNT, ACCOUNT_LOGIN, LOGIN, PROFILE, SUBSCRIPTION_PLANS, ALLINONE } from '@/constants/PageName';

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loginCred = useSelector((state) => state?.login?.loginCred);
  const loader = useSelector((state) => state.login.loading);
  const { t } = useTranslation();
  const data = useSelector((state) => state.login);
  const dataCheck = useSelector((state) => state);

  // State to manage the confirmation dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // Function to handle logout
  const setLogOut = async () => {
    try {
      dispatch(updateLoader(true));
      const action = await dispatch(logout());
      const response = action.payload;
      if (response) {
        dispatch(
          showToast({
            message: response.message,
            type: 'success',
          })
        );
        dispatch(updateLoader(false));
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        dispatch(setVibeForFreeFlag(false));
        dispatch(resetCountLimit());
        navigate(ACCOUNT_LOGIN);
        handleDialogClose();
      } else {
        dispatch(updateLoader(false));
      }
    } catch (err) {
      dispatch(updateLoader(false));
      handleDialogClose();
      return err;
    }
  };

  useEffect(() => {
    if (data?.unAuth) {
      navigate(ACCOUNT_LOGIN);
    }
    if (location.pathname == LANDING_PAGE) {
      dispatch(setHeaderFooterVisible(false));
    } else {
      dispatch(setHeaderFooterVisible(true));
    }
  }, [location, dispatch, navigate, data?.unAuth]);

  // Function to handle setting vibe mode
  const setModeHandler = (mode, popupState) => {
    if (mode == PROFILE) {
      dispatch(setCurrentPage(PROFILE));
      dispatch(resetVibeData(SIGN_UP_ACCOUNT));
      navigate(PROFILE);
    } else if (dataCheck?.login?.loginCred?.subscription_details?.is_basic_plan ? dataCheck?.login?.loginCred?.subscription_details?.is_basic_plan && dataCheck?.vibeset?.countLimit < 15 : true) {
      if (data?.vibeForFreeFlag && data?.demoUsed) {
        if (data?.demoUsed) {
          dispatch(setVibeForFreeFlag(false));
          dispatch(setDemoUsedFlag(false));
        }
      }
      popupState.close();
      dispatch(setVibeMode(mode));
      if (mode == QUICK_MIX) {
        dispatch(setCurrentPage(LIST_NATURAL));
        dispatch(resetVibeData(mode));
        navigate(QUICK_MIX_HOME_PAGE);
      } else if (mode == SIGN_UP_ACCOUNT) {
        dispatch(setCurrentPage(SIGN_UP_ACCOUNT));
        dispatch(resetVibeData(SIGN_UP_ACCOUNT));
        navigate(ACCOUNT);
      } else if (mode == ACCOUNT_LOGIN) {
        dispatch(setCurrentPage(ACCOUNT_LOGIN));
        dispatch(resetVibeData(SIGN_UP_ACCOUNT));
        navigate(LOGIN);
      } else if (mode == ALLINONE) {
        dispatch(setCurrentPage(ALLINONE));
        dispatch(resetVibeData(mode));
        navigate(ALLINONE);
      }
    }
    // else {
    //   navigate(SUBSCRIPTION_PLANS);
    //   dispatch(
    //     showToast({
    //       message: "You've reached your limit on the basic plan. Upgrade pro to continue.",
    //       type: 'error',
    //     })
    //   );
    // }
  };

  return (
    data.headerHooterVisible && (
      <>
        {loader ?
          <Loader />
        : ''}
        <header className='fixed inset-x-0 top-0 z-50 backdrop-blur-md'>
          <div className='container mx-auto px-4'>
            <div className='my-8 flex items-center justify-between'>
              <Link to={LANDING_PAGE} underline='none' className='code-1'>
                <img srcSet={logo} alt={t('common.vibesetAi')} width={120} height={21} />
              </Link>

              <Box className='burger-menu'>
                <PopupState variant='popover' popupId='demo-popup-menu'>
                  {(popupState) => (
                    <>
                      <IconButton {...bindTrigger(popupState)}>
                        {popupState.isOpen ?
                          <CloseIcon className='menuicon' />
                        : <MenuIcon className='menuicon' />}
                      </IconButton>

                      <Menu {...bindMenu(popupState)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <MenuItem onClick={() => setModeHandler(ALLINONE, popupState)}>{t('header.guidedMix')}</MenuItem>
                        <MenuItem onClick={() => setModeHandler(QUICK_MIX, popupState)}>{t('header.quickMix')}</MenuItem>
                        {loginCred?.token && <MenuItem onClick={() => setModeHandler(PROFILE, popupState)}>{t('header.profile')}</MenuItem>}
                        {loginCred?.token && (
                          <MenuItem
                            onClick={() => {
                              handleDialogOpen();
                              popupState.close();
                            }}
                          >
                            {t('header.logout')}
                          </MenuItem>
                        )}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            </div>
          </div>
        </header>

        {/* Confirmation Dialog for Logout */}
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          sx={{ '& .MuiPaper-root': { backgroundColor: '#061492' } }} // Change lightblue to your desired color
        >
          <DialogTitle id='alert-dialog-title' sx={{ color: '#ffffff' }}>
            {t('header.logout')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description' sx={{ color: '#ffffff' }}>
              {t('header.logoutConfirmationTitle')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} sx={{ color: '#ffffff' }}>
              {t('header.cancel')}
            </Button>
            <Button onClick={setLogOut} sx={{ color: '#ffffff' }} autoFocus>
              {t('header.yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
}

export default Header;
