import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import styles from './styles.module.scss';

import GSAPParallaxSection from '@/components/new/GSAPParallaxSection';

import MotionButton from '@/components/new/MotionButton';

import { ArrowRight } from '@phosphor-icons/react';

import Ribbon from '@/components/new/Ribbon';
import Text from '@/components/new/Text';

import Tags from './Tags';
import BPMs from './BPM';
import Genres from './Genre';

const genres = ['Hard Dance', 'Dance Electro Pop', 'Bass House', 'Tech House', 'Dubstep', 'Progressive House', 'Trap', 'Afro House', 'Melodic House/Techno', 'Drum and Bass', 'Techno', 'Trance', 'House', 'Mainstage'];

const slides = [
  { title: 'Select genres', content: <Genres isActive={false} genres={genres} /> },
  { title: 'Add/select songs and artists', content: <Tags /> },
  { title: 'Set your BPMs', content: <BPMs /> },
];

const AdventureSlideshow = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const swiperRef = useRef(null);
  const cardRef = useRef(null);
  const lastProgressRef = useRef(0);
  const rafRef = useRef(null);
  const lastScrollTime = useRef(Date.now());

  const handleScroll = useCallback(
    (progressValue) => {
      lastScrollTime.current = Date.now();

      const adjustedProgressValue = Math.min(progressValue, 1);
      if (adjustedProgressValue !== lastProgressRef.current) {
        lastProgressRef.current = adjustedProgressValue;
        const totalSlides = slides.length;
        const slideIndex = Math.floor(adjustedProgressValue * totalSlides);
        if (swiperRef.current) {
          swiperRef.current.slideTo(slideIndex);
        }
      }
    },
    [slides]
  );

  useEffect(() => {
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0 }
    );
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }
    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [cardRef]);

  return (
    <GSAPParallaxSection onProgressChange={handleScroll} className={styles.wrapper}>
      <div ref={cardRef} className={styles.card}>
        <div className={styles.details}>
          <p className={styles.step}>01. Guided Experience</p>
          <Text.Title tag='h2' variant='md'>
            <Ribbon className='acid-jazz z-index'>Organize</Ribbon> your creative chaos
          </Text.Title>
          <MotionButton bgColors={['var(--color-valence)']} borderColors={['var(--color-valence)']} borderRadii={['1.75em']} colors={['#000']} size='large' className={styles.details__button} style={{ textTransform: 'uppercase' }}>
            {[
              <React.Fragment key='initial'>
                Early access <ArrowRight weight='bold' />
              </React.Fragment>,
              <React.Fragment key='hover'>
                Let&lsquo;s vibe <ArrowRight weight='bold' />
              </React.Fragment>,
            ]}
          </MotionButton>
          <footer className={styles.details__footer}>
            <Text.Lead>Set the vibe of your set by answering a series of questions with absolute ease and fun.</Text.Lead>
          </footer>
        </div>
        <Swiper className={styles.slideshow} allowTouchMove={false} touchRatio={0} preventClicks={true} preventClicksPropagation={true} simulateTouch={false} noSwiping={true} spaceBetween={50} slidesPerView={1} onSwiper={(swiper) => (swiperRef.current = swiper)} onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}>
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              <div className={styles.slide__inner}>
                <div className={styles.slide__pills}>
                  <span className={`${styles.pill} ${index === 0 ? 'valence' : 'acid-jazz'}`}>{slide.title}</span>
                  <span className={`${styles.pill} ${index === 0 ? 'valence' : 'acid-jazz'}`}>
                    <strong>0{index + 1}.</strong>
                  </span>
                </div>
                {React.cloneElement(slide.content, {
                  isActive: isInView && activeSlide === index,
                })}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </GSAPParallaxSection>
  );
};

export default AdventureSlideshow;
