import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize, useMeasure } from 'react-use';
import clsx from 'clsx';
import styles from './styles.module.scss';
import calculateParallaxConfig from './calculateParallaxConfig';
import useParallaxEffect from './useParallaxEffect';

const GSAPParallaxSection = React.forwardRef(({ children, onProgressChange, className, parallaxStrength = 1, ariaLabel }, ref) => {
  const { height: viewportHeight } = useWindowSize();
  const [wrapperRef, { height: wrapperHeight }] = useMeasure();
  const outerRef = useRef(null);
  const parallaxItemRef = useRef(null);

  const config = useMemo(() => calculateParallaxConfig(wrapperHeight, viewportHeight, parallaxStrength), [wrapperHeight, viewportHeight, parallaxStrength]);

  const handleProgressChange = useCallback(onProgressChange, [onProgressChange]);

  useParallaxEffect(config, { outerRef, parallaxItemRef }, { handleProgressChange });

  React.useImperativeHandle(ref, () => outerRef.current, []);

  return (
    <div className={styles.parallaxTrack} ref={outerRef} style={config.outerStyle} role='region' aria-label={ariaLabel || 'Parallax section'}>
      <div ref={wrapperRef} className={styles.parallaxWrapper}>
        <div aria-hidden='true' ref={parallaxItemRef} className={clsx(styles.parallaxItem, className)}>
          {children}
        </div>
      </div>
    </div>
  );
});

GSAPParallaxSection.propTypes = {
  children: PropTypes.node.isRequired,
  onProgressChange: PropTypes.func,
  className: PropTypes.string,
  parallaxStrength: PropTypes.number,
  ariaLabel: PropTypes.string,
};

GSAPParallaxSection.displayName = 'GSAPParallaxSection';

export default React.memo(GSAPParallaxSection);
