import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_BILLING, ADD_DOB, ENTER_PAYMENT, SIGN_UP_ACCOUNT } from '../../constants/PageName';
import { setCurrentPage } from '../../redux/slice/vibeset';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import { updateFormData } from '../../redux/slice/paymentdetail';
import { PaymentDetailValidationSchema } from '../../validation/Formvalidation';

function PaymentDetail({ contentRef, buttonRef, setPrevoiusPage, isPrevious }) {
  const { t } = useTranslation();
  const monthsList = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  const currentYear = new Date().getFullYear();
  const next25Years = Array.from({ length: 25 }, (_, index) => ({
    year: currentYear + index + 1,
    value: currentYear + index + 1
  }));

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.paymentdetail.formData);

  const validationSchema = PaymentDetailValidationSchema;

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    setPrevoiusPage(ENTER_PAYMENT);
    dispatch(setCurrentPage(page));
  };

  const handleNextClick = () => {
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateFormData(values));
      // handleNextClick();
      goToPageHandler(ADD_BILLING);
    }
  });

  return (
    <>
      <div className='create-account content-animation' ref={contentRef}>
        <div className='heading'>
          <h1 className='text-white'>{t('payment-detail.enterDetail')}</h1>
        </div>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              className='form-input-email text-white'
              placeholder={t('payment-detail.placeholder.name')}
              variant='outlined'
              name='cardHolderName'
              value={formik.values.cardHolderName}
              onChange={formik.handleChange}
              error={formik.touched.cardHolderName && Boolean(formik.errors.cardHolderName)}
              helperText={formik.touched.cardHolderName && formik.errors.cardHolderName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              className='form-input-email text-white'
              placeholder={t('payment-detail.placeholder.cardNumber')}
              variant='outlined'
              name='cardNumber'
              value={formik.values.cardNumber}
              onChange={formik.handleChange}
              error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Box>
              <FormControl className='form-input-email text-white' error>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  name='expMonth'
                  value={formik.values.expMonth}
                  onChange={formik.handleChange}
                  error={formik.touched.expMonth && Boolean(formik.errors.expMonth)}
                >
                  <MenuItem disabled value='null'>
                    <em className='select-placeholder'>
                      {t('payment-detail.placeholder.expMonth')}
                    </em>
                  </MenuItem>
                  {monthsList.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.expMonth && formik.errors.expMonth}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Box>
              <FormControl className='form-input-email text-white' error>
                <Select
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  name='expYear'
                  value={formik.values.expYear}
                  onChange={formik.handleChange}
                  error={formik.touched.expYear && Boolean(formik.errors.expYear)}
                >
                  <MenuItem disabled value=''>
                    <em className='select-placeholder'>
                      {t('payment-detail.placeholder.expYear')}
                    </em>
                  </MenuItem>
                  {next25Years.map((year) => (
                    <MenuItem key={year.year} value={year.year}>
                      {year.year}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.expYear && formik.errors.expYear}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              className='form-input-email text-white'
              placeholder={t('payment-detail.placeholder.cvv')}
              variant='outlined'
              name='cvv'
              value={formik.values.cvv}
              onChange={formik.handleChange}
              error={formik.touched.cvv && Boolean(formik.errors.cvv)}
              helperText={formik.touched.cvv && formik.errors.cvv}
            />
          </Grid>
        </Grid>
        {/* </form> */}
      </div>
      {!isPrevious && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='footer-button animation' ref={buttonRef}>
            <Button
              disableRipple
              variant='outlined'
              className='outline-back-button'
              onClick={() => goToPageHandler(ADD_DOB)}
            >
              <ArrowBackOutlinedIcon /> {t('common.goBack')}
            </Button>
            <Button
              disableRipple
              variant='contained'
              className='fill-button'
              onClick={formik.handleSubmit}
            >
              {t('common.next')} <ArrowForwardIcon />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}

export default PaymentDetail;
