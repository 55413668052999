import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import clsx from 'clsx';
import styles from './styles.module.scss';

const WaveformLoader = ({
  isActive = false,
  height = 60,
  numberOfBulges = 4,
  maxBulgeHeightRatio = 1,
  minBulgeHeightRatio = 0.01,
  animationDuration = 0.6,
  steepness = 0.95,
  roundness = 1,
  easing = 6,
  opacity = 1,
  className
}) => {
  const controls = useAnimationControls();
  const [containerWidth, setContainerWidth] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isResizing, setIsResizing] = useState(false);
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const isActiveRef = useRef(isActive);
  const lastAnimationStateRef = useRef(null);

  const baselineY = height / 2;
  const maxBulgeHeight = (height * maxBulgeHeightRatio) / 2;
  const minBulgeHeight = (height * minBulgeHeightRatio) / 2;

  const generateBulge = useCallback(
    (peakX, bulgeHeight, width) => {
      const easingFactor = easing;
      const controlPointOffset = (width / 4) * (1 - steepness);
      const leftControlX1 = Math.max(0, peakX - controlPointOffset);
      const leftControlX2 = Math.max(0, peakX - controlPointOffset * easingFactor);
      const rightControlX1 = Math.min(width, peakX + controlPointOffset * easingFactor);
      const rightControlX2 = Math.min(width, peakX + controlPointOffset);

      const controlPointYOffset = bulgeHeight * (1 - roundness) * easingFactor;

      const topPath = `
      M 0 ${baselineY}
      C ${leftControlX1} ${baselineY}, ${leftControlX2} ${baselineY - bulgeHeight + controlPointYOffset}, ${peakX} ${baselineY - bulgeHeight}
      C ${rightControlX1} ${baselineY - bulgeHeight + controlPointYOffset}, ${rightControlX2} ${baselineY}, ${width} ${baselineY}
    `;

      const bottomPath = `
      M 0 ${baselineY}
      C ${leftControlX1} ${baselineY}, ${leftControlX2} ${baselineY + bulgeHeight - controlPointYOffset}, ${peakX} ${baselineY + bulgeHeight}
      C ${rightControlX1} ${baselineY + bulgeHeight - controlPointYOffset}, ${rightControlX2} ${baselineY}, ${width} ${baselineY}
    `;

      return `${topPath} ${bottomPath} Z`;
    },
    [baselineY, steepness, roundness, easing]
  );

  const initialBulges = useMemo(() => {
    return Array(numberOfBulges)
      .fill()
      .map(() => ({
        peakX: 0.2 + Math.random() * 0.6,
        height: minBulgeHeight + Math.random() * (maxBulgeHeight - minBulgeHeight),
        opacity: opacity
      }));
  }, [numberOfBulges, maxBulgeHeight, minBulgeHeight, opacity]);

  const animateBulges = useCallback(async () => {
    if (!isActiveRef.current) {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      return;
    }

    const animate = async () => {
      const newBulges = initialBulges.map(() => ({
        peakX: 0.2 + Math.random() * 0.6,
        height: minBulgeHeight + Math.random() * (maxBulgeHeight - minBulgeHeight),
        opacity: opacity
      }));

      lastAnimationStateRef.current = newBulges;

      await controls.start((i) => ({
        d: generateBulge(newBulges[i].peakX * containerWidth, newBulges[i].height, containerWidth),
        opacity: newBulges[i].opacity,
        transition: { duration: animationDuration, ease: 'linear' }
      }));

      if (isActiveRef.current) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };

    animate();
  }, [controls, animationDuration, initialBulges, maxBulgeHeight, minBulgeHeight, generateBulge, containerWidth, opacity]);

  useEffect(() => {
    isActiveRef.current = isActive;
    
    if (containerWidth > 0 && !isInitialLoad && !isResizing) {
      if (isActive) {
        if (lastAnimationStateRef.current) {
          controls.start((i) => ({
            d: generateBulge(lastAnimationStateRef.current[i].peakX * containerWidth, lastAnimationStateRef.current[i].height, containerWidth),
            opacity: lastAnimationStateRef.current[i].opacity,
            transition: { duration: 0 }
          }));
        }
        animateBulges();
      } else {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
          animationRef.current = null;
        }
        controls.stop();
      }
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isActive, controls, animateBulges, containerWidth, isInitialLoad, isResizing, generateBulge]);

  const updateContainerWidth = useCallback(() => {
    if (containerRef.current) {
      const newWidth = containerRef.current.offsetWidth;
      setContainerWidth(newWidth);

      controls.start((i) => ({
        d: generateBulge(initialBulges[i].peakX * newWidth, initialBulges[i].height, newWidth),
        transition: { duration: 0 }
      }));
    }
  }, [controls, generateBulge, initialBulges]);

  const debouncedUpdateContainerWidth = useMemo(() => debounce(updateContainerWidth, 250), [updateContainerWidth]);

  useEffect(() => {
    const handleResize = () => {
      setIsResizing(true);
      debouncedUpdateContainerWidth();
    };

    window.addEventListener('resize', handleResize);
    updateContainerWidth();

    return () => {
      window.removeEventListener('resize', handleResize);
      debouncedUpdateContainerWidth.cancel();
    };
  }, [debouncedUpdateContainerWidth, updateContainerWidth]);

  useEffect(() => {
    if (containerWidth > 0) {
      setIsInitialLoad(false);
      setIsResizing(false);
    }
  }, [containerWidth]);

  // Generate static bulges when isActive is false
  const staticBulges = useMemo(() => {
    if (isActive || containerWidth === 0) return null;
    
    return initialBulges.map((bulge, index) => ({
      d: generateBulge(bulge.peakX * containerWidth, bulge.height, containerWidth),
      opacity: bulge.opacity
    }));
  }, [isActive, containerWidth, initialBulges, generateBulge]);

  return (
    <div ref={containerRef} className={`w-full h-[${height}px] overflow-hidden`}>
      <svg className={className} width='100%' height='100%' viewBox={`0 0 ${containerWidth} ${height}`} preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>
        {initialBulges.map((bulge, index) => (
          <motion.path
            key={index}
            fill='currentColor'
            initial={{
              d: generateBulge(0, bulge.height, 0),
              opacity: 0
            }}
            animate={isActive ? controls : (staticBulges ? staticBulges[index] : {})}
            custom={index}
            className={clsx(styles.bulge, !isActive ? styles.inactive : styles.active)}
          />
        ))}
      </svg>
    </div>
  );
};

WaveformLoader.propTypes = {
  isActive: PropTypes.bool,
  height: PropTypes.number,
  numberOfBulges: PropTypes.number,
  maxBulgeHeightRatio: PropTypes.number,
  minBulgeHeightRatio: PropTypes.number,
  animationDuration: PropTypes.number,
  steepness: PropTypes.number,
  roundness: PropTypes.number,
  easing: PropTypes.number,
  opacity: PropTypes.number,
  className: PropTypes.string
};

export default WaveformLoader;