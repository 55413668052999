import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const Panel = React.forwardRef(({ children, className, ...props }, ref) => (
  <div ref={ref} className={twMerge('border border-NRG', className)} {...props}>
    {children}
  </div>
));

Panel.displayName = 'Panel';

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default React.memo(Panel);
