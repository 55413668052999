// toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
  message: '',
  isOpen: false,
  isLoading: false,
  type: ''
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: { ...initialStateValue },
  reducers: {
    showToast(state, action) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    hideToast(state) {
      state.isOpen = false;
      state.message = '';
      state.type = '';
    }
  }
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
