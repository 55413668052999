import React from 'react';

const Letsvibe = ({ onClick, type, children, className, disabled, hidden }) => {
  return (
    <>
      <button
        onClick={onClick}
        type={type}
        className={`btn-med-white ${className}`}
        disabled={disabled}
        hidden={hidden}
      >
        {children}
      </button>
    </>
  );
};

export default Letsvibe;
