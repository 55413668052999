import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import BPMSliderSimple from '@/components/new/BPMSliderSimple';
import Title from '@/components/new/Title';
import Panel from '@/views/AllInOne/atoms/Panel';
import { store } from '@/views/AllInOne/store';

function BpmPanel({ className }) {
  const bpmPanelRef = useRef(null);

  const handleBPMChange = useCallback((newValue, percentage) => {
    if (bpmPanelRef.current) {
      bpmPanelRef.current.style.setProperty('--slide-percent', `${percentage}%`);
    }
    
    // Update the store with the new BPM value
    store.setState((state) => ({
      ...state,
      bpm: newValue,
    }));
  }, []);

  return (
    <Panel className={className} ref={bpmPanelRef} style={{ '--slide-percent': '50%' }}>
      <Title className='mt-6 font-mono text-3xl uppercase text-black lg:text-4xl'>
        <h2>BPM</h2>
      </Title>
      <div className='mx-2 mb-4 mt-6'>
        <BPMSliderSimple onChange={handleBPMChange} start={125} />
      </div>
    </Panel>
  );
}

BpmPanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(BpmPanel);