import React from 'react';

import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';
import Ribbon from '@/components/new/Ribbon';

function StakesSection() {
  return (
    <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
      <Container>
        <div className='relative space-y-6 rounded-10 bg-hard px-10 py-14 md:rounded-12 md:px-12 md:py-16 lg:space-y-8 lg:rounded-16 lg:px-16 lg:py-20 xl:space-y-10 xl:rounded-20 xl:px-20 xl:py-24'>
          <Title size={5} className='font-semibold'>
            <h2>
              <Ribbon className='dance z-index'>Simplify</Ribbon> your setlist creation process
            </h2>
          </Title>
          <Lead size={1}>
            <p>We cut the grunt work of compiling and organizing your creative vision, so that you can focus on the fun stuff.</p>
          </Lead>

          <div className='grid grid-cols-1 grid-rows-3 gap-12 lg:grid-cols-3 lg:grid-rows-1'>
            <div>
              <Title size={3} className='mb-4 uppercase text-electric lg:mb-6'>
                <h3>Curate</h3>
              </Title>
              <Lead size={0}>
                <p>Select songs and genres that are on rotation for you.</p>
              </Lead>
            </div>
            <div>
              <Title size={3} className='mb-4 uppercase text-NRG lg:mb-6'>
                <h3>Produce</h3>
              </Title>
              <Lead size={0}>
                <p>Use Vibeset to set the bones and structure of your setlist.</p>
              </Lead>
            </div>
            <div>
              <Title size={3} className='mb-4 uppercase text-valence lg:mb-6'>
                <h3>Create</h3>
              </Title>
              <Lead size={0}>
                <p>Take your Vibeset creation and mix it to your heart’s desire.</p>
              </Lead>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default StakesSection;
