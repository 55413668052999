import { Button, Checkbox, FormControl, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { ADD_DJ, LOGIN, SIGN_UP_ACCOUNT } from '../../constants/PageName';
import { setCurrentPage } from '../../redux/slice/vibeset';
import { Link } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, resetFieldErrors } from '../../redux/slice/createaccount';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormik } from 'formik';
import { createAccountValidationSchema } from '../../validation/Formvalidation';
import gsap from 'gsap';
import { InputBox, StyledCheckBox, StyledCheckedIcon } from '../mui-components/Account';

function CreateAccount({ contentRef, buttonRef, setPrevoiusPage, isPrevious }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.createaccount.formData);
  const errorObject = useSelector((state) => state.createaccount.fieldErrors);
  const generalError = useSelector((state) => state.createaccount.generalError);
  const validationSchema = createAccountValidationSchema;

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    setPrevoiusPage(SIGN_UP_ACCOUNT);
    dispatch(setCurrentPage(page));
  };

  // Function to handle the next click event
  const handleNextClick = async () => {
    dispatch(updateFormData(formik.values));
    goToPageHandler(ADD_DJ);
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleNextClick();
    }
  });

  // Function to handle input change events
  const handleChange = (e) => {
    formik.handleChange(e);
    dispatch(resetFieldErrors());
  };

  React.useEffect(() => {
    gsap.fromTo(
      '.button-animation',
      {
        x: 300,
        y: -20,
        opacity: 0
      },
      { y: 0, x: 0, opacity: 1, duration: 1.5, ease: 'elastic.out' }
    );
  }, []);

  return (
    <>
      <div className='create-account content-animation' ref={contentRef}>
        <div className='heading '>
          <h1 className='text-white'>{t('sign-up.create')}</h1>
          <h2 className='text-white'>{t('sign-up.itsFree')}</h2>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} className=''>
            <InputBox className={(formik.touched.first_name && Boolean(formik.errors.first_name)) || errorObject?.name ? 'form-input-email text-white error-border' : 'form-input-email text-white'} placeholder={t('sign-up.placeholder.firstName')} variant='outlined' name='first_name' value={formik.values.first_name} onChange={handleChange} error={formik.touched.first_name || errorObject?.name} helperText={(formik.touched.first_name && formik.errors.first_name) || errorObject?.name} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className=''>
            <InputBox className={(formik.touched.last_name && Boolean(formik.errors.last_name)) || errorObject?.last_name ? 'form-input-email text-white error-border form-input-fonts' : 'form-input-email text-white form-input-fonts'} placeholder={t('sign-up.placeholder.lastName')} variant='outlined' name='last_name' value={formik.values.last_name} onChange={handleChange} error={formik.touched.last_name || errorObject?.last_name} helperText={(formik.touched.last_name && formik.errors.last_name) || errorObject?.last_name} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <InputBox className={(formik.touched.username && Boolean(formik.errors.username)) || errorObject?.username ? 'form-input-email text-white error-border form-input-fonts' : 'form-input-email text-white form-input-fonts'} placeholder={t('sign-up.placeholder.email')} variant='outlined' name='username' value={formik.values.username} onChange={handleChange} error={formik.touched.username || errorObject?.username} helperText={(formik.touched.username && formik.errors.username) || errorObject?.username} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <InputBox className={(formik.touched.password && Boolean(formik.errors.password)) || errorObject?.password ? 'form-input-email text-white error-border form-input-fonts' : 'form-input-email text-white form-input-fonts'} placeholder={t('sign-up.placeholder.password')} variant='outlined' type='password' name='password' value={formik.values.password} onChange={handleChange} error={formik.touched.password || errorObject?.password} helperText={(formik.touched.password && formik.errors.password) || errorObject?.password} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <InputBox className={(formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)) || errorObject?.confirmPassword ? 'form-input-email text-white error-border form-input-fonts' : 'form-input-email text-white form-input-fonts'} placeholder={t('sign-up.placeholder.confirmPassword')} variant='outlined' type='password' name='confirmPassword' value={formik.values.confirmPassword} onChange={handleChange} error={formik.touched.confirmPassword || errorObject?.confirmPassword} helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || errorObject?.confirmPassword} />
          </Grid>
          {<p className='text-danger'>{generalError}</p>}
          <p className='text-danger'>{errorObject?.non_field_errors}</p>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <FormControl className={`flex flex-wrap align-start terms-conditions ${formik.touched.agree && formik.errors.agree ? 'error-checkbox-custom' : ''}`} error>
              <Checkbox
                disableRipple
                name='agree'
                className='checkbox-custom'
                value={formik.values.agree}
                checked={formik.values.agree}
                error={formik.touched.agree?.toString()}
                onChange={handleChange}
                sx={{
                  '&:hover': { bgcolor: 'transparent' }
                }}
                checkedIcon={<StyledCheckedIcon />}
                icon={<StyledCheckBox />}
                inputProps={{ 'aria-label': 'Checkbox demo' }}
              />
              <p className='text-white'>
                {t('sign-up.agree')} <br />
                <span>{t('sign-up.tnc')}</span>
              </p>
              <FormHelperText className='w-full'>{formik.touched.agree && formik.errors.agree}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className=''>
            <div className='privacy-notice'>
              <p className='text-white font-inter'>
                {t('sign-up.view')} <span>{t('sign-up.privacyNotice')}</span> {t('sign-up.manage')}
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      {!isPrevious && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='footer-button animation' ref={buttonRef}>
            <Link to={LOGIN} variant='outlined' className='outline-button'>
              <p className='button-animation'> {t('sign-up.already')}</p>
            </Link>
            <Button disableRipple variant='contained' className='fill-button' type='submit' onClick={formik.handleSubmit}>
              {t('common.next')} <ArrowForwardIcon />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}

export default CreateAccount;
