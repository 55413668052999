import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTransform, Reorder, motion } from 'framer-motion';

import songs from './songs';
import styles from './styles.module.scss';

import { DotsSixVertical } from '@phosphor-icons/react';

const SetListRow = React.memo(({ song, songToMove, progress }) => {
  const isMoving = song.id === songs[songToMove].id;

  const scale = useTransform(progress, [0, 0.1, 0.9, 1], [1, isMoving ? 1.2 : 1, isMoving ? 1.2 : 1, 1]);

  const backgroundColor = useTransform(progress, [0, 0.1, 0.9, 1], ['#1E2330', isMoving ? '#1ae8ce' : '#1E2330', isMoving ? '#1ae8ce' : '#1E2330', '#1E2330']);

  const color = useTransform(progress, [0, 0.1, 0.9, 1], ['#FFF', isMoving ? '#1E2330' : '#FFF', isMoving ? '#1E2330' : '#FFF', '#FFF']);

  const outline = useTransform(progress, [0, 0.1, 0.9, 1], ['none', isMoving ? 'var(--table-border-width) solid var(--color-chill)' : 'none', isMoving ? 'var(--table-border-width) solid var(--color-chill)' : 'none', 'none']);

  const memoizedContent = useMemo(
    () => (
      <>
        <motion.td>
          <DotsSixVertical weight='bold' />
        </motion.td>
        <motion.td>{song.song}</motion.td>
        <motion.td>{song.artist}</motion.td>
        <motion.td>{song.tempo}</motion.td>
        <motion.td>{song.energy}</motion.td>
        <motion.td>{song.mood}</motion.td>
        <motion.td>{song.key}</motion.td>
      </>
    ),
    [song]
  );

  return (
    <Reorder.Item
      value={song}
      as='tr'
      style={{
        scale,
        backgroundColor,
        color,
        outline,
      }}
      whileDrag={{
        scale: 1.2,
        backgroundColor: '#1ae8ce',
        color: '#1E2330',
        outline: 'var(--table-border-width) solid var(--color-chill)',
      }}
      className={isMoving ? styles.reordered : ''}
    >
      {memoizedContent}
    </Reorder.Item>
  );
});

SetListRow.displayName = 'SetListRow';
SetListRow.propTypes = {
  song: PropTypes.shape({
    id: PropTypes.number.isRequired,
    song: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    tempo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    energy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    mood: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  songToMove: PropTypes.number.isRequired,
  progress: PropTypes.object.isRequired,
};

export default SetListRow;
