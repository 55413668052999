import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
} from '@mui/material';

import ResultChart from './ResultChart';

const ChartCard = React.memo(function ChartCard({ img, alt, value, label, data, colors }) {
  const { t } = useTranslation();

  const formattedValue = useMemo(() => {
    return value && !isNaN(value) && value > 0 ? value : null;
  }, [value]);

  return (
    <Box className='chart-card'>
      <Box className='d-flex chart-card-header' alignItems='center' justifyContent='space-between'>
        <img src={img} alt={t(`table.alt.${alt}`)} width={69} height={68} />
        <Box className='d-flex' flexDirection='column' alignItems='end'>
          {formattedValue && (
            <Typography variant='h6' style={{ color: 'white' }} className='chart-report-number'>
              {formattedValue}
            </Typography>
          )}
          <Typography variant='h6' style={{ color: 'white' }} className='chart-report'>
            {t(`table.${label}`)}
          </Typography>
        </Box>
      </Box>
      <ResultChart data={data} color1={colors[0]} color2={colors[1]} />
    </Box>
  );
});

ChartCard.propTypes = {
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ChartCard;
