import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoader, retrieveProfile } from '../redux/slice/profile';
import { updateLoginCredential } from '../redux/slice/login';
import PaymentSuccessImg from '../assets/styles/img/payment-success.png';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE } from '../constants/PageName';
import { resetClickedRoute } from '../redux/slice/clickedRoute';
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {
  const { t } = useTranslation();
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clickedRoute = useSelector((state) => state.clickedRoute.clickedRoute);

  // Function to handle redirection to saved click route page
  const redirectHandler = () => {
    if (clickedRoute) {
      let saveRoute = clickedRoute;
      dispatch(resetClickedRoute(null));
      navigate(saveRoute);
    } else {
      navigate(HOME_PAGE);
    }
  };

  useEffect(() => {
    dispatch(retrieveProfile())
      .then((result) => {
        if (result.payload.subscription_details != null) {
          const loginData = JSON.parse(JSON.stringify(data.login));
          loginData.loginCred.subscription_details = result.payload.subscription_details;
          dispatch(updateLoginCredential(loginData.loginCred));
        }
      })
      .catch((error) => {
        dispatch(updateLoader(false));
      });
  }, []);

  return (
    <div className='child-main payment-status'>
      <div className='fade-in one'>
        <div className='page-not-found'>
          <div>
            <Box className='page-not-found-text'>
              <Typography variant='h1' component='h1' className='heading-text text-upper'>
                <Box className='bluetape_cya'></Box>
                {t('paymentSuccess.success')}
              </Typography>
              <img src={PaymentSuccessImg} alt='payment success' />
              <Typography variant='h2' component='h2' className='heading-text-below'>
                {t('paymentSuccess.yourPaymentWasSuccessfully')}
              </Typography>
            </Box>
            {/* <h3></h3> */}
            <div className='back-to-home-page-button'>
              <Button
                disableRipple
                variant='outlined'
                className='outline-back-button'
                onClick={() => redirectHandler()}
              >
                {t('paymentSuccess.continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
