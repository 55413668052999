import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import clsx from 'clsx';
import styles from './MotionButton.module.scss';

const DURATION = 0.3;

const MotionButtonNew = ({
  children = [],
  bgColors = ['var(--color-off-white)'],
  borderColors = ['var(--color-hard)'],
  borderRadii = ['var(--size-fluid-sm)'],
  colors = ['var(--color-hard)'],
  className = '',
  style = {},
  onClick = () => {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonVariants = {
    initial: {
      backgroundColor: bgColors[0],
      borderColor: borderColors[0],
      borderRadius: borderRadii[0],
      color: colors[0],
    },
    hover: {
      backgroundColor: bgColors[1] || bgColors[0],
      borderColor: borderColors[1] || borderColors[0],
      borderRadius: borderRadii[1] || borderRadii[0],
      color: colors[1] || colors[0],
    },
  };

  const textWrapperVariants = {
    initial: { width: 'auto', opacity: 1 },
    hover: { width: 0, opacity: 0 },
  };

  const renderChildren = (index) => {
    if (Array.isArray(children) && children.length > 1) {
      return children[index] || children[0];
    }
    return children;
  };

  return (
    <motion.button
      className={clsx("px-[1.25em] py-[1em] overflow-hidden border-1 border-current border-solid cursor-pointer", className)}
      initial="initial"
      animate={isHovered ? 'hover' : 'initial'}
      variants={buttonVariants}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      style={style}
      transition={{
        duration: DURATION,
        ease: 'easeInOut',
      }}
      onClick={onClick}
    >
      <div className={clsx(styles.textWrapper, "grid")}>
        <motion.div
          className={clsx(styles.textContainer, "inline-flex items-center justify-center gap-[0.5em] whitespace-nowrap pointer-events-none select-none")}
          initial="initial"
          animate={isHovered ? 'hover' : 'initial'}
          variants={textWrapperVariants}
          transition={{
            duration: DURATION,
            ease: isHovered ? 'circIn' : 'circOut'
          }}
        >
          {renderChildren(0)}
        </motion.div>
        <motion.div
          className={clsx(styles.textContainer, "inline-flex items-center justify-center gap-[0.5em] whitespace-nowrap pointer-events-none select-none")}
          initial="hover"
          animate={isHovered ? 'initial' : 'hover'}
          variants={textWrapperVariants}
          transition={{
            duration: DURATION,
            ease: isHovered ? 'circOut' : 'circIn'
          }}
        >
          {renderChildren(1)}
        </motion.div>
      </div>
    </motion.button>
  );
};

MotionButtonNew.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bgColors: PropTypes.arrayOf(PropTypes.string),
  borderColors: PropTypes.arrayOf(PropTypes.string),
  borderRadii: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default MotionButtonNew;
