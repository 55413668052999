import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import clsx from 'clsx';

import styles from './styles.module.scss';

import { Pause, Play, ArrowCounterClockwise } from '@phosphor-icons/react';

const TOTAL_DURATION = 5; // Total duration in seconds

const useProgress = (totalDuration) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const controls = useAnimation();
  const progressRef = useRef(null);

  const startProgress = (startFrom = progress) => {
    setIsPlaying(true);
    setIsFinished(false);
    const remainingProgress = 100 - startFrom;
    const remainingDuration = (remainingProgress / 100) * totalDuration;

    controls.start({
      width: '100%',
      transition: {
        duration: remainingDuration,
        ease: 'linear',
      },
    });
  };

  const stopProgress = () => {
    setIsPlaying(false);
    controls.stop();
  };

  const restartProgress = () => {
    stopProgress();
    setProgress(0);
    setIsFinished(false);
    controls.set({ width: '0%' });
    setTimeout(() => startProgress(0), 0);
  };

  const updateProgress = () => {
    if (progressRef.current) {
      const width = progressRef.current.style.width;
      const currentProgress = parseFloat(width);
      setProgress(currentProgress);
      if (currentProgress >= 100) {
        setIsFinished(true);
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(updateProgress, 50);
    return () => clearInterval(interval);
  }, []);

  return {
    isPlaying,
    progress,
    isFinished,
    controls,
    progressRef,
    startProgress,
    stopProgress,
    restartProgress,
    setProgress,
    setIsPlaying,
    setIsFinished,
  };
};

const FauxPlayer = ({ className }) => {
  const { isPlaying, progress, isFinished, controls, progressRef, startProgress, stopProgress, restartProgress, setProgress, setIsPlaying, setIsFinished } = useProgress(TOTAL_DURATION);

  const togglePlayPause = () => {
    if (isFinished) {
      restartProgress();
    } else if (isPlaying) {
      stopProgress();
    } else {
      startProgress();
    }
  };

  const handleSliderChange = (e) => {
    const newProgress = Number(e.target.value);
    setProgress(newProgress);
    setIsPlaying(false);
    setIsFinished(newProgress >= 100);
    controls.set({ width: `${newProgress}%` });
  };

  useEffect(() => {
    startProgress(0); // Auto-play when component mounts
  }, []);

  const renderIcon = () => {
    if (isFinished) return <ArrowCounterClockwise weight='bold' />;
    return isPlaying ? <Pause weight='fill' /> : <Play weight='fill' />;
  };

  return (
    <div className={clsx(styles.fauxPlayer, className)}>
      <motion.div ref={progressRef} className={styles.fauxProgress} initial={{ width: '0%' }} animate={controls} />
      <input type='range' min='0' max='100' value={progress} onChange={handleSliderChange} className={styles.fauxSlider} />
      <button
        className={clsx(styles.button, styles.fauxControl)}
        onClick={(e) => {
          e.stopPropagation();
          togglePlayPause();
        }}
      >
        {renderIcon()}
      </button>
    </div>
  );
};

FauxPlayer.propTypes = {
  className: PropTypes.string,
};

export default FauxPlayer;
