import React from 'react';
import { CheckCircle } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useStore } from '@tanstack/react-store';
import Title from '@/components/new/Title';
import { store, toggleHarmonic } from '@/views/AllInOne/store';
import styles from '@/views/AllInOne/styles.module.scss';
import harmonicSvg from '@/views/AllInOne/harmonic.svg';

const Harmonic = () => {
  const isHarmonicActive = useStore(store, (state) => state.isHarmonicActive);

  return (
    <div className='harmonic-panel'>
      <Title className='mb-4 text-xl lg:text-2xl xl:text-3xl'>
        <h3>Harmonic</h3>
      </Title>
      <button onClick={toggleHarmonic} className={clsx(styles.harmonic, isHarmonicActive && styles.harmonic__active, 'grid-stack mx-auto grid items-center justify-center')} aria-pressed={isHarmonicActive} type='button'>
        <CheckCircle weight='duotone' className='text-3xl' aria-hidden='true' />
        <img className={styles.harmonic__image} src={harmonicSvg} alt='' width={120} height={120} />
        <span className='sr-only'>Toggle Harmonic</span>
      </button>
    </div>
  );
};

export default React.memo(Harmonic);
