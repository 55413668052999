import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HOME_PAGE } from '../constants/PageName';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const saveRoute = useSelector((state) => state?.clickedRoute?.clickedRoute);
  const handleBackToHome = () => {
    if (saveRoute) {
      navigate(saveRoute);
    } else {
      navigate(HOME_PAGE);
    }
  };
  return (
    <div className='child-main'>
      <div className='fade-in one'>
        <div className='page-not-found'>
          <div>
            <Box className='page-not-found-text'>
              <Typography variant='h1' component='h1' className='heading-text text-upper'>
                <Box className='bluetape_cya'></Box>
                {t('notFound.oops')}
              </Typography>
              <h2>{t('notFound.404')}</h2>
              <Typography variant='h2' component='h2' className='heading-text-below'>
                {t('notFound.SorryWeCouldnotFind')}
              </Typography>
            </Box>
            <h3>{t('notFound.butDontWorry')}</h3>
            <div className='back-to-home-page-button'>
              <Button
                disableRipple
                variant='outlined'
                onClick={handleBackToHome}
                className='outline-back-button'
              >
                {t('notFound.backToHome')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
