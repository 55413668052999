import React from 'react';
import PropTypes from 'prop-types';
import { MusicNotesSimple, User } from '@phosphor-icons/react';

const Suggestions = ({ suggestions, onSelect, loading }) => {
  const hasResults = suggestions.length > 0 && !(suggestions.length === 1 && suggestions[0].artist === 'No similar artist found' && suggestions[0].song === 'No similar song found');

  if (loading) {
    return;
  }

  if (!hasResults) {
    return (
      <div className='px-4'>
        No suggestions. Press <kbd>Enter</kbd> to add.
      </div>
    );
  }

  return (
    <ul>
      {suggestions.map((suggestion) => (
        <li key={`${suggestion.songID}-${suggestion.artist}-${suggestion.song}`} className='uppercase font-display px-4 py-2 flex gap-3 cursor-pointer hover:bg-hard/10' onClick={() => onSelect(suggestion)}>
          <span>{suggestion.song !== 'N/A' ? <MusicNotesSimple weight='bold' className='mt-1' /> : <User weight='bold' className='mt-1' />}</span>
          <span>
            <strong className='font-semibold'>{suggestion.artist}</strong>
            {suggestion.song !== 'N/A' ? ` - ${suggestion.song}` : ''}
          </span>
        </li>
      ))}
    </ul>
  );
};

Suggestions.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default Suggestions;