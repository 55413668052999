import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { Reorder } from 'framer-motion';

const ReorderableTableBody = React.memo(function ReorderableTableBody({
  playlistData,
  setPlaylistData,
  isLoading,
  t
}) {
  if (isLoading) {
    return (
      <TableBody>
        {Array.from({ length: 11 }, (_, index) => (
          <TableRow key={`loading-${index}`}>
            {index === 0 ? (
              <TableCell className='text-teal-glow font-inter'>{t('table.hangtight')}</TableCell>
            ) : (
              Array.from({ length: 6 }, (_, cellIndex) => (
                <TableCell key={`loading-cell-${index}-${cellIndex}`} className='text-white'>
                  <div className='table-loader-data song-load'></div>
                </TableCell>
              ))
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (playlistData.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell
            className='mobile-hide font-inter'
            style={{ color: 'white', textAlign: 'center' }}
            colSpan={6}
          >
            {t('table.noDataPresent')}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <Reorder.Group as={TableBody} axis='y' values={playlistData} onReorder={setPlaylistData}>
      {playlistData.map((song) => (
        <Reorder.Item key={song.stableId} value={song} as={TableRow} className='reorderable-row'>
          {['name', 'artist', 'genre', 'tempo', 'valence', 'danceability'].map((field, index) => (
            <TableCell
              key={field}
              className={`font-inter ${index > 1 ? 'mobile-hide' : ''}`}
              style={{ color: 'white' }}
            >
              {song[field]}
            </TableCell>
          ))}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  );
});

ReorderableTableBody.propTypes = {
  playlistData: PropTypes.arrayOf(
    PropTypes.shape({
      stableId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      artist: PropTypes.string, // Changed from .isRequired to allow null values
      genre: PropTypes.string,
      tempo: PropTypes.number,
      valence: PropTypes.number,
      danceability: PropTypes.number
    })
  ).isRequired,
  setPlaylistData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default ReorderableTableBody;
