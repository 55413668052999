import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

import styles from './styles.module.scss';

const BPMSlider = React.memo(({ min = 65, max = 185, start = [90, 120], onChange, step = 1 }) => {
  const sliderRef = useRef(null);
  const sliderInstance = useRef(null);
  const [range, setRange] = useState(start);

  const vibeRanges = [
    { name: 'chill', min: 75, max: 95, label: 'CHILL / RELAXED' },
    { name: 'focus', min: 90, max: 110, label: 'WORK / FOCUS' },
    { name: 'party', min: 105, max: 145, label: 'DANCE PARTY / EVENT' },
    { name: 'club', min: 130, max: 170, label: 'CLUB / GYM / FESTIVAL' }
  ];

  const getPipsConfig = useCallback(() => {
    const width = window.innerWidth;
    if (width < 600) {
      return { mode: 'count', values: 7, density: 9 };
    } else if (width < 1301) {
      return { mode: 'count', values: 9, density: 6 };
    } else if (width < 1451) {
      return { mode: 'count', values: 7, density: 9 };
    } else {
      return { mode: 'count', values: 9, density: 6 };
    }
  }, []);

  const updateHighlight = useCallback(
    (values) => {
      const highlightRef = sliderRef.current?.querySelector(`.${styles.highlight}`);
      if (highlightRef) {
        const [left, right] = values.map(Number);
        const leftPercent = ((left - min) / (max - min)) * 100;
        const rightPercent = ((right - min) / (max - min)) * 100;
        highlightRef.style.left = `${leftPercent}%`;
        highlightRef.style.width = `${rightPercent - leftPercent}%`;
      }
    },
    [min, max]
  );

  const updateVibeLabels = useCallback(
    (values) => {
      if (!sliderRef.current) return;
      const [rangeMin, rangeMax] = values.map(Number);
      const vibeLabels = sliderRef.current.querySelectorAll(`.${styles.vibeLabel}`);

      vibeLabels.forEach((label, index) => {
        const vibe = vibeRanges[index];
        const isInRange = vibe.min <= rangeMax && vibe.max >= rangeMin;
        label.classList.toggle(styles.inRange, isInRange);
      });
    },
    [vibeRanges]
  );

  const handleUpdate = useCallback(
    (values) => {
      const newValues = values.map(Number);
      updateHighlight(newValues);
      updateVibeLabels(newValues);
      if (onChange && JSON.stringify(newValues) !== JSON.stringify(range)) {
        setRange(newValues);
        onChange(newValues);
      }
    },
    [onChange, range, updateHighlight, updateVibeLabels]
  );

  useEffect(() => {
    if (!sliderRef.current || sliderInstance.current) return;

    sliderInstance.current = noUiSlider.create(sliderRef.current, {
      start: range,
      connect: true,
      range: { min, max },
      step,
      tooltips: true,
      pips: getPipsConfig(),
      format: {
        to: (value) => Math.round(value),
        from: (value) => Number(value)
      }
    });

    sliderInstance.current.on('update', handleUpdate);

    // Add vibe labels and highlight to the slider
    const sliderContainer = sliderRef.current;
    const highlightOuter = document.createElement('div');
    highlightOuter.className = styles.highlightOuter;

    const highlight = document.createElement('div');
    highlight.className = styles.highlight;
    highlightOuter.appendChild(highlight);

    const vibeRange = document.createElement('div');
    vibeRange.className = styles.vibeRange;

    vibeRanges.forEach((vibe) => {
      const vibeLabel = document.createElement('div');
      vibeLabel.className = clsx(styles.vibeLabel, styles[`vibeLabel__${vibe.name}`]);
      vibeLabel.innerHTML = `<span>${vibe.label}<br/>${vibe.min}-${vibe.max}BPM</span>`;
      vibeLabel.style.marginLeft = `${((vibe.min - min) / (max - min)) * 100}%`;
      vibeLabel.style.marginRight = `${100 - ((vibe.max - min) / (max - min)) * 100}%`;
      vibeRange.appendChild(vibeLabel);
    });

    highlightOuter.appendChild(vibeRange);
    sliderContainer.appendChild(highlightOuter);

    // Initial update
    handleUpdate(range);

    return () => {
      if (sliderInstance.current) {
        sliderInstance.current.destroy();
        sliderInstance.current = null;
      }
    };
  }, [min, max, step, range, handleUpdate, vibeRanges, getPipsConfig]);

  useEffect(() => {
    const handleResize = () => {
      if (sliderInstance.current) {
        sliderInstance.current.updateOptions({
          pips: getPipsConfig()
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getPipsConfig]);

  return (
    <div className={styles.sliderContainer}>
      <div ref={sliderRef} className={styles.slider} />
    </div>
  );
});

BPMSlider.displayName = 'BPMSlider';

BPMSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  start: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  step: PropTypes.number
};

export default BPMSlider;
