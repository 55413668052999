import { createSlice } from '@reduxjs/toolkit';
import apiConfig from '../../composable/api.json';
import { createAPIUtils } from './apiUtils';

const initialStateValue = {
  formData: {
    first_name: '',
    last_name: '',
    username: '',
    password: '',
    confirmPassword: '',
    agree: false,
    dj: '',
    dob: null
  },
  fieldErrors: '',
  generalError: '',
  loginCred: JSON.parse(localStorage.getItem('login'))
};

const createAccountSlice = createSlice({
  name: 'createaccount',
  initialState: { ...initialStateValue },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialStateValue.formData;
    },
    setFieldError(state, action) {
      const fieldName = action.payload;
      state.fieldErrors = fieldName;
      state.generalError = 'Kindly review your form again!';
    },
    resetFieldErrors(state) {
      state.fieldErrors = {};
      state.generalError = '';
    }
  }
});

export const { updateFormData, resetFormData, setFieldError, resetFieldErrors } =
  createAccountSlice.actions;

const apiUtils = createAPIUtils({
  setFieldError
});

const { createAPIAsyncThunk } = apiUtils;

export const registerUser = createAPIAsyncThunk('registeruser', apiConfig.registeruser);
export default createAccountSlice.reducer;