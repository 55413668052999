import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';
import Ribbon from '@/components/new/Ribbon';
import MotionButton from '@/components/new/MotionButton';
import MotionCollapse from '@/components/new/MotionCollapse';

import { ArrowRight, Play, X } from '@phosphor-icons/react';

import VideoSplash from '@/assets/images/video_splash.png';

import { ALLINONE } from '@/constants/PageName';

function HeroSection() {
  const navigate = useNavigate();

  const [showVideo, setShowVideo] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const checkTouchDevice = () => {
      if (window.matchMedia('(pointer: coarse)').matches) {
        setIsTouchDevice(true);
      }
    };
    checkTouchDevice();
  }, []);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  return (
    <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
      <Container>
        <div className='space-y-6 lg:space-y-8 xl:space-y-10'>
          <Title size={5} className='max-w-screen-lg font-semibold'>
            <h1>
              Vibeset.ai curates a set list that <Ribbon className='dance'>any DJ</Ribbon> can mix seamlessly together
            </h1>
          </Title>

          <Lead size={1}>
            <p>You ideate and create, we organize.</p>
          </Lead>

          <div className='flex gap-4'>
            <MotionButton className='px-8 py-5' bgColors={['#061492', 'var(--color-dance)']} borderColors={['#FFF', 'var(--color-dance)']} borderRadii={['0.75em', '2em']} colors={['#FFF']} onClick={() => navigate(ALLINONE)}>
              {['Play for free', 'Try DEMO']}
            </MotionButton>
            <MotionButton className='px-8 py-5 font-bold uppercase' bgColors={['var(--color-electric)']} borderColors={['var(--color-electric)']} borderRadii={['1.75em']} colors={['#000']} onClick={toggleVideo}>
              {[
                <React.Fragment key='initial'>
                  Watch video <Play weight='fill' className='text-xl' />
                </React.Fragment>,
                <React.Fragment key='hover'>
                  See the magic <ArrowRight weight='bold' className='text-xl' />
                </React.Fragment>,
              ]}
            </MotionButton>
          </div>

          <MotionCollapse isOpen={showVideo}>
            <img src={VideoSplash} alt='' />
            <iframe src={isTouchDevice ? 'https://player.vimeo.com/video/961687745?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' : 'https://player.vimeo.com/video/917300156?h=12dfecd6c4'} allow='autoplay; fullscreen' allowFullScreen style={{ aspectRatio: isTouchDevice ? '4/5' : '16/9', border: 'unset', width: '100%' }}></iframe>

            <button className='z-10 mb-auto ml-auto mr-4 mt-4 rounded-full bg-off-white p-3 text-hard' onClick={toggleVideo}>
              <X key='initial' weight='bold' className='text-xl' />
            </button>
          </MotionCollapse>
        </div>
      </Container>
    </section>
  );
}

export default HeroSection;
