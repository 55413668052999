import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import createAC from '../assets/styles/img/createAC.png';
import { LOGIN } from '../constants/PageName';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { forgotPasswordValidationSchema } from '../validation/Formvalidation';
import { forgotpassword, updateLoader, resetFieldErrors } from '../redux/slice/login';
import Loader from '../components/Loader';
import { showToast } from '../redux/slice/toastSlice';
import { InputBox } from '../components/mui-components/Account';
function ForgotPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.login.formData);
  const loader = useSelector((state) => state.login.loading);
  const navigate = useNavigate();
  const errorObject = useSelector((state) => state.login.fieldErrors);

  const validationSchema = forgotPasswordValidationSchema;

  // Function to handle the next click event
  const handleNextClick = async () => {
    try {
      dispatch(updateLoader(true));
      const action = await dispatch(forgotpassword(formik.values));

      const response = action.payload;
      if (response) {
        dispatch(showToast({ message: response?.message, type: 'success' }));
        navigate(LOGIN);
        dispatch(updateLoader(false));
      } else {
        // only settimeout will be removed in future, not dispatch one's
        setTimeout(() => {
          dispatch(updateLoader(false));
        }, 2000);
      }
    } catch (error) {
      // only settimeout will be removed in future, not dispatch one's
      setTimeout(() => {
        dispatch(updateLoader(false));
      }, 10000);
      return error;
    }
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: () => {
      handleNextClick();
    }
  });

  useEffect(() => {
    dispatch(resetFieldErrors());
  }, [formik.values, dispatch]);
  return (
    <div>
      {loader ? <Loader /> : ''}
      <div className='child-main create-account-main fade-in one'>
        <Grid container spacing={10} alignItems='center' justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <div className='create-account-img'>
              <img srcSet={createAC} alt={t('login.alt.create')} />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className='create-account authorize-pages'>
              <div className='heading'>
                <Box className='centerright'>
                  <Typography variant='h2' component='h2' className='ish1 text-upper '>
                    <Box className='pinktape_cya'></Box>
                    {t('login.forgotpassword')}
                  </Typography>
                  <Typography variant='h2' component='h2' className='ish1below  text-white'>
                    {t('login.forgotpassworddescription')}
                  </Typography>
                </Box>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={10} lg={10}>
                    <InputBox
                      className={
                        (formik.touched.email && Boolean(formik.errors.email)) || errorObject?.email
                          ? 'form-input-email text-white error-border'
                          : 'form-input-email text-white'
                      }
                      placeholder={t('login.placeholder.email')}
                      variant='outlined'
                      name='email'
                      value={formik.values.email}
                      // onChange={handleInputChange}
                      onChange={formik.handleChange}
                      error={
                        (formik.touched.email && Boolean(formik.errors.email)) ||
                        Boolean(errorObject?.email)
                      }
                      helperText={
                        (formik.touched.email && formik.errors.email) || errorObject?.email
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className='flex footer-button customer-footer-button-forget'>
                      <Link variant='outlined' className='outline-button' to={LOGIN}>
                        {t('common.back')}
                      </Link>
                      <Button
                        type='submit'
                        className='btn-text-blue btn-bg-teal-blue primary button btn-med-white link-button-effect mr-15'
                        rel='noopener noreferrer'
                      >
                        {t('common.send')}
                      </Button>
                    </div>
                    <p className='text-danger'>
                      {errorObject?.non_field_errors || errorObject?.message}
                    </p>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ForgotPassword;
