import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Letsvibebutton from '../Button/Letsvibe';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useFormik } from 'formik';
import { SetlistCreationValidation } from '../../../validation/Formvalidation';
import { BUILD_SET, LETS_VIBE, QUICK_MIX, TABLE_DATA } from '../../../constants/PageName';
import { useDispatch, useSelector } from 'react-redux';
import { addGuidedMix, addQuickMix, setCurrentPage } from '../../../redux/slice/vibeset';
import blueBackground from '../../../assets/styles/img/blue-white-line-image.svg';
import { useTranslation } from 'react-i18next';

function SetlistCreation() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email:
        data.vibeset.mode == QUICK_MIX
          ? data.vibeset.quickMix.email
          : data.vibeset.guidedMix.email || ''
    },
    validationSchema: SetlistCreationValidation,
    onSubmit: (values) => {
      if (data.vibeset.mode == QUICK_MIX) {
        const dataQuick = { prompt: data.vibeset.quickMix.prompt, email: values.email };
        dispatch(addQuickMix(dataQuick));
      } else {
        const dataGuided = {
          genre: data.vibeset.guidedMix.genre,
          bpm: data.vibeset.guidedMix.bpm,
          email: values.email,
          song_artist: data.vibeset.guidedMix.song_artist,
          valence: data.vibeset.guidedMix.valence
        };
        dispatch(addGuidedMix(dataGuided));
      }

      dispatch(setCurrentPage(TABLE_DATA));
    }
  });

  // Function to navigate to a specific page
  const goBackHandler = () => {
    if (data.vibeset.mode == QUICK_MIX) {
      dispatch(setCurrentPage(LETS_VIBE));
    } else {
      dispatch(setCurrentPage(BUILD_SET));
    }
  };

  return (
    <>
      <Box className='child-main fade-in one'>
        <Box className='setlist-email-container'>
          <Box className='set-list-form'>
            <Box>
              <Typography variant='h2' className='is-heading-h2 bold-txt'>
                {t('set-list-creation.creation')}
              </Typography>
              <Box className='txt-box'>
                <Typography variant='p' className='is-txt-large text-white'>
                  {t('set-list-creation.helps')}
                </Typography>
              </Box>
            </Box>

            <TextField
              className='form-input-email text-white'
              placeholder={t('set-list-creation.placeholder.email')}
              variant='outlined'
              name='email'
              id='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched.email && Boolean(formik.errors.email)}
              // helperText={formik.touched.email && formik.errors.email}
            />
            <Box className='mt-btn-box d-flex'>
              <Letsvibebutton
                className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
                onClick={() => goBackHandler()}
              >
                {t('common.back')}
              </Letsvibebutton>
              <Letsvibebutton
                onClick={formik.handleSubmit}
                className={`btn-text-blue btn-bg-teal-blue primary ${formik.touched.email && !formik.errors.email && formik.isValid ? '' : 'btn-color-change'}`}
                // className='btn-text-blue btn-bg-teal-blue primary'
                disabled={!formik.isValid || !formik.touched.email || Boolean(formik.errors.email)}
              >
                {t('common.letsVibe')} <ArrowForwardIcon />
              </Letsvibebutton>
            </Box>
          </Box>
          <Box className='email-img'>
            <img srcSet={blueBackground} alt={t('common.alt.vibeset')} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SetlistCreation;
