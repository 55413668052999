import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Reorder } from 'framer-motion';

import SetListRow from './SetListRow';
import styles from './styles.module.scss';

const SetListTable = React.memo(({ items, setItems, songToMove, progress, isParallaxActive }) => {
  const memoizedRows = useMemo(
    () =>
      items.map((song) => (
        <SetListRow
          key={song.id}
          song={song}
          songToMove={songToMove}
          progress={progress}
          isParallaxActive={isParallaxActive}
        />
      )),
    [items, songToMove, progress, isParallaxActive]
  );

  return (
    <div className={styles.table__scroll}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>Song</th>
            <th>Artist</th>
            <th>Tempo</th>
            <th>Energy</th>
            <th>Mood</th>
            <th>Key</th>
          </tr>
        </thead>
        <Reorder.Group axis="y" values={items} onReorder={setItems} as="tbody">
          {memoizedRows}
        </Reorder.Group>
      </table>
    </div>
  );
});

SetListTable.displayName = 'SetListTable';
SetListTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  setItems: PropTypes.func.isRequired,
  songToMove: PropTypes.number.isRequired,
  progress: PropTypes.object.isRequired,
  isParallaxActive: PropTypes.object.isRequired,
};

export default SetListTable;