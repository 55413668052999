import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import Bubbles from '@/components/new/Bubbles';
import Panel from '@/views/AllInOne/atoms/Panel';
import Title from '@/components/new/Title';
import { store } from '@/views/AllInOne/store';

const genreItems = ['Alternative', 'R&B', 'Pop', 'Electronic', 'Hip Hop', 'K-pop', 'EDM', 'Synth-pop', 'Indie Pop', 'Electropop', 'Goa Trance', 'Psytrance', 'Soul', 'Hard Dance', 'Dance Electro Pop', 'Bass House', 'Tech House', 'Dubstep', 'Progressive House', 'Trap', 'Afro House', 'Melodic House / Techno', 'Drum and Bass', 'Techno', 'Trance', 'House', 'Mainstage'];

const TIMEOUT_DURATION = 5000; // 5 seconds

const GenrePanel = ({ className }) => {
  const [isActive, setIsActive] = useState(true);
  const timeoutRef = useRef(null);
  const isHoveringRef = useRef(false);

  const handleSelectionChange = useCallback((selectedGenres) => {
    store.setState((state) => ({
      ...state,
      genre: selectedGenres,
    }));
  }, []);

  const deactivateBubbles = useCallback(() => {
    if (!isHoveringRef.current) {
      setIsActive(false);
    }
  }, []);

  const activateBubbles = useCallback(() => {
    setIsActive(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  const startDeactivationTimer = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(deactivateBubbles, TIMEOUT_DURATION);
  }, [deactivateBubbles]);

  useEffect(() => {
    startDeactivationTimer();
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [startDeactivationTimer]);

  const handleInteractionStart = useCallback(() => {
    isHoveringRef.current = true;
    activateBubbles();
  }, [activateBubbles]);

  const handleInteractionEnd = useCallback(() => {
    isHoveringRef.current = false;
    startDeactivationTimer();
  }, [startDeactivationTimer]);

  return (
    <Panel className={className}>
      <div className='grid-stack h-full'>
        <Title className='z-10 mx-auto mt-6 mb-auto rounded-4 bg-white/10 px-3 py-1 font-mono text-3xl uppercase shadow-[inset_0_0_0.25em_-0.125em_currentColor] backdrop-blur lg:text-4xl'>
          <h2>Genre selection</h2>
        </Title>
        <div onMouseEnter={handleInteractionStart} onMouseLeave={handleInteractionEnd} onTouchStart={handleInteractionStart} onTouchEnd={handleInteractionEnd}>
          <Bubbles 
            isActive={isActive} 
            bubblesArray={genreItems} 
            className='text-xl lg:text-2xl xl:text-3xl' 
            onSelectionChange={handleSelectionChange}
          />
        </div>
      </div>
    </Panel>
  );
};

GenrePanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(GenrePanel);