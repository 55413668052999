// ResultChart.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ResultChart = ({ data, color1, color2 }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Function to get a linear gradient for a chart
    function getGradient(ctx, chartArea, color1, color2) {
      // Create a linear gradient from bottom to top within the chart area
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      // Add color stops to the gradient
      gradient.addColorStop(0, color1); // Start color at the bottom
      gradient.addColorStop(0.5, color2); // Middle color
      gradient.addColorStop(1, color1); // End color at the top
      // Return the gradient
      return gradient;
    }

    // Function to create a line chart
    function createGraph() {
      // Get the 2D rendering context of the chart canvas
      const ctx = chartRef.current.getContext('2d');

      // Check if data is undefined or empty
      if (!data || data.length === 0) {
        // If data is undefined or empty, do nothing and return
        return;
      }

      // Check if there's an existing chart instance, and destroy it if so
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create a new Chart.js instance
      chartInstance.current = new Chart(ctx, {
        type: 'line', // Chart type: line chart
        data: {
          labels: Array.from({ length: data.length }, (_, i) => i + 1), // Generate labels based on data length
          datasets: [
            {
              data: data, // Data points for the chart
              borderWidth: 4, // Border width of the line
              borderColor: function (context) {
                // Function to determine border color gradient
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) {
                  return;
                }
                // Get gradient for border color using helper function getGradient
                return getGradient(ctx, chartArea, color1, color2);
              },
              fill: {
                // Fill configuration
                target: 'origin', // Fill from the origin (x-axis)
                above: function (context) {
                  // Function to determine fill color gradient above the line
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  if (!chartArea) {
                    return;
                  }
                  // Get gradient for fill color using helper function getGradient
                  return getGradient(
                    ctx,
                    chartArea,
                    'rgba(0,212,255,0.1)',
                    'rgba(215,228,231,0.1)'
                  );
                }
              }
            }
          ]
        },
        options: {
          layout: {
            padding: 2 // Padding configuration
          },
          scales: {
            // Scale configuration
            y: {
              beginAtZero: false, // Y-axis starts from non-zero value
              display: true,
              ticks: {
                display: false // Hide y-axis ticks
              },
              grid: {
                color: 'rgba(0,212,255,0.1)' // Grid line color
              },
              border: {
                display: false // Hide y-axis border
              }
            },
            x: {
              display: false // Hide x-axis
            }
          },
          tension: 0.4, // Tension of the line
          plugins: {
            legend: {
              display: false // Hide legend
            },
            labels: {
              display: false // Hide labels
            }
          },
          elements: {
            point: {
              radius: 0 // Point radius
            }
          }
        }
      });
    }

    createGraph();

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, color1, color2]);

  return <canvas ref={chartRef} width='400' height='300'></canvas>;
};

export default ResultChart;
