import React from 'react';

import Text from '@/components/new/Text';

import styles from './styles.module.scss';

function About() {
  return (
    <div className='mt-28'>
      <section className={styles.section}>
        <div className='container mx-auto px-4'>
          <div className={styles.section__inner}>
            <Text.Title tag='h1' variant='sm' className={'width_limiter__lg'}>
              About Vibeset<sup><small>&reg;</small></sup>
            </Text.Title>
            <p>Vibeset® is the ultimate setlist builder for DJs. We&apos;re on a mission to make creating the perfect setlist as easy and interactive as possible. Whether you&apos;re a seasoned DJ playing at a club or at a friend&apos;s party, or simply someone who loves curating the perfect music experience, Vibeset® is here to help you unlock your creativity and become the DJ you&apos;ve always wanted to be.</p>
            <p>Our innovative platform empowers DJs to ideate, plan, and build sets. Easily search and discover tracks, then seamlessly create dynamic setlists tailored to your audience and vibe. With AI-powered features and a user-friendly interface, Vibeset® helps guide the DJ experience. Vibeset® (beta) currently integrates with the top music services and DJ software, making it effortless to go from ideation to a ready-to-mix set in minutes.</p>
            <p>We are a group of creatives, builders, DJ&apos;s, and concert junkies looking to bridge the gap for anyone to become a DJ and level up their music skills.</p>
            <p>
              Join the{' '}
              <a href='https://o6aeuqjhybw.typeform.com/to/ly66v2Fp' target='_blank' rel='noreferrer'>
                mailing list
              </a>{' '}
              to see what we’ve been cooking up.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
