import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@tanstack/react-store';
import Panel from '@/views/AllInOne/atoms/Panel';
import MotionButton from '@/components/new/MotionButton';
import { ArrowRight, PencilLine } from '@phosphor-icons/react';
import { store, fetchSetlist, updateSetlistTitle } from '@/views/AllInOne/store';

const FinalPanel = ({ className }) => {
  const { isLoadingSetlist, autocompleteTags, setlistTitle } = useStore(store);

  const handleButtonClick = () => {
    fetchSetlist();
  };

  const handleTitleChange = (e) => {
    updateSetlistTitle(e.target.value);
  };

  const isButtonDisabled = isLoadingSetlist || autocompleteTags.length === 0;

  const getButtonText = () => {
    if (isLoadingSetlist) return 'Generating setlist...';
    if (autocompleteTags.length === 0) return 'Select artists/songs to continue';
    return 'Done making edits';
  };

  return (
    <Panel className={className}>
      <div className='my-4 px-4 text-lg md:text-xl lg:text-2xl xl:text-3xl'>
        <div className='flex items-center rounded-2 border-1 border-transparent focus-within:border-NRG'>
          <PencilLine className='ml-[0.25em] text-[1.75em] text-NRG' />
          <input 
            type='text' 
            className='w-full grow bg-transparent px-[0.75em] py-[0.5em] font-display text-[1.25em] uppercase tracking-widest outline-0 placeholder:text-[1em] placeholder:opacity-50' 
            placeholder='My super sick set'
            value={setlistTitle}
            onChange={handleTitleChange}
          />
        </div>
        <MotionButton 
          className='mt-4 w-full' 
          bgColors={['var(--color-hard)', 'var(--color-valence)']} 
          borderColors={['var(--color-off-white)', 'var(--color-valence)']} 
          borderRadii={['0.5em', '2em']} 
          colors={['var(--color-off-white)', 'var(--color-hard)']} 
          onClick={handleButtonClick} 
          disabled={isButtonDisabled}
        >
          {[
            <React.Fragment key='initial'>{getButtonText()}</React.Fragment>,
            <div key='hover' className='flex w-full items-center justify-between text-start'>
              <span>Let&lsquo;s vibe</span> <ArrowRight weight='bold' className='text-3xl' />
            </div>,
          ]}
        </MotionButton>
      </div>
    </Panel>
  );
};

FinalPanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(FinalPanel);