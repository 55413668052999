import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ArrowRight } from '@phosphor-icons/react';

import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Ribbon from '@/components/new/Ribbon';
import MotionButton from '@/components/new/MotionButton';

import { ALLINONE } from '@/constants/PageName';

function ValuePropSection() {
  const navigate = useNavigate();

  return (
    <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
      <Container>
        <div className='space-y-6 lg:space-y-8 xl:space-y-10'>
          <Title size={5} className='mx-auto max-w-screen-lg text-center font-semibold'>
            <h2>
              Your 1 hour set list, produced in <Ribbon className='chill'>seconds</Ribbon>
            </h2>
          </Title>

          <div className='flex flex-wrap justify-center gap-4'>
            <MotionButton bgColors={['#061492', 'var(--color-dance)']} borderColors={['#FFF', 'var(--color-dance)']} borderRadii={['0.75em', '2em']} colors={['#FFF']} className='px-8 py-5' onClick={() => navigate(ALLINONE)}>
              {['Play for free', 'Try DEMO']}
            </MotionButton>
            <MotionButton bgColors={['var(--color-valence)']} borderColors={['var(--color-valence)']} borderRadii={['1.75em']} colors={['#000']} className='px-8 py-5 font-bold uppercase' style={{ textTransform: 'uppercase' }} onClick={() => window.open('https://o6aeuqjhybw.typeform.com/to/ly66v2Fp', '_blank', 'noopener,noreferrer')}>
              {[
                <React.Fragment key='initial'>
                  Early access <ArrowRight weight='bold' className='text-xl' />
                </React.Fragment>,
                <React.Fragment key='hover'>
                  Let&lsquo;s vibe <ArrowRight weight='bold' className='text-xl' />
                </React.Fragment>,
              ]}
            </MotionButton>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ValuePropSection;
