import React from 'react';
import PropTypes from 'prop-types';

const Limited = ({ className }) => (
  <svg viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path d='M20 36.817C11.18 36.817 4 29.637 4 20.817C4 11.997 11.18 4.81702 20 4.81702C28.82 4.81702 36 11.997 36 20.817C36 29.637 28.82 36.817 20 36.817ZM20 0.817017C17.3736 0.817017 14.7728 1.33433 12.3463 2.33943C9.91982 3.34452 7.71504 4.81771 5.85786 6.67488C2.10714 10.4256 0 15.5127 0 20.817C0 26.1213 2.10714 31.2084 5.85786 34.9592C7.71504 36.8163 9.91982 38.2895 12.3463 39.2946C14.7728 40.2997 17.3736 40.817 20 40.817C25.3043 40.817 30.3914 38.7099 34.1421 34.9592C37.8929 31.2084 40 26.1213 40 20.817C40 18.1906 39.4827 15.5899 38.4776 13.1633C37.4725 10.7368 35.9993 8.53205 34.1421 6.67488C32.285 4.81771 30.0802 3.34452 27.6537 2.33943C25.2272 1.33433 22.6264 0.817017 20 0.817017ZM10 22.817H30V18.817H10' fill='currentColor' />
  </svg>
);

Limited.propTypes = {
  className: PropTypes.string
};

export default Limited;
