import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Lead = ({ children, className, style }) => {
  return <p className={clsx("mb-2 last:mb-0 font-light tracking-tight text-lg md:text-xl lg:text-2xl xl:text-3xl", className)} style={style}>{children}</p>;
};

Lead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

Lead.displayName = 'Lead';

export default Lead;