import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setVibeMode } from '@/redux/slice/vibeset';

import Buildyourset from '@/components/layout/Guided-mix/Buildyourset';
import FeelItOut from '@/components/layout/Guided-mix/Feelitout';
import SetlistCreation from '@/components/layout/Quick-mix/SetlistCreation';
import SetListNatural from '@/components/layout/Quick-mix/SetlistNatural';

import BpmRange from '@/components/BpmRange';
import CreateAccount from '@/components/account/CreateAccount';
// import DataTable from '@/components/table';
import SetlistGuided from '@/components/SetlistGuided';
import SetListOutput from '@/components/new/SetListOutput';

import {
  BPM_RANGE,
  BUILD_SET,
  FEEL_OUT,
  GUIDED_MIX,
  LETS_VIBE,
  LIST_CREATION,
  LIST_GUIDED,
  LIST_NATURAL,
  TABLE_DATA,
  SIGN_UP_ACCOUNT
} from '@/constants/PageName';

function SetListGuidedHome() {
  const vibeSetCurrentPage = useSelector((state) => state.vibeset?.currentPage);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setVibeMode(GUIDED_MIX));
    if (vibeSetCurrentPage !== LETS_VIBE) dispatch(setCurrentPage(LETS_VIBE));
  }, []);

  const ennumOfComponent = {
    [LETS_VIBE]: <SetlistGuided />,
    [LIST_NATURAL]: <SetListNatural />,
    [LIST_CREATION]: <SetlistCreation />,
    [FEEL_OUT]: <FeelItOut />,
    [BUILD_SET]: <Buildyourset />,
    [LIST_GUIDED]: <SetlistGuided />,
    [BPM_RANGE]: <BpmRange />,
    [TABLE_DATA]: <SetListOutput />,
    [SIGN_UP_ACCOUNT]: <CreateAccount />
  };
  return <div className='container mx-auto px-4'>{ennumOfComponent[vibeSetCurrentPage]}</div>;
}

export default SetListGuidedHome;
