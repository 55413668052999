// formValidation.js

import * as yup from 'yup';

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 13);

// Validation schema for SetlistCreation.js
export const SetlistCreationValidation = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

// Validation schema for the SetlistNatural.js
export const SetlistNaturalValidationSchema = yup.object().shape({
  description: yup.string().required('Description is required')
});

// Validation schema for the Buildyouset.js
export const BuildyousetValidationSchema = yup.object().shape({
  artist: yup.string().required('Artist is required')
});

// Validation schema for the Buildyouset.js
export const SetlistGuidedValidationSchema = yup.object().shape({
  selectedPills: yup
    .object()
    .test('atLeastOneTrue', 'Please select at least one genre.', (obj) =>
      Object.values(obj).some((e) => e == 'true')
    )
});

// Validation schema for the createAccount.js
export const createAccountValidationSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  username: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, 1 number, and be between 8 and 20 characters long'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .test('password-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
  agree: yup.boolean().oneOf([true], 'Please accept the terms and conditions')
});

// Validation schema for the PaymentDetail.js
export const PaymentDetailValidationSchema = yup.object().shape({
  cardHolderName: yup.string().required('Card Holder Name is required'),
  cardNumber: yup
    .string()
    .matches(/^(?:5[1-5][0-9]{14})$/, 'Invalid credit card number')
    .required('Credit card number is required'),
  expMonth: yup.string().required('EXP month is required'),
  expYear: yup.string().required('EXP year is required'),
  cvv: yup
    .string()
    .min(3, 'CVV is 3-4 digit')
    .max(4, 'CVV is 3-4 digit')
    .required('CVV is required')
});

// Validation schema for the PaymentDetail.js
export const BillingDetailValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  country: yup.string().required('Country is required')
});

// Validation schema for the Login.js
export const loginValidationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 6 characters long')
});

// Validation schema for the resetPassword.js
export const resetPasswordValidationSchema = yup.object().shape({
  // email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, 1 number, and be between 8 and 20 characters long'
    ),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .test('password-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
});

// Validation schema for the forgotPassword.js
export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

// Validation schema for the changePassword.js
export const changePasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,20}$/,
      'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, 1 number, and be between 8 and 20 characters long'
    ),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .test('password-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
});

// Validation schema for the Dj.js
export const DjValidationSchema = yup.object().shape({
  dj: yup.string().required('DJ Name is required')
});

// Validation schema for the Dob.js
export const DobValidationSchema = yup.object().shape({
  dob: yup
    .date()
    .max(minDate, 'You must be at least 13 years old')
    .required('Date of Birth is required')
});

export const ProfileUpdateValidationSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  dob: yup.string().required('Dob is required'),
  dj: yup.string().required('DJ is required')
});
