import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { ABOUT, ALLINONE } from '@/constants/PageName';
import { useDispatch } from 'react-redux';
import { setVibeForFreeFlag, setDemoUsedFlag } from '@/redux/slice/login';

import VibeSetLogo from '@/assets/icons/VibeSetLogo';
import { ArrowRight } from '@phosphor-icons/react';

import { BeatportLogo, DjayLogo, RekordboxLogo, SeratoLogo, SoundcloudLogo, SpotifyLogo, TidalLogo, TraktorLogo } from '@/assets/images/_logos';

import Video from '@/assets/vidoes/Prompting.mp4';

import Container from '@/components/new/Container';
import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';
import Ribbon from '@/components/new/Ribbon';

import MotionButton from '@/components/new/MotionButton';
import AdventureSlideshow from '@/components/new/AdventureSlideshow';
import Prompting from '@/components/new/Prompting';
import SetList from '@/components/new/SetList';

import HeroSection from './HeroSection';
import StakesSection from './StakesSection';
import ValuePropSection from './ValuePropSection';

import styles from './styles.module.scss';

function LandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const phrase = `"I want a set that sounds like <b>James Hype</b> mixed with <b>Kaytranada</b> with <b>high intensity</b>"`;

  useEffect(() => {
    dispatch(setDemoUsedFlag(false));
    dispatch(setVibeForFreeFlag(false));
  }, [dispatch]);

  return (
    <>
      <header className='py-8'>
        <Container>
          <div className='flex flex-wrap items-center justify-between gap-8'>
            <VibeSetLogo className={styles.header__logo} />

            <nav className='flex gap-4'>
              <MotionButton className='px-8 py-5' bgColors={['#061492', 'var(--color-chill)']} borderColors={['#FFF', 'var(--color-chill)']} borderRadii={['0.5em', '2em']} colors={['#FFF']} onClick={() => navigate(ABOUT)}>
                {[<React.Fragment key='initial'>About</React.Fragment>]}
              </MotionButton>
              <MotionButton className='px-8 py-5 font-bold uppercase' bgColors={['var(--color-valence)']} borderColors={['var(--color-valence)']} borderRadii={['2em']} colors={['#000']} onClick={() => window.open('https://o6aeuqjhybw.typeform.com/to/ly66v2Fp', '_blank', 'noopener,noreferrer')}>
                {[
                  <React.Fragment key='initial'>
                    Early access <ArrowRight weight='bold' className='text-xl' />
                  </React.Fragment>,
                  <React.Fragment key='hover'>
                    Let&lsquo;s vibe <ArrowRight weight='bold' className='text-xl' />
                  </React.Fragment>,
                ]}
              </MotionButton>
            </nav>
          </div>
        </Container>
      </header>

      <HeroSection />
      <StakesSection />
      <ValuePropSection />

      <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
        <Container>
          <div className='flex flex-wrap justify-center gap-8 md:gap-10 lg:gap-12 xl:gap-14'>
            <img className='h-[var(--logos-height)]' src={BeatportLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={SpotifyLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={SoundcloudLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={TidalLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={DjayLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={RekordboxLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={SeratoLogo} alt='' />
            <img className='h-[var(--logos-height)]' src={TraktorLogo} alt='' />
          </div>
        </Container>
      </section>

      <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
        <Container>
          <hgroup className='mb-12 text-center'>
            <Title size={5} className='mx-auto mb-6 max-w-screen-lg font-semibold'>
              <h2>
                Creating a setlist is an <Ribbon className='acid-jazz'>adventure</Ribbon>
              </h2>
            </Title>
            <Lead size={1}>
              <p>Vibeset.ai let’s you choose which one you’d like to embark on.</p>
            </Lead>
          </hgroup>

          <AdventureSlideshow />
        </Container>
      </section>
      <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
        <Container>
          <div className={styles.natural_prompting}>
            <Prompting phrase={phrase} video={Video} onComplete={() => {}} />
          </div>
        </Container>
      </section>
      <section className='py-12 md:py-16 lg:py-20 xl:py-24'>
        <Container>
          <hgroup className='mx-auto mb-6 max-w-screen-xl'>
            <Title size={5} className='mb-6 text-center font-semibold'>
              <h2>
                Visualize your unique <Ribbon className='dance'>masterpiece</Ribbon>
              </h2>
            </Title>
            <Lead size={1} className='text-center'>
              <p>Every submitted prompt generates a customized playlist and data visualization to better help you understand the craftsmanship of each setlist vibe.</p>
            </Lead>
          </hgroup>
        </Container>

        <SetList />

        <Container>
          <div className={`${styles.buttons} ${styles.adventure__buttons}`}>
            <MotionButton bgColors={['var(--color-valence)']} borderColors={['var(--color-valence)']} borderRadii={['0.5em', '3em']} colors={['#000']} className={styles.unlimited__button} onClick={() => window.open('https://o6aeuqjhybw.typeform.com/to/ly66v2Fp', '_blank', 'noopener,noreferrer')}>
              {[
                <React.Fragment key='initial'>Get Early Unlimited Access</React.Fragment>,
                <div key='hover' className='flex w-full items-center justify-between text-start'>
                  <span>
                    <small style={{ textTransform: 'uppercase' }}>Unlimited access to all exclusive features</small> At only $5/month
                  </span>{' '}
                  <ArrowRight weight='bold' className='text-[2em]' />
                </div>,
              ]}
            </MotionButton>

            <MotionButton bgColors={['var(--color-dance)']} borderColors={['var(--color-dance)']} borderRadii={['0.5em', '3em']} colors={['#FFF']} className={styles.trial__button} onClick={() => navigate(ALLINONE)}>
              {[
                <React.Fragment key='initial'>Exclusive Pro Trial</React.Fragment>,
                <div key='hover' className='flex w-full items-center justify-between text-start'>
                  <span>
                    <small style={{ textTransform: 'uppercase' }}>
                      Get access to all <br />
                      exclusive features <br />
                      and product releases
                    </small>
                  </span>{' '}
                  <ArrowRight weight='bold' className='text-[2em]' />
                </div>,
              ]}
            </MotionButton>

            <MotionButton bgColors={['rgba(255, 255, 255, 0)', '#FFF']} borderColors={['#FFF']} borderRadii={['0.5em']} colors={['#FFF', '#000']} className={styles.demo__button} onClick={() => navigate(ALLINONE)}>
              {[
                <React.Fragment key='initial'>Free demo</React.Fragment>,
                <div key='hover' className='flex w-full items-center justify-between text-start'>
                  <span>
                    <small style={{ textTransform: 'uppercase' }}>
                      Create and curate
                      <br />1 free set
                    </small>
                  </span>{' '}
                  <ArrowRight weight='bold' className='text-[2em]' />
                </div>,
              ]}
            </MotionButton>
          </div>
        </Container>
      </section>
    </>
  );
}

export default LandingPage;
