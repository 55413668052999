import React from 'react';

import BPMSlider from '@/components/new/BPMSlider';

import styles from './styles.module.scss';

const BPMs = () => {
  return (
    <div className={styles.sliderContainer}>
      <BPMSlider />
    </div>
  );
};

export default BPMs;
