import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Button
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { planInfoHistory } from '../redux/slice/vibeset';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import dayjs from 'dayjs';
import { PROFILE } from '../constants/PageName';
import { useNavigate } from 'react-router-dom';

function SongInfoHistory() {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // pagination
  const [songsList, setSongsList] = useState([]);
  const [songsCount, setSongsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  // Function to handle page change
  const handlePageChange = (event, newPage) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set new page
    setPage(newPage);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set rows per page based on event target value
    setRowsPerPage(parseInt(event.target.value, 10));
    // Set page to 0
    setPage(0);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    navigate(page);
  };

  useEffect(() => {
    const getData = async () => {
      let payload = {
        page: page + 1,
        records: rowsPerPage
      };
      dispatch(planInfoHistory(payload))
        .then((result) => {
          let resultSet = [];
          result?.payload?.results?.forEach((song, index) => {
            const created_ts = song?.created_ts
              ? dayjs(song?.created_ts, 'DD-MM-YYYY HH:mm:ss').format('MM-DD-YYYY')
              : '-';
            const subscription_end_date = song?.subscription_end_date
              ? dayjs(song?.subscription_end_date, 'DD-MM-YYYY HH:mm:ss').format('MM-DD-YYYY')
              : '-';
            // initialize resultSet[index] as an object before assigning values, assign values to properties of resultSet[index]
            resultSet[index] = {
              sequence: index + 1 + page * rowsPerPage,
              //TODO : "Change the static condition for plan name"
              plan: song.plan == 'price_1P3WSyHV6ShaIAJMlA4z9A9h' ? 'Pro' : 'Basic',
              created_ts,
              amount: song.amount,
              payment_status: song.payment_status_text,
              subscription_end_date
            };
          });
          setSongsList(resultSet);
          setSongsCount(result.payload.count);
        })
        .catch((error) => {});
    };
    getData();
  }, [page, rowsPerPage]);

  const loadingItems = Array.from({ length: 11 }, (_, index) => (
    <TableRow key={index}>
      {index === 0 ? (
        <TableCell className='text-teal-glow font-inter'>{t('table.hangtight')}</TableCell>
      ) : (
        <>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const renderTableRows = () => {
    if (data.vibeset.isLoading) {
      return loadingItems;
    } else if (songsList.length) {
      return songsList?.map((song, index) => (
        <TableRow key={index}>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.sequence}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.plan}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.created_ts}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {'$'}
            {song.amount}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.payment_status}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.subscription_end_date}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <>
          <TableRow>
            <TableCell
              className='mobile-hide font-inter'
              style={{ color: 'white', textAlign: 'center' }}
            >
              {t('table.noDataPresent')}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  return (
    <Box className='child-main setList history-table fade-in one'>
      {loader ? <Loader /> : ''}
      <Box className='result-table-heading plan-info-history text-white song-info-history'>
        <Box className='pinktape_cya'> </Box>
        <Typography variant='h2' className='text-upper is-h2'>
          {t('table.planHistory')}
        </Typography>
        <Button
          disableRipple
          variant='outlined'
          className='btn-med-white btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
          onClick={() => goToPageHandler(PROFILE)}
        >
          {' '}
          {t('common.goBack')}
        </Button>
      </Box>
      <Box className='table-card'>
        <Box className='result-table-content'>
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead className='font-inter'>
                <TableRow>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.sequence')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.plan')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.created_ts')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.amount')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.paymentStatus')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.subscriptionEndDate')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='font-inter'>{renderTableRows()}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                    onPageChange={handlePageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={songsCount}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='text-white'
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default SongInfoHistory;
