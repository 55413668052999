import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { resetFormData } from '../redux/slice/createaccount';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ACCOUNT } from '../constants/PageName';
import { setClickedRoute, resetClickedRoute, setRedirectedRoute } from '../redux/slice/clickedRoute';

export const ProtectedRoute = ({ isProtected, children, name }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const formData = useSelector((state) => state.createaccount.formData);
  let redirectedRoute = useSelector((state) => state.clickedRoute.redirectedRoute);
  const loginCred = useSelector((state) => state.login.loginCred);
  const vibeForFreeFlag = useSelector((state) => state.login.vibeForFreeFlag);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (location.pathname !== ACCOUNT) {
      dispatch(resetFormData(formData));
    }
  }, [location, dispatch, formData]);

  if (redirectedRoute === 'log-out') {
    dispatch(resetClickedRoute());
  }

  // Allow navigation to unprotected routes even when logged in
  if (!isProtected) {
    return children;
  }

  if (isProtected && !loginCred?.token) {
    if (redirectedRoute !== 'log-out') {
      dispatch(setClickedRoute(location.pathname));
    }
    return <Navigate to='/login' replace />;
  } else if (isProtected && loginCred?.token && loginCred?.subscription_details == null && name !== 'subscriptionPlans' && name !== 'changepassword' && name !== 'paymentSuccess' && name !== 'paymentFailed' && name !== 'myProfile' && !vibeForFreeFlag) {
    dispatch(setClickedRoute(location.pathname));
    return <Navigate to='/subscription-plans' replace />;
  }

  setTimeout(() => {
    if (redirectedRoute === 'log-out') {
      dispatch(setRedirectedRoute(null));
      dispatch(resetClickedRoute());
    }
  }, 1000);

  return children;
};
