import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { routes } from './route';
import { ProtectedRoute } from './ProtectedRoute';

import Header from '@/components/layout/Header';
import Footer from '@/components/new/Footer';
import { HOME_PAGE } from '@/constants/PageName';

const Router = () => {
  const data = useSelector((state) => state.login);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {routes.map((ele) => {
          return (
            <Route
              key={ele.id}
              path={ele.path}
              element={
                <ProtectedRoute isProtected={ele.isProtected} key={ele.id} name={ele.name}>
                  {ele.component}
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route path='/' element={<Navigate to={HOME_PAGE} />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;

//  --------------- 💀 DO NOT REMOVE THIS CODE DANGER 💀 --------------------------

// for landing page

{
  /* <main className='main'>
  <Container maxWidth='fluid'>
    <Routes>
      {routes.map((ele) => {
        return (
          <Route
            key={ele.id}
            path={ele.path}
            element={
              <ProtectedRoute isProtected={ele.isProtected} key={ele.id}>
                {ele.component}
              </ProtectedRoute>
            }
          />
        );
      })}
      <Route path='/' element={<Navigate to={HOME_PAGE} />} />
    </Routes>
  </Container>
</main>; */
}
