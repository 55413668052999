import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Reorder } from 'framer-motion';
import clsx from 'clsx';

import styles from './styles.module.scss';

import Tag from '@/components/new/Tag';

const tags_array = [
  {
    artist: 'john summit',
    song: 'where you are'
  },
  {
    artist: 'gessaffelstein',
    song: 'hard dreams'
  },
  {
    artist: 'mindchatter',
    song: 'cash'
  },
  {
    artist: 'fred again...',
    song: 'places to be'
  },
  {
    artist: 'post malone',
    song: 'circles'
  },
  {
    artist: 'disclosure',
    song: 'you & me'
  },
  {
    artist: 'jamie xx',
    song: 'life'
  },
  {
    artist: 'dom dolla',
    song: 'girl$'
  },
  {
    artist: 'purple disco machine',
    song: 'heartbreaker'
  },
  {
    artist: 'sofi tukker',
    song: 'purple hat'
  },
  {
    artist: 'noizu',
    song: 'push to start'
  }
];

const Tags = ({ className }) => {
  const [states, setStates] = useState(tags_array.map(() => 'inactive'));

  const updateStates = useCallback((index, newState) => {
    setStates((prevStates) =>
      prevStates.map((state, i) => {
        if (i === index) return newState;
        if (newState === 'player' && state === 'player') return 'active';
        return state;
      })
    );
  }, []);

  return (
    <div className={clsx(styles.tagsOuter, className)}>
      <Reorder.Group as='div' axis='y' values={tags_array} onReorder={() => {}} className={styles.tagsWrapper} transition={{ duration: 0.3 }}>
        {tags_array.map((item, index) => (
          <Reorder.Item key={item.id || index} value={item} as='div' dragListener={false} transition={{ duration: 0.3 }}>
            <Tag item={item} state={states[index]} setState={(newState) => updateStates(index, newState)} onPlayerStateChange={() => updateStates(index, 'player')} />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};

Tags.propTypes = {
  className: PropTypes.string
};

export default Tags;
