import React from 'react';
import { useStore } from '@tanstack/react-store';

import Container from '@/components/new/Container';
import WaveformLoader from '@/components/new/WaveformLoader';

import MoodPanel from '@/views/AllInOne/organisms/MoodPanel';
import DialsPanel from '@/views/AllInOne/organisms/DialsPanel';
import ArtistSongPanel from '@/views/AllInOne/organisms/ArtistSongPanel';
import BpmPanel from '@/views/AllInOne/organisms/BpmPanel';
import GenrePanel from '@/views/AllInOne/organisms/GenrePanel';
import FinalPanel from '@/views/AllInOne/organisms/FinalPanel';
import SetlistTable from '@/views/AllInOne/organisms/SetlistTable';

import { store, deleteTrack, replaceTrack, addTrack, reorderTracks } from '@/views/AllInOne/store';

import clsx from 'clsx';

import getTailwindValue from '@/utils/getTailwindValue';

import styles from '@/views/AllInOne/styles.module.scss';

const AllInOne = () => {
  const { isLoadingSetlist, error, sanitizedVibeset, mood, bpm, genre, isHarmonicActive, duration } = useStore(store);

  const handleReorderTracks = React.useCallback((draggedId, targetId, position) => {
    console.log('handleReorderTracks called:', { draggedId, targetId, position });
    reorderTracks(draggedId, targetId, position);
  }, []);

  return (
    <>
      <style>{`html { background-color: ${getTailwindValue('colors.hard.950')} !important; }`}</style>
      <div className='grid-stack min-h-screen content-center pt-28 text-off-white'>
        {isLoadingSetlist ?
          <section>
            <Container>
              <WaveformLoader height={120} isActive={true} className='text-off-white' />
              <span className='sr-only'>Generating setlist... This may take up to 10 seconds.</span>
            </Container>
          </section>
        : error ?
          <section className='text-center text-red-500'>
            <Container>
              <p>Error: {error}</p>
            </Container>
          </section>
        : sanitizedVibeset.length > 0 ?
          <section className='text-left'>
            <Container>
              <SetlistTable onDeleteTrack={deleteTrack} onReplaceTrack={replaceTrack} onAddTrack={addTrack} onReorderTracks={handleReorderTracks} />
            </Container>
          </section>
        : <section className='grid grid-cols-7 grid-rows-4 border-2 border-NRG bg-hard text-center'>
            <MoodPanel className='col-span-3 row-span-1 grid' />
            <DialsPanel className='col-span-1 row-span-2' />
            <ArtistSongPanel className='col-span-3 row-span-3' />
            <BpmPanel className={clsx('col-span-3 row-span-1', styles.bpm_panel)} />
            <GenrePanel className='col-span-4 row-span-2' />
            <FinalPanel className='col-span-3 row-span-1' />
          </section>
        }
      </div>
    </>
  );
};

export default React.memo(AllInOne);
