import React from 'react';

import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './MotionCollapse.module.scss';

const MotionCollapse = ({ isOpen, children }) => {
  const variants = {
    open: { height: 'auto' },
    closed: { height: 0 },
  };

  const transition = {
    duration: 0.6,
    ease: 'circInOut',
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
          transition={transition}
          className={styles.collapseWrapper}
          style={{ overflow: 'hidden' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

MotionCollapse.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default MotionCollapse;