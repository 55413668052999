import React from 'react';
import { useStore } from '@tanstack/react-store';
import CustomKnob from '@/components/new/CustomKnob';
import Title from '@/components/new/Title';
import { store, updateDuration } from '@/views/AllInOne/store';

const Duration = () => {
  const duration = useStore(store, (state) => state.duration);

  return (
    <div className='duration-control'>
      <Title className='mb-4 text-xl lg:text-2xl xl:text-3xl'>
        <h3>Duration</h3>
      </Title>
      <CustomKnob min={30} max={120} steps={[30, 40, 60, 90, 120]} size={120} value={duration} onChange={updateDuration} aria-label='Set duration' />
    </div>
  );
};

export default React.memo(Duration);
