import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Button
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import { songInfoHistory } from '../redux/slice/vibeset';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router-dom';
import { PROFILE, SONG_DETAILS_HISTORY } from '../constants/PageName';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

function SongInfoHistory() {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // pagination
  const [songsList, setSongsList] = useState([]);
  const [songsCount, setSongsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);

  // Function to handle page change
  const handlePageChange = (event, newPage) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set new page
    setPage(newPage);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set rows per page based on event target value
    setRowsPerPage(event.target.value);
    // Set page to 0
    setPage(0);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to redirect to song details history page
  const redirectToSongDetails = (id) => {
    const path = generatePath(SONG_DETAILS_HISTORY, { id });
    navigate(path);
  };

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    navigate(page);
  };

  useEffect(() => {
    const getData = async () => {
      let payload = {
        page: page + 1,
        records: rowsPerPage
      };
      dispatch(songInfoHistory(payload))
        .then((result) => {
          let resultSet = [];
          let url1 = import.meta.env.VITE_SPOTIFY_BASE_URL;
          let url2 = import.meta.env.VITE_TIDAL_BASE_URL;

          result.payload.results.forEach((song, index) => {
            // initialize resultSet[index] as an object before assigning values, assign values to properties of resultSet[index]
            resultSet[index] = {};
            resultSet[index].name =
              song.search !== null && song.search !== undefined && song.search !== ''
                ? song.search
                : '-';
            resultSet[index].mood =
              song.mood_text !== null && song.mood_text !== undefined && song.mood_text !== ''
                ? song.mood_text
                : '-';
            resultSet[index].styles = song.styles.length ? song.styles : '-';
            resultSet[index].export_link =
              song.type_export_text !== null &&
              song.type_export_text !== undefined &&
              song.type_export_text !== ''
                ? song.type_export_text == 'Spotify'
                  ? url1 + song.playlist_id
                  : url2 + song.playlist_id
                : '-';
            resultSet[index].export =
              song.type_export_text !== null &&
              song.type_export_text !== undefined &&
              song.type_export_text !== ''
                ? song.type_export_text
                : '-';
            resultSet[index].id = song.id;
          });
          setSongsList(resultSet);
          setSongsCount(result.payload.count);
        })
        .catch((error) => {});
    };
    getData();
  }, [page, rowsPerPage]);

  const loadingItems = Array.from({ length: 11 }, (_, index) => (
    <TableRow key={index}>
      {index == 0 ? (
        <TableCell className='text-teal-glow font-inter'>{t('table.hangtight')}</TableCell>
      ) : (
        <>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const renderTableRows = () => {
    if (data.vibeset.isLoading) {
      return loadingItems;
    } else if (songsList.length) {
      return songsList?.map((song, index) => (
        <TableRow key={index}>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.name}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.mood}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.styles}
          </TableCell>
          <TableCell className='mobile-hide font-inter'>
            <Link
              target='_blank'
              style={{ color: 'white', textDecoration: 'underline' }}
              to={song.export_link}
            >
              {song.export}
            </Link>
          </TableCell>
          <TableCell
            className='mobile-hide font-inter'
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={() => redirectToSongDetails(song.id)}
          >
            <RemoveRedEyeIcon />
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <>
          <TableRow>
            <TableCell
              className='mobile-hide font-inter '
              style={{ color: 'white', textAlign: 'center' }}
            >
              {t('table.noDataPresent')}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  return (
    <Box className='child-main history-table setList fade-in one'>
      {loader ? <Loader /> : ''}
      <Box className='result-table-heading song-history-info text-white song-info-history'>
        <Box className='pinktape_cya'> </Box>
        <Typography variant='h2' className='text-upper is-h2'>
          {t('table.history')}
        </Typography>
        <Button
          disableRipple
          variant='outlined'
          className='btn-med-white btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
          onClick={() => goToPageHandler(PROFILE)}
        >
          {' '}
          {t('common.goBack')}
        </Button>
      </Box>
      <Box className='table-card'>
        <Box className='result-table-content'>
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead className='font-inter'>
                <TableRow>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.name')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.mood')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.styles')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.export')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.action')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='font-inter'>{renderTableRows()}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                    onPageChange={handlePageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={songsCount}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='text-white'
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default SongInfoHistory;
