import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ABOUT, SUBSCRIPTION_PLANS } from '@/constants/PageName';

import Container from '@/components/new/Container';

import VibeSetLogo from '@/assets/icons/VibeSetLogo';

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className='py-24'>
      <Container>
        <div className='flex flex-wrap items-center gap-10'>
          <VibeSetLogo />

          <div className='mr-auto flex flex-wrap gap-4'>
            <button onClick={() => navigate(ABOUT)} className='rounded-3 border-1 border-off-white/30 px-6 py-4 hover:border-off-white hover:bg-white/10 focus:border-off-white focus:bg-white/10'>
              About
            </button>
            <button onClick={() => console.log('To legal page when it exists')} className='rounded-3 border-1 border-off-white/30 px-6 py-4 hover:border-off-white hover:bg-white/10 focus:border-off-white focus:bg-white/10'>
              Legal
            </button>
            <button onClick={() => navigate(SUBSCRIPTION_PLANS)} className='rounded-3 border-1 border-off-white/30 px-6 py-4 hover:border-off-white hover:bg-white/10 focus:border-off-white focus:bg-white/10'>
              Pricing
            </button>
            <button onClick={() => window.open('https://vibeset.notion.site/Vibeset-Beta-Documentation-b73d4cbe76624e42b2b3ffdf15f74f7c', '_blank', 'noopener,noreferrer')} className='rounded-3 border-1 border-off-white/30 px-6 py-4 hover:border-off-white hover:bg-white/10 focus:border-off-white focus:bg-white/10'>
              Documentation
            </button>
          </div>

          <div>&copy; 2024 Lambchop, LLC</div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
