import { createSlice } from '@reduxjs/toolkit';

const routesSlice = createSlice({
  name: 'routes',
  initialState: {
    clickedRoute: JSON.parse(localStorage.getItem('saveRoute')),
    redirectedRoute: null
  },
  reducers: {
    setClickedRoute: (state, action) => {
      // if (!state.clickedRoute) {
      state.clickedRoute = action.payload;
      localStorage.setItem('saveRoute', JSON.stringify(action.payload));
      // }
    },
    setRedirectedRoute: (state, action) => {
      state.redirectedRoute = action.payload;
    },
    resetClickedRoute: (state) => {
      state.clickedRoute = null;
      localStorage.setItem('saveRoute', JSON.stringify(null));
    }
  }
});

export const { setClickedRoute, setRedirectedRoute, resetClickedRoute } = routesSlice.actions;
export default routesSlice.reducer;
