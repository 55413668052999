import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3-force';
import twMerge from 'clsx';
import Bubble from './Bubble';

const INITIAL_BUBBLE_SIZE = 0;
const COLLISION_PADDING = 0.01;
const VELOCITY_DECAY = 0.125;
const ROTATION_RANGE = 7.5;
const GRAVITY = 0.25;
const BOUNCE = 0;
const MOVEMENT_THRESHOLD = 0.1;
const SIMULATION_CHECK_INTERVAL = 1000/30; // 30 FPS

const Bubbles = ({ isActive, bubblesArray, className, onSelectionChange = () => {} }) => {
  const containerRef = useRef(null);
  const labelRefs = useRef([]);
  const [bubbles, setBubbles] = useState([]);
  const simulationRef = useRef(null);
  const [isSimulationActive, setIsSimulationActive] = useState(true);
  const [selectedBubbles, setSelectedBubbles] = useState([]);

  const initializeBubbles = useCallback((labels, container) => {
    const { width, height } = container.getBoundingClientRect();
    return labels.map((label) => ({
      label,
      x: Math.random() * width,
      y: height,
      size: INITIAL_BUBBLE_SIZE,
      active: false,
      vx: (Math.random() - 0.5) * 2,
      vy: 0,
      rotation: (Math.random() < 0.5 ? -1 : 1) * ((ROTATION_RANGE / 3) * 2 + Math.random() * ROTATION_RANGE),
    }));
  }, []);

  const calculateBubbleSizes = useCallback((currentBubbles, container) => {
    return currentBubbles.map((bubble, index) => {
      const element = container.children[index];
      if (element) {
        const { width, height } = element.getBoundingClientRect();
        return { ...bubble, size: Math.max(width, height) };
      }
      return bubble;
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const newBubbles = initializeBubbles(bubblesArray, containerRef.current);
      setBubbles(newBubbles);
    }
  }, [bubblesArray, initializeBubbles]);

  useEffect(() => {
    if (bubbles.length > 0 && containerRef.current) {
      const newBubbles = calculateBubbleSizes(bubbles, containerRef.current);
      setBubbles(newBubbles);
    }
  }, [bubbles.length, calculateBubbleSizes]);

  const applyGravityAndBoundaries = useCallback((bubble, width, height) => {
    bubble.vy += GRAVITY;
    bubble.y += bubble.vy;
    bubble.x += bubble.vx;

    if (bubble.y + bubble.size / 2 > height) {
      bubble.y = height - bubble.size / 2;
      bubble.vy *= -BOUNCE;
    }

    if (bubble.x - bubble.size / 2 < 0 || bubble.x + bubble.size / 2 > width) {
      bubble.vx *= -BOUNCE;
      bubble.x = Math.max(bubble.size / 2, Math.min(width - bubble.size / 2, bubble.x));
    }

    bubble.vx *= 0.99;
    bubble.vy *= 0.99;
  }, []);

  const startSimulation = useCallback(() => {
    if (bubbles.length > 0 && containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();

      simulationRef.current = d3
        .forceSimulation(bubbles)
        .force(
          'collision',
          d3
            .forceCollide()
            .radius((d) => d.size / 2 + COLLISION_PADDING)
            .iterations(2)
        )
        .velocityDecay(VELOCITY_DECAY)
        .on('tick', () => {
          bubbles.forEach((bubble) => applyGravityAndBoundaries(bubble, width, height));
          setBubbles([...simulationRef.current.nodes()]);
        });

      setIsSimulationActive(true);
    }
  }, [bubbles, applyGravityAndBoundaries]);

  const stopSimulation = useCallback(() => {
    if (simulationRef.current) {
      simulationRef.current.stop();
      setIsSimulationActive(false);
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      startSimulation();
    } else {
      stopSimulation();
    }

    return stopSimulation;
  }, [isActive, startSimulation, stopSimulation]);

  useEffect(() => {
    let intervalId;

    if (isSimulationActive) {
      intervalId = setInterval(() => {
        const isMoving = bubbles.some((bubble) => Math.abs(bubble.vx) > MOVEMENT_THRESHOLD || Math.abs(bubble.vy) > MOVEMENT_THRESHOLD);

        if (!isMoving) {
          stopSimulation();
        }
      }, SIMULATION_CHECK_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [isSimulationActive, bubbles, stopSimulation]);

  const handleContainerHover = useCallback(() => {
    if (!isSimulationActive) {
      startSimulation();
    }
  }, [isSimulationActive, startSimulation]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && bubbles.length > 0) {
      const resizeObserver = new ResizeObserver(() => {
        setBubbles((prevBubbles) => calculateBubbleSizes(prevBubbles, container));
      });
      resizeObserver.observe(container);
      return () => resizeObserver.disconnect();
    }
  }, [bubbles, calculateBubbleSizes]);

  useEffect(() => {
    labelRefs.current.forEach((ref) => {
      if (ref) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect;
            ref.style.setProperty('--longest', `${Math.max(width, height)}px`);
          }
        });
        resizeObserver.observe(ref);
        return () => resizeObserver.disconnect();
      }
    });
  }, [bubbles]);

  const handleBubbleClick = useCallback((index) => {
    setBubbles((prevBubbles) => {
      const newBubbles = prevBubbles.map((bubble, i) => 
        i === index ? { ...bubble, active: !bubble.active } : bubble
      );
      
      const newSelectedBubbles = newBubbles
        .filter(bubble => bubble.active)
        .map(bubble => bubble.label);
      
      setSelectedBubbles(newSelectedBubbles);
      
      return newBubbles;
    });
  }, []);

  useEffect(() => {
    onSelectionChange(selectedBubbles);
  }, [selectedBubbles, onSelectionChange]);

  const memoizedBubbles = useMemo(() => bubbles.map((bubble, index) => <Bubble key={bubble.label} bubble={bubble} index={index} onClick={handleBubbleClick} ref={(el) => (labelRefs.current[index] = el)} />), [bubbles, handleBubbleClick]);

  return (
    <div ref={containerRef} className={twMerge('relative grid h-full w-full overflow-hidden p-6', className)} onMouseEnter={handleContainerHover} onTouchStart={handleContainerHover}>
      {memoizedBubbles}
    </div>
  );
};

Bubbles.propTypes = {
  bubblesArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onSelectionChange: PropTypes.func,
};

export default React.memo(Bubbles);