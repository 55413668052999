import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  BPM_DEFAULT_RANGE,
  GUIDED_MIX,
  HOME_PAGE,
  LETS_VIBE,
  QUICK_MIX,
  SIGN_UP_ACCOUNT,
  PROFILE
} from '../../constants/PageName';
import axios from 'axios';
import dataJSON from '../../assets/JSON/test_data.json';
import { updateLoginCredential } from '../../redux/slice/login';
import { resetClickedRoute, setRedirectedRoute } from '../../redux/slice/clickedRoute';
import { ACCOUNT_LOGIN } from '../../constants/PageName';
import { showToast } from '../../redux/slice/toastSlice';

const HTTP = axios.create({
  baseURL: import.meta.env.VITE_VIBESET_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const HTTPTIDAL = axios.create({
  baseURL: import.meta.env.VITE_VIBESET_TIDAL_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const HTTPPROJECTURL = axios.create({
  baseURL: import.meta.env.VITE_VIBESET_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Action
export const fetchPlayList = createAsyncThunk('fetchPlayList', async (payload) => {
  const response = await HTTP.post('', payload);
  return response.data;
});

// Action
export const exportToTidal = createAsyncThunk('exportToTidal', async (payload) => {
  const response = await HTTPTIDAL.post('', payload);
  return response.data;
});

// Action
export const exportToSpotify = createAsyncThunk('exportToSpotify', async (payload) => {
  const response = await HTTP.post('', payload);
  return response.data;
});

// Action
export const basicSearchStore = createAsyncThunk(
  'basicSearchStore',
  async (payload, { dispatch, getState, navigate }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.get('/search/basic_search_store/', {
        headers: {
          Authorization: 'Token ' + token
        }
      });
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'Someting went wrong',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

// Action
export const basicSearchCount = createAsyncThunk(
  'basicSearchCount',
  async (payload, { dispatch, getState, navigate }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.get('/search/basic_search_count/', {
        headers: {
          Authorization: 'Token ' + token
        }
      });
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'Someting went wrong',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

// Action
export const songInfoStoreCall = createAsyncThunk(
  'songInfoStoreCall',
  async (payload, { dispatch, navigate, getState }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.post('/song_info_store/', payload, {
        headers: {
          Authorization: 'Token ' + token
        }
      });
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

// Action
export const songInfoHistory = createAsyncThunk(
  'songInfoHistory',
  async (payload, { dispatch, navigate, getState }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.get(
        `/song_info_history/?page=${payload.page}&records=${payload.records}`,
        {
          headers: {
            Authorization: 'Token ' + token
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));

        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
      // throw error;
    }
  }
);

export const planInfoHistory = createAsyncThunk(
  'planInfoHistory',
  async (payload, { dispatch, navigate, getState }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.get(
        `/plan_history/?page=${payload.page}&records=${payload.records}`,
        {
          headers: {
            Authorization: 'Token ' + token
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
      // throw error;
    }
  }
);

export const songDetailHistory = createAsyncThunk(
  'songDetailHistory',
  async (payload, { dispatch, navigate, getState }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTPPROJECTURL.get(
        `/song_details_history/?page=${payload.page}&pk=${payload.id}&records=${payload.records}`,
        {
          headers: {
            Authorization: 'Token ' + token
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

const InitialStateValue = {
  isLoading: false,
  isError: false,
  mode: '',
  currentPage: LETS_VIBE,
  guidedMix: {
    genre: dataJSON.musicStyles,
    bpm: BPM_DEFAULT_RANGE,
    email: '',
    song_artist: '',
    valence: ''
  },
  quickMix: {
    email: '',
    prompt: ''
  },
  playlist: {},
  tidalData: {},
  spotifyData: {},
  countLimit: JSON.parse(localStorage.getItem('countBasic'))
};
const vibeset = createSlice({
  name: 'videset',
  initialState: { ...InitialStateValue },
  reducers: {
    setVibeMode: (state, action) => {
      state.mode = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    addQuickMix: (state, action) => {
      state.quickMix = action.payload;
    },
    addGuidedMix: (state, action) => {
      state.guidedMix = action.payload;
    },
    setCountLimit: (state, action) => {
      let count = action.payload || 0;
      localStorage.setItem('countBasic', JSON.stringify(count));
      state.countLimit = count;
    },
    resetCountLimit: (state) => {
      state.countLimit = '';
    },
    resetVibeData: (state, action) => {
      switch (action.payload) {
        case QUICK_MIX:
          state.guidedMix = InitialStateValue.guidedMix;
          state.tidalData = InitialStateValue.tidalData;
          state.spotifyData = InitialStateValue.spotifyData;
          break;

        case GUIDED_MIX:
          state.quickMix = InitialStateValue.quickMix;
          state.tidalData = InitialStateValue.tidalData;
          state.spotifyData = InitialStateValue.spotifyData;
          break;

        case SIGN_UP_ACCOUNT:
          state.quickMix = InitialStateValue.quickMix;
          state.guidedMix = InitialStateValue.guidedMix;
          state.tidalData = InitialStateValue.tidalData;
          state.spotifyData = InitialStateValue.spotifyData;
          break;

        case PROFILE:
          state.mode = InitialStateValue.mode;
          break;

        case HOME_PAGE:
          state.mode = InitialStateValue.mode;
          state.currentPage = InitialStateValue.currentPage;
          state.quickMix = InitialStateValue.quickMix;
          state.guidedMix = InitialStateValue.guidedMix;
          state.tidalData = InitialStateValue.tidalData;
          state.spotifyData = InitialStateValue.spotifyData;
          break;

        default:
          break;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlayList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.playlist = action.payload;
    });
    builder.addCase(fetchPlayList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPlayList.rejected, (state) => {
      state.isError = true;
    });
    builder.addCase(exportToTidal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tidalData = action.payload;
    });
    builder.addCase(exportToTidal.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(exportToTidal.rejected, (state) => {
      state.isError = true;
    });
    builder.addCase(exportToSpotify.fulfilled, (state, action) => {
      state.isLoading = false;
      state.spotifyData = action.payload;
    });
    builder.addCase(exportToSpotify.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(exportToSpotify.rejected, (state) => {
      state.isError = true;
    });
    builder.addCase(basicSearchStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.spotifyData = action.payload;
    });
    builder.addCase(basicSearchStore.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(basicSearchStore.rejected, (state) => {
      state.isError = true;
    });
  }
});

export const {
  resetCountLimit,
  setVibeMode,
  setCurrentPage,
  addQuickMix,
  addGuidedMix,
  resetVibeData,
  setCountLimit
} = vibeset.actions;

export default vibeset.reducer;
