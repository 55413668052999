import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableCell, TableContainer, TableHead, TableRow, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { QUICK_MIX, HOME_PAGE } from '@/constants/PageName';
import { songInfoStoreCall } from '@/redux/slice/vibeset';
import { showToast } from '@/redux/slice/toastSlice';

import avgBPM from '@/assets/styles/img/avg-BPM.svg';
import ambienceLevel from '@/assets/styles/img/ambience-level.svg';
import groove from '@/assets/styles/img/groove.svg';
import Letsvibebutton from '../../layout/Button/Letsvibe';

import Ribbon from '../Ribbon';
import Text from '../Text';

import usePlaylistData from './hooks/usePlaylistData';
import useChartValues from './hooks/useChartValues';
import ChartCard from './ChartCard';
import ReorderableTableBody from './ReorderableTableBody';

function SetListOutput() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { vibeset } = useSelector((state) => state);
  const { mode, tidalData, spotifyData } = vibeset;

  const { playlistData, setPlaylistData, isLoading } = usePlaylistData();
  const chartValues = useChartValues(playlistData);

  const goBackHandler = useCallback(() => navigate(HOME_PAGE), [navigate]);

  const songInfoStore = useCallback(
    (platform, url) => {
      if (!url?.startsWith('https')) {
        dispatch(showToast({ message: url, type: 'error' }));
        return;
      }

      window.open(url, '_blank');

      const payload = {
        search: mode === QUICK_MIX ? vibeset.quickMix.prompt : vibeset.guidedMix.song_artist,
        type_search: mode === QUICK_MIX ? 1 : 2,
        styles: mode === QUICK_MIX ? {} : vibeset.guidedMix.genre,
        mood: mode === QUICK_MIX ? '' : vibeset.guidedMix.valence,
        type_export: platform === 'tidal' ? 1 : 2,
        playlist_id: url.split('/').pop(),
        songs: playlistData,
        lower_energy_level:
          mode === QUICK_MIX ? null : parseInt(vibeset.guidedMix.bpm.split('-')[0]),
        upper_energy_level:
          mode === QUICK_MIX ? null : parseInt(vibeset.guidedMix.bpm.split('-')[1])
      };

      dispatch(songInfoStoreCall(payload))
        .then((response) =>
          dispatch(showToast({ message: response.payload.message, type: 'success' }))
        )
        .catch(() => dispatch(showToast({ message: 'Failed to store song info', type: 'error' })));
    },
    [dispatch, mode, vibeset, playlistData]
  );

  const handleReorder = useCallback((newOrder) => setPlaylistData(newOrder), [setPlaylistData]);

  const chartCards = useMemo(
    () => [
      {
        img: avgBPM,
        alt: 'landing',
        value: chartValues.bpmAvg,
        label: 'averageBpm',
        data: chartValues.bpm,
        colors: ['#D2E823', '#32D583']
      },
      {
        img: ambienceLevel,
        alt: 'landing',
        value: chartValues.valenceAvg,
        label: 'ambience',
        data: chartValues.valence,
        colors: ['#7F56D9', '#D717E7']
      },
      {
        img: groove,
        alt: 'landing',
        value: chartValues.danceAvg,
        label: 'groove',
        data: chartValues.dance,
        colors: ['#FFFFFF', '#1AE8CE']
      }
    ],
    [chartValues]
  );

  return (
    <Box className='child-main setList fade-in one text-white'>
      <Text.Title tag='h2' variant='md'>
        <Ribbon className='dance h1'>
          {mode === QUICK_MIX ? t('table.boom') : t('table.solid')}
        </Ribbon>
      </Text.Title>
      <Text.Title tag='h3' variant='md'>
        {mode === QUICK_MIX ? t('table.ready') : t('table.dropped')}
      </Text.Title>
      <Divider className='custom-divider' />
      <Text.Lead style={{ marginBlock: '1.5em' }}>
        {t('table.belowReady')}
        <br />
        {t('table.belowReadyBreak')}
      </Text.Lead>
      <Box className='table-card'>
        <Box className='result-table-content'>
          <TableContainer>
            <Table aria-label='playlist table'>
              <TableHead className='font-inter'>
                <TableRow>
                  {['name', 'artist', 'genre', 'tempo', 'mood', 'groove'].map((header) => (
                    <TableCell
                      key={header}
                      className={`font-inter ${header !== 'name' && header !== 'artist' ? 'mobile-hide' : ''}`}
                      style={{ color: 'white' }}
                    >
                      {t(`table.header.${header}`)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <ReorderableTableBody
                playlistData={playlistData}
                setPlaylistData={handleReorder}
                isLoading={isLoading}
                t={t}
              />
            </Table>
          </TableContainer>
        </Box>
        <Box className='chart-parent'>
          {chartCards.map((chartProps) => (
            <ChartCard key={chartProps.label} {...chartProps} />
          ))}
        </Box>
      </Box>
      <Box className='mt-btn-box d-flex'>
        <Letsvibebutton
          className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
          onClick={goBackHandler}
        >
          {t('common.generateNew')}
        </Letsvibebutton>
        {tidalData.result && playlistData.length > 0 && (
          <Letsvibebutton
            className='btn-text-blue mr-15 btn-bg-teal-blue primary button btn-med-white link-button-effect'
            onClick={() => songInfoStore('tidal', tidalData.result)}
          >
            {t('table.export')}
            <ArrowForwardIcon />
          </Letsvibebutton>
        )}
        {spotifyData?.data?.spotify_url && playlistData.length > 0 && (
          <Letsvibebutton
            className='btn-text-blue mr-15 btn-bg-teal-blue primary button btn-med-white link-button-effect'
            onClick={() => songInfoStore('spotify', spotifyData.data.spotify_url)}
          >
            {t('table.exportSpotify')}
            <ArrowForwardIcon />
          </Letsvibebutton>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(SetListOutput);
