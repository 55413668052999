import { useStore } from '@tanstack/react-store';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { autocompleteStore, setInput, setIsOpen, handleSelect, handleDelete, handleKeyDown } from './store';

const API_URL = import.meta.env.DEV ? '/api/autocomplete' : 'https://kcdadphd63l5rk5kf33iy3nrjy0zjgkg.lambda-url.us-east-2.on.aws/api/v2/autocomplete';

const fetchSuggestions = async (query) => {
  if (query.length > 0) {
    const response = await axios.post(API_URL, { query });
    return response.data;
  }
  return [];
};

export function useAutocomplete() {
  const input = useStore(autocompleteStore, (state) => state.input);
  const selectedTags = useStore(autocompleteStore, (state) => state.selectedTags);
  const isOpen = useStore(autocompleteStore, (state) => state.isOpen);

  const {
    data: suggestions = [],
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['suggestions', input],
    queryFn: () => fetchSuggestions(input),
    enabled: input.length > 0,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const filteredSuggestions = suggestions.filter((suggestion) => !selectedTags.some((tag) => tag.artist === suggestion.artist && (tag.song === suggestion.song || suggestion.song === 'N/A')));

  return {
    input,
    setInput,
    suggestions: filteredSuggestions,
    loading: isLoading,
    animating: isFetching,
    selectedTags,
    isOpen,
    setIsOpen,
    handleSelect,
    handleDelete,
    handleKeyDown,
  };
}
