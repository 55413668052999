import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '@/redux/slice/vibeset';
import { resetFieldErrors } from '@/redux/slice/createaccount';

import { Grid } from '@mui/material';

import gsap from 'gsap';

import CreateAccount from '@/components/account/CreateAccount';
import PaymentDetail from '@/components/account/PaymentDetail';
import BillingDetail from '@/components/account/BillingDetail';
import SignupSucess from '@/components/account/SignupSucess';
import Dj from '@/components/account/Dj';
import Dob from '@/components/account/Dob';

import accountSvg from '@/assets/animation/svgs/accountSvg';

import { ADD_BILLING, ENTER_PAYMENT, SIGN_UP_ACCOUNT, SIGN_UP_SUCCESS, ADD_DJ, ADD_DOB } from '@/constants/PageName';

import styles from './styles.module.scss';

gsap.config({ nullTargetWarn: false });
gsap.registerEffect({
  name: 'fadeOut',
  effect: (targets) => {
    return gsap.to(targets, { duration: 2, opacity: 0, ease: 'elastic' });
  }
});
gsap.registerEffect({
  name: 'fadeIn',
  effect: (targets) => {
    return gsap.to(targets, { duration: 2, opacity: 0.9, ease: 'elastic' });
  }
});
gsap.registerEffect({
  name: 'fillFadeIn',
  effect: (targets, config) => {
    return gsap.to(targets, {
      duration: 2,
      fill: config.fill,
      opacity: 0.8,
      ease: 'elastic'
    });
  }
});
gsap.registerEffect({
  name: 'fillFadeOut',
  effect: (targets, config) => {
    return gsap.to(targets, {
      duration: 2,
      fill: config.fill,
      ease: 'elastic'
    });
  }
});

const Svg = ({ page, prevPage }) => {
  const linePositions = {
    [SIGN_UP_ACCOUNT]: 0,
    [ADD_DJ]: 235,
    [ADD_DOB]: 497,
    [SIGN_UP_SUCCESS]: 207
  };

  const optionData = {
    [SIGN_UP_ACCOUNT]: {
      index: 1,
      previousPage: ADD_DJ
    },
    [ADD_DJ]: {
      index: 2,
      nonActiveFill: '#061492',
      activeFill: '#D717E7',
      previousPage: ADD_DOB
    },
    [ADD_DOB]: {
      nonActiveFill: '#061492',
      activeFill: '#1AE8CE',
      index: 3,
      previousPage: SIGN_UP_SUCCESS
    },
    [SIGN_UP_SUCCESS]: {
      index: 4,
      previousPage: null
    },
    [ADD_BILLING]: {
      index: 4
    },
    [ENTER_PAYMENT]: { index: 4 }
  };

  React.useLayoutEffect(() => {
    const pages = Object.keys(optionData);
    for (let i = 0; i < pages.length; i++) {
      if (optionData[pages[i]].index === optionData[page]?.index) {
        gsap.effects.fadeIn(`.text-option-${optionData[pages[i]].index}`);
      } else {
        gsap.effects.fadeOut(`.text-option-${optionData[pages[i]].index}`);
      }

      if (optionData[pages[i]].index <= optionData[page]?.index) {
        if (optionData[pages[i]].activeFill) {
          gsap.effects.fillFadeIn(`.fill-option-${optionData[pages[i]].index}`, {
            fill: optionData[pages[i]].activeFill
          });
        }

        gsap.effects.fadeIn(`.active-option-${optionData[pages[i]].index}`);
        gsap.effects.fadeOut(`.non-active-option-${optionData[pages[i]].index}`);
      } else {
        if (optionData[pages[i]].nonActiveFill) {
          gsap.effects.fillFadeOut(`.fill-option-${optionData[pages[i]].index}`, {
            fill: optionData[pages[i]].nonActiveFill
          });
        }

        gsap.effects.fadeOut(`.active-option-${optionData[pages[i]].index}`);
        gsap.effects.fadeIn(`.non-active-option-${optionData[pages[i]].index}`);
      }
    }

    if (page === SIGN_UP_SUCCESS) {
      gsap.to('.scroll-svg', { duration: 2, y: -210, ease: 'elastic' });
      gsap.fromTo('.scroll-svg-option-4', { y: 210 }, { duration: 2, y: 2, opacity: 0.9, ease: 'elastic' });
    } else {
      optionData[page]?.index !== 4 && gsap.to('.scroll-svg', { duration: 2, y: 0, ease: 'elastic' });
      !prevPage && page === SIGN_UP_ACCOUNT
        ? gsap.fromTo(
            '.animation-line',
            { x: -130 },
            {
              duration: 2,
              x: linePositions[page],
              ease: 'elastic'
            }
          )
        : gsap.to('.animation-line', {
            duration: 2,
            x: linePositions[page],
            ease: 'elastic'
          });
    }
  }, [page]);

  return accountSvg();
};

function Account() {
  const [prevContentPos, setPrevContentPos] = React.useState(0);
  const [prevBtnPos, setPrevBtnPos] = React.useState(0);
  const [prevPage, setPrevPage] = React.useState(null);
  const [prePageAni, setPrePageAni] = React.useState(false);

  const buttonRef = React.useRef(null);
  const contentRef = React.useRef(null);

  const vibeSetCurrentPage = useSelector((state) => state.vibeset.currentPage);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (vibeSetCurrentPage !== SIGN_UP_ACCOUNT) {
      dispatch(setCurrentPage(SIGN_UP_ACCOUNT));
      setPrevPage(null);
    }
    if (location.state?.data?.isNavigatedFromLogin) {
      dispatch(resetFieldErrors());
    }
  }, []);

  const setPrevoiusPage = (page) => {
    setPrevPage(page);
  };

  const ennumOfComponent = {
    [SIGN_UP_ACCOUNT]: <CreateAccount setPrevoiusPage={setPrevoiusPage} contentRef={prevPage !== SIGN_UP_ACCOUNT ? contentRef : null} buttonRef={prevPage !== SIGN_UP_ACCOUNT ? buttonRef : null} isPrevious={prevPage === SIGN_UP_ACCOUNT} />,

    [ENTER_PAYMENT]: <PaymentDetail isPrevious={prevPage === ENTER_PAYMENT} setPrevoiusPage={setPrevoiusPage} contentRef={prevPage !== ENTER_PAYMENT ? contentRef : null} buttonRef={prevPage !== ENTER_PAYMENT ? buttonRef : null} />,
    [ADD_BILLING]: <BillingDetail isPrevious={prevPage === ADD_BILLING} setPrevoiusPage={setPrevoiusPage} contentRef={prevPage !== ADD_BILLING ? contentRef : null} buttonRef={prevPage !== ADD_BILLING ? buttonRef : null} />,
    [ADD_DJ]: <Dj isPrevious={prevPage === ADD_DJ} setPrevoiusPage={setPrevoiusPage} previousPage={prevPage} contentRef={prevPage !== ADD_DJ ? contentRef : null} buttonRef={prevPage !== ADD_DJ ? buttonRef : null} />,
    [ADD_DOB]: <Dob isPrevious={prevPage === ADD_DOB} setPrevoiusPage={setPrevoiusPage} contentRef={prevPage !== ADD_DOB ? contentRef : null} buttonRef={prevPage !== ADD_DOB ? buttonRef : null} />,
    [SIGN_UP_SUCCESS]: <SignupSucess isPrevious={prevPage === SIGN_UP_SUCCESS} setPrevoiusPage={setPrevoiusPage} contentRef={prevPage !== SIGN_UP_SUCCESS ? contentRef : null} buttonRef={prevPage !== SIGN_UP_SUCCESS ? buttonRef : null} />
  };

  React.useEffect(() => {
    gsap.fromTo(
      '.content-animation',
      {
        opacity: 0,
        y: prevPage ? prevContentPos - contentRef.current?.offsetTop || 0 : 100
      },
      { duration: 1.5, opacity: 1, y: 0, ease: 'elastic' }
    );

    gsap.fromTo(
      '.prev-content-animation',
      {
        opacity: 1,
        y: prevPage ? prevContentPos - contentRef.current?.offsetTop || 0 : 100
      },
      {
        duration: 1.5,
        opacity: 0,
        y: 0,
        ease: 'elastic',
        onStart: () => setPrePageAni(true),
        onComplete: () => setPrePageAni(false)
      }
    );

    gsap.fromTo(
      '.animation',
      {
        y: prevPage ? prevBtnPos - buttonRef.current?.offsetTop || 0 : 100
      },
      { y: 0, duration: 1.5, ease: 'elastic' }
    );

    setPrevContentPos(contentRef.current?.offsetTop || 0);
    setPrevBtnPos(buttonRef.current?.offsetTop || 0);
  }, [vibeSetCurrentPage]);

  return (
    <div className='fade-in one child-main create-account-main text-white'>
      <section className={styles.section}>
        <div className='container mx-auto px-4'>
          <Grid container spacing={10} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {ennumOfComponent[vibeSetCurrentPage]}
              <div
                className='prev-content-animation'
                style={{
                  position: 'absolute',
                  top: prevContentPos,
                  width: contentRef.current?.offsetWidth,
                  zIndex: -1
                }}
              >
                {prePageAni && ennumOfComponent[prevPage]}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={6}>
              <div className='create-account-img'>
                <Svg page={vibeSetCurrentPage} prevPage={prevPage} />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
}

export default Account;
