import React, { useRef, useEffect } from 'react';
import { ArrowElbowDownRight, X } from '@phosphor-icons/react';
import { motion, AnimatePresence } from 'framer-motion';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Tag from './Tag';
import Suggestions from './Suggestions';
import WaveformLoader from '@/components/new/WaveformLoader';
import { useAutocomplete } from './useAutocomplete';
import { setIsOpen } from './store';

const queryClient = new QueryClient();

function AutocompleteInner() {
  const { input, setInput, suggestions, loading, animating, selectedTags, isOpen, handleSelect, handleDelete, handleKeyDown } = useAutocomplete();

  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target) && suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setIsOpen(true);
  };

  const clearInput = () => {
    setInput('');
    setIsOpen(false);
  };

  return (
    <div className='mt-6'>
      <div className='relative'>
        <div className='mb-4'>
          <div className='relative h-[60px]'>
            <WaveformLoader height={60} isActive={animating} className='text-off-white' />
          </div>
        </div>

        <div className='flex items-center rounded-2 border'>
          <ArrowElbowDownRight className='ml-[0.25em] text-[1.75em]' />
          <input ref={inputRef} type='text' className='w-full grow bg-transparent px-[0.75em] py-[0.5em] font-display text-[1.25em] uppercase tracking-widest outline-0 placeholder:text-[1em] placeholder:opacity-50' value={input} onChange={handleInputChange} onFocus={() => setIsOpen(input.length > 0)} onKeyDown={handleKeyDown} onClick={() => setIsOpen(input.length > 0)} placeholder='Type to start searching' />
          {input && <X weight='bold' className='mr-3 cursor-pointer text-[1.25em]' onClick={clearInput} />}
        </div>

        <AnimatePresence>
          {isOpen && suggestions.length > 0 && !loading && (
            <motion.div ref={suggestionsRef} className='absolute top-[6.675em] w-full overflow-clip rounded-2 bg-off-white/90 py-[0.5em] text-start text-hard shadow-lg' initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} transition={{ duration: 0.2 }}>
              <Suggestions suggestions={suggestions} onSelect={handleSelect} loading={loading} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {selectedTags.length > 0 && (
          <motion.div className='mt-4 flex flex-wrap gap-[1em] rounded-[1em] bg-gray-900 p-[1em] text-[1.125em]' initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} transition={{ duration: 0.2 }}>
            {selectedTags.map((tag) => (
              <Tag key={tag.id} tag={tag} onDelete={handleDelete} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function Autocomplete() {
  return (
    <QueryClientProvider client={queryClient}>
      <AutocompleteInner />
    </QueryClientProvider>
  );
}

export default Autocomplete;
