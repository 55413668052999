import React from 'react';
import PropTypes from 'prop-types';

import Bubbles from '@/components/new/Bubbles';

const Genres = ({ isActive, genres }) => {
  return (
    <div className="bg-gradient-to-br from-hard-900 to-hard-600">
      <Bubbles isActive={isActive} bubblesArray={genres} className="text-xl md:text-2xl lg:text-3xl xl:text-4xl" />
    </div>
  );
};

Genres.displayName = 'Genres';

Genres.propTypes = {
  isActive: PropTypes.bool,
  genres: PropTypes.array
};



export default Genres;
