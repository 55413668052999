import React from 'react';
import PropTypes from 'prop-types';

import Lead from './Lead';
import Title from './Title';

const Text = ({ children }) => {
  return <>{children}</>;
};

Text.propTypes = {
  children: PropTypes.node.isRequired
};

Text.Lead = Lead;
Text.Title = Title;

export default Text;
