import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledCheckBox = styled('span')(({ theme }) => ({
  borderRadius: 6,
  width: 20,
  height: 20,
  backgroundColor: 'transparent',
  border: '2px solid #1570ef',
  'input:checked ~ &': {
    borderColor: 'black'
  }
}));

export const StyledCheckedIcon = styled(StyledCheckBox)({
  backgroundColor: 'black',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""'
  }
});

export const InputBox = styled(TextField)({
  'input::placeholder': {
    fontSize: '20px',
    fontFamily: 'Dmmono'
  },
  input: {
    fontweight: '500',
    fontSize: '20px',
    fontFamily: 'Dmmono'
  }
});
