import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoader, retrieveProfile } from '../redux/slice/profile';
import { updateLoginCredential } from '../redux/slice/login';
import PaymentFailedImg from '../assets/styles/img/payment-failed.svg';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION_PLANS } from '../constants/PageName';
import { useTranslation } from 'react-i18next';
function PaymentFailed() {
  const { t } = useTranslation();
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to handle redirection to subscription plan page
  const redirectHandler = () => {
    navigate(SUBSCRIPTION_PLANS);
  };

  useEffect(() => {
    dispatch(retrieveProfile())
      .then((result) => {
        if (result.payload.subscription_details != null) {
          const loginData = JSON.parse(JSON.stringify(data.login));
          loginData.loginCred.subscription_details = result.payload.subscription_details;
          dispatch(updateLoginCredential(loginData.loginCred));
        }
      })
      .catch((error) => {
        dispatch(updateLoader(false));
      });
  }, []);

  return (
    <div className='child-main payment-failed'>
      <div className='fade-in one'>
        <div className='page-not-found'>
          <div>
            <Box className='page-not-found-text'>
              <Typography variant='h1' component='h1' className='heading-text text-upper'>
                <Box className='bluetape_cya'></Box>
                {t('paymentFailed.failed')}
              </Typography>
              <div className='failed-img'>
                <img src={PaymentFailedImg} color='red' alt='Payment Failed' />
              </div>
              <Typography variant='h2' component='h2' className='heading-text-below'>
                {t('paymentFailed.anErrorOccurred')}
              </Typography>
            </Box>
            {/* <h3></h3> */}
            <div className='back-to-home-page-button'>
              <Button
                disableRipple
                variant='outlined'
                className='outline-back-button'
                onClick={() => redirectHandler()}
              >
                {t('paymentFailed.backToPaymentPage')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
