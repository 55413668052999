import { Store } from '@tanstack/store';

export const autocompleteStore = new Store({
  input: '',
  selectedTags: [],
  isOpen: false,
});

export const setInput = (input) => {
  autocompleteStore.setState((state) => ({ ...state, input }));
};

export const setIsOpen = (isOpen) => {
  autocompleteStore.setState((state) => ({ ...state, isOpen }));
};

export const handleSelect = (suggestion) => {
  autocompleteStore.setState((state) => {
    const newTag = {
      id: `${suggestion.songID}-${Date.now()}`,
      artist: suggestion.artist,
      song: suggestion.song,
    };

    const tagExists = state.selectedTags.some((tag) => tag.artist === newTag.artist && tag.song === newTag.song);

    if (!tagExists) {
      return {
        ...state,
        selectedTags: [...state.selectedTags, newTag],
        isOpen: false,
      };
    }
    return state;
  });
};

export const handleDelete = (tagToDelete) => {
  autocompleteStore.setState((state) => ({
    ...state,
    selectedTags: state.selectedTags.filter((tag) => tag.id !== tagToDelete.id),
  }));
};

export const handleKeyDown = (e) => {
  const state = autocompleteStore.state;
  if (e.key === 'Enter' && state.input.trim() !== '') {
    e.preventDefault();
    const newTag = {
      id: `custom-${Date.now()}`,
      artist: state.input,
      song: 'N/A',
    };

    autocompleteStore.setState((state) => {
      const tagExists = state.selectedTags.some((tag) => tag.artist.toLowerCase() === newTag.artist.toLowerCase() && tag.song === newTag.song);

      if (!tagExists) {
        return {
          ...state,
          selectedTags: [...state.selectedTags, newTag],
          isOpen: false,
        };
      }
      return state;
    });
  } else if (e.key === 'Escape') {
    setIsOpen(false);
  }
};

export const getSelectedTags = () => autocompleteStore.state.selectedTags;
