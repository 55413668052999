import React from 'react';
import PropTypes from 'prop-types';

const VibeSetLogo = ({ className }) => (
  <svg
    width='219'
    height='38'
    viewBox='0 0 219 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_74_7101)'>
      <path
        d='M11.7713 37.2094L0.5 8.67773H7.58935L11.4658 19.7294C12.8036 23.5505 14.2573 27.625 15.4687 31.2666H15.5846C16.8592 27.625 18.2497 23.5611 19.5875 19.7294L23.5272 8.67773H30.3743L19.2294 37.2094H11.7819H11.7713Z'
        fill='#FDF0D5'
      />
      <path
        d='M30.7229 4.85556V0H37.8122V4.85556H30.7229ZM31.0284 37.2083V8.67667H37.57V37.2083H31.0284Z'
        fill='#FDF0D5'
      />
      <path
        d='M48.1668 16.7517H48.2827C49.6205 10.9883 52.886 7.94833 58.4585 7.94833C65.7901 7.94833 69.6034 13.5956 69.6034 22.8211C69.6034 32.0467 65.6005 38 58.3953 38C52.6964 38 49.5468 34.9072 48.2827 29.0806H48.1668V37.2189H41.6252V0H48.1668V16.7517ZM63.125 22.8211C63.125 17.1106 60.8181 13.8383 55.7934 13.8383C51.0637 13.8383 48.1563 16.9944 48.1563 22.6417V23.1272C48.1563 28.595 51.0637 32.11 55.7934 32.11C60.5231 32.11 63.125 28.6478 63.125 22.8211Z'
        fill='#FDF0D5'
      />
      <path
        d='M70.5156 23.3056C70.5156 13.6578 76.2145 7.94727 85.0525 7.94727C93.8904 7.94727 98.0724 13.415 98.0724 21.2367C98.0724 22.3873 98.0724 23.5484 97.9565 25.0578H77.0572C77.6576 29.85 80.4491 32.225 85.1157 32.225C89.7822 32.225 91.3518 29.9767 92.205 27.0634L97.9039 28.6995C96.4502 34.1673 92.5737 37.925 85.063 37.925C76.3409 37.925 70.5262 32.7 70.5262 23.295L70.5156 23.3056ZM77.1204 20.2128H92.0259C91.6678 15.7795 89.3608 13.4784 84.8734 13.4784C80.3859 13.4784 77.8472 15.6 77.1204 20.2128Z'
        fill='#FDF0D5'
      />
      <path
        d='M98.625 28.1612L104.745 26.9473C105.651 30.5256 107.474 32.9534 112.014 32.9534C115.469 32.9534 117.165 31.6128 117.228 29.375C117.228 27.3062 115.774 26.2189 110.075 25.1845C102.386 23.6645 99.6573 20.9939 99.6573 16.4445C99.6573 11.2828 103.418 7.94727 110.865 7.94727C118.313 7.94727 121.831 11.2828 123.043 16.7506L116.807 18.1439C115.953 14.3756 114.257 12.8028 110.865 12.8028C107.779 12.8028 106.136 14.08 106.136 16.1384C106.136 18.1967 107.284 19.4739 113.046 20.445C120.978 21.9017 123.643 24.815 123.643 29.185C123.643 34.5262 119.946 37.9884 111.887 37.9884C102.744 37.9884 99.6468 33.7345 98.625 28.1506V28.1612Z'
        fill='#FDF0D5'
      />
      <path
        d='M124.367 23.3056C124.367 13.6578 130.066 7.94727 138.904 7.94727C147.742 7.94727 151.924 13.415 151.924 21.2367C151.924 22.3873 151.924 23.5484 151.808 25.0578H130.909C131.52 29.85 134.301 32.225 138.967 32.225C143.634 32.225 145.203 29.9767 146.057 27.0634L151.755 28.6995C150.302 34.1673 146.425 37.925 138.915 37.925C130.192 37.925 124.378 32.7 124.378 23.295L124.367 23.3056ZM130.972 20.2128H145.878C145.519 15.7795 143.212 13.4784 138.725 13.4784C134.237 13.4784 131.699 15.6 130.972 20.2128Z'
        fill='#FDF0D5'
      />
      <path
        d='M156.169 14.2077H152.293V8.6871H156.169V1.09766H162.648V8.6871H171.064V14.2077H162.648V27.4443C162.648 30.2415 163.743 31.6349 166.588 31.6349C168.041 31.6349 169.379 31.276 170.643 30.9699L171.433 36.976C169.368 37.641 167.862 38.0104 165.071 38.0104C158.835 38.0104 156.169 33.8832 156.169 28.5421V14.2077Z'
        fill='#FDF0D5'
      />
      <path d='M180.335 29.7461V37.2089H172.761V29.7461H180.335Z' fill='#FDF0D5' />
      <path
        d='M201.298 30.0509H201.171C199.833 35.1492 196.389 37.8198 191.48 37.8198C186.213 37.8198 182.516 35.1492 182.516 29.6814C182.516 24.2137 185.665 20.6987 192.818 20.6987H201.298V19.5481C201.298 15.6637 199.718 13.542 195.788 13.542C192.27 13.542 190.279 15.1253 189.605 19.2526L183.369 18.3976C184.222 12.022 187.972 8.07422 196.031 8.07422C204.089 8.07422 207.787 12.2014 207.787 19.4848V37.2076H201.308V30.0403L201.298 30.0509ZM201.298 26.4726V25.5648H193.787C190.637 25.5648 188.941 26.7153 188.941 28.6576C188.941 30.5998 190.216 32.4787 193.913 32.4787C198.401 32.4787 201.308 29.9242 201.308 26.4726H201.298Z'
        fill='#FDF0D5'
      />
      <path
        d='M211.414 4.85556V0H218.503V4.85556H211.414ZM211.72 37.2083V8.67667H218.261V37.2083H211.72Z'
        fill='#FDF0D5'
      />
    </g>
    <defs>
      <clipPath id='clip0_74_7101'>
        <rect width='218' height='38' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

VibeSetLogo.propTypes = {
  className: PropTypes.string
};

export default VibeSetLogo;
