import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Prompting.module.scss';
import GSAPParallaxSection from '@/components/new/GSAPParallaxSection';

const useVideoControl = (videoRef) => {
  const [videoDuration, setVideoDuration] = useState(null);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setVideoDuration(videoRef.current.duration);
      }
    };

    const video = videoRef.current;
    if (!video) return;

    video.addEventListener('loadedmetadata', handleLoadedMetadata);

    if (video.readyState >= 2) {
      handleLoadedMetadata();
    }

    return () => video.removeEventListener('loadedmetadata', handleLoadedMetadata);
  }, [videoRef]);

  const updateVideoTime = useCallback(
    (progress) => {
      if (videoRef.current && videoDuration) {
        videoRef.current.currentTime = progress * videoDuration;
      }
    },
    [videoDuration]
  );

  return { updateVideoTime, videoDuration };
};

const useTypingEffect = (phrase, onComplete) => {
  const [displayedText, setDisplayedText] = useState('');
  const currentIndex = useRef(0);
  const lastProgressRef = useRef(0);

  const phraseLength = useMemo(() => phrase.length, [phrase]);

  const updateDisplayedContent = useCallback(
    (progress) => {
      const newIndex = Math.max(0, Math.min(phraseLength, Math.round(progress * phraseLength)));
      if (newIndex !== currentIndex.current) {
        currentIndex.current = newIndex;
        setDisplayedText(phrase.slice(0, currentIndex.current));

        if (currentIndex.current >= phraseLength || currentIndex.current <= 0) {
          onComplete();
        }
      }
    },
    [phrase, phraseLength, onComplete]
  );

  const handleScroll = useCallback(
    (progress) => {
      if (progress !== lastProgressRef.current) {
        lastProgressRef.current = progress;
        requestAnimationFrame(() => updateDisplayedContent(progress));
      }
    },
    [updateDisplayedContent]
  );

  return {
    displayedText,
    handleScroll,
    cursorVisible: currentIndex.current !== phraseLength && currentIndex.current !== 0
  };
};

const Prompting = ({ phrase, video, onComplete }) => {
  const videoRef = useRef(null);
  const { updateVideoTime } = useVideoControl(videoRef);
  const { displayedText, handleScroll, cursorVisible } = useTypingEffect(phrase, onComplete);

  const handleProgressChange = useCallback(
    (progress) => {
      handleScroll(progress);
      updateVideoTime(progress);
    },
    [handleScroll, updateVideoTime]
  );

  return (
    <GSAPParallaxSection onProgressChange={handleProgressChange} className={styles.wrapper}>
      <h2 className={styles.step}>02. Natural Prompting</h2>
      <div className={styles.scrollHolder}>
        <span className={styles.spaceHolder} dangerouslySetInnerHTML={{ __html: phrase }} />
        <div className={styles.scrollType}>
          <span dangerouslySetInnerHTML={{ __html: displayedText }} />
          {cursorVisible && (
            <span className={styles.cursor} aria-hidden='true'>
              |
            </span>
          )}
        </div>
      </div>
      <video
        ref={videoRef}
        className={styles.video}
        muted
        preload='auto'
      >
        <source src={video} type='video/mp4' />
        Your browser does not support the video tag. Please update your browser.
      </video>
    </GSAPParallaxSection>
  );
};

Prompting.propTypes = {
  phrase: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default React.memo(Prompting);
