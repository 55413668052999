import { useCallback, useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);
ScrollTrigger.config({ ignoreMobileResize: true });

const useParallaxEffect = (config, refs, callbacks) => {
  const { outerRef, parallaxItemRef } = refs;
  const { handleProgressChange } = callbacks;
  const scrollTriggerRef = useRef(null);
  const resizeObserverRef = useRef(null);

  const cleanup = useCallback(() => {
    if (scrollTriggerRef.current) {
      scrollTriggerRef.current.kill();
      scrollTriggerRef.current = null;
    }
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
      resizeObserverRef.current = null;
    }
  }, []);

  useLayoutEffect(() => {
    if (!config.yRange[0] && !config.yRange[1]) return cleanup;

    if (!parallaxItemRef.current) {
      console.warn('Parallax item ref is not available');
      return cleanup;
    }

    gsap.set(parallaxItemRef.current, { y: config.yRange[0] });

    const updateParallax = (progress) => {
      if (!parallaxItemRef.current) return;
      const y = gsap.utils.interpolate(config.yRange[0], config.yRange[1], progress);
      gsap.set(parallaxItemRef.current, { y });
      handleProgressChange(progress);
    };

    scrollTriggerRef.current = ScrollTrigger.create({
      trigger: outerRef.current,
      start: config.start,
      end: config.end,
      scrub: true,
      onUpdate: (self) => updateParallax(self.progress)
    });

    resizeObserverRef.current = new ResizeObserver(() => {
      ScrollTrigger.refresh();
    });

    resizeObserverRef.current.observe(document.body);

    return () => {
      cleanup();
    };
  }, [config, outerRef, parallaxItemRef, handleProgressChange, cleanup]);
};

export default useParallaxEffect;
