import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { gsap } from 'gsap';

const useKnob = (min, max, steps, onChange) => {
  const [value, setValue] = useState(min);
  const knobRef = useRef(null);
  const dialRef = useRef(null);
  const isDraggingRef = useRef(false);

  const range = useMemo(() => max - min, [max, min]);

  const updateKnob = useCallback((newValue, duration = 0.3) => {
    setValue(newValue);
    onChange(newValue);

    const percentage = ((newValue - min - 45) / range) * 100;
    gsap.to(dialRef.current, {
      rotation: percentage * 1.8,
      duration,
      ease: 'power2.out',
    });
  }, [min, range, onChange]);

  const getStepFromEvent = useCallback((clientX, clientY) => {
    const knob = knobRef.current;
    if (!knob) return min;

    const knobRect = knob.getBoundingClientRect();
    const centerX = knobRect.left + knobRect.width / 2;
    const centerY = knobRect.top + knobRect.height / 2;

    let angle = Math.atan2(centerY - clientY, clientX - centerX);
    let degrees = (angle * (180 / Math.PI) + 360) % 360;

    if (degrees > 180) {
      return degrees <= 270 ? min : max;
    }

    let mappedValue = max - (degrees / 180) * range;

    return steps.reduce((prev, curr) => 
      Math.abs(curr - mappedValue) < Math.abs(prev - mappedValue) ? curr : prev
    );
  }, [min, max, range, steps]);

  useEffect(() => {
    const knob = knobRef.current;
    if (!knob) return;

    const handleStart = (e) => {
      isDraggingRef.current = true;
      document.body.style.cursor = 'grabbing';
      knob.style.cursor = 'grabbing';
      e.preventDefault();
      e.stopPropagation();
    };

    const handleMove = (e) => {
      if (!isDraggingRef.current) return;
      const clientX = e.clientX || (e.touches && e.touches[0].clientX);
      const clientY = e.clientY || (e.touches && e.touches[0].clientY);
      updateKnob(getStepFromEvent(clientX, clientY));
      e.preventDefault();
      e.stopPropagation();
    };

    const handleEnd = () => {
      isDraggingRef.current = false;
      document.body.style.cursor = 'default';
      knob.style.cursor = 'grab';
    };

    knob.addEventListener('mousedown', handleStart);
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);

    knob.addEventListener('touchstart', handleStart, { passive: false });
    document.addEventListener('touchmove', handleMove, { passive: false });
    document.addEventListener('touchend', handleEnd);

    return () => {
      knob.removeEventListener('mousedown', handleStart);
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      knob.removeEventListener('touchstart', handleStart);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [getStepFromEvent, updateKnob]);

  return { value, knobRef, dialRef, updateKnob };
};

export default useKnob;