import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { addQuickMix, setCurrentPage } from '../../../redux/slice/vibeset';

import { useFormik } from 'formik';
import { SetlistNaturalValidationSchema } from '../../../validation/Formvalidation';

import { TextareaAutosize } from '@mui/material';

import { ArrowRight } from '@phosphor-icons/react';

import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';

import { TABLE_DATA, LIST_GUIDED, QUICK_MIX } from '../../../constants/PageName';

function SetListNatural() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);

  const formik = useFormik({
    initialValues: {
      description: data.vibeset.quickMix.prompt || '',
    },
    validationSchema: SetlistNaturalValidationSchema,
    onSubmit: (values) => {
      if (data.vibeset.mode == QUICK_MIX) {
        const dataQuick = { prompt: values.description, email: data.login.loginCred?.username };
        dispatch(addQuickMix(dataQuick));
        dispatch(setCurrentPage(TABLE_DATA));
      } else {
        dispatch(setCurrentPage(LIST_GUIDED));
      }
    },
  });

  return (
    <div className='mt-40'>
      <div className='space-y-6'>
        <Title size={5} className='mb-6 font-semibold'>
          <h1>What&apos;s your mix?</h1>
        </Title>
        <Lead size={0}>
          <p>Describe the type of setlist you are looking to build.</p>
        </Lead>

        <TextareaAutosize className='w-full resize-none border-b-4 border-off-white/50 bg-transparent py-6 text-xl focus:border-NRG focus:outline-none md:text-2xl lg:text-3xl xl:text-4xl placeholder:font-light placeholder:text-off-white/50' placeholder='Make an upbeat set list that blends Kendrick Lamar and Taylor Swift' id='description' name='description' value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur} />

        <button onClick={formik.handleSubmit} className='flex select-none items-center gap-4 rounded-3 border-1 border-valence bg-valence px-[1.25em] py-[0.75em] font-display text-lg font-bold tracking-wider text-hard hover:bg-valence-600 disabled:pointer-events-none disabled:bg-valence disabled:opacity-60 md:text-xl lg:text-2xl' disabled={!formik.isValid}>
          Let&apos;s vibe <ArrowRight weight='bold' />
        </button>
      </div>
    </div>
  );
}

export default SetListNatural;
