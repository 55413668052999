import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QUICK_MIX } from '@/constants/PageName';
import {
  exportToSpotify,
  exportToTidal,
  fetchPlayList,
  basicSearchStore,
  basicSearchCount,
  setCountLimit
} from '@/redux/slice/vibeset';
import { showToast } from '@/redux/slice/toastSlice';
import { setDemoUsedFlag } from '@/redux/slice/login';


const usePlaylistData = () => {
  const dispatch = useDispatch();
  const { vibeset, login } = useSelector((state) => state);
  const { mode, quickMix, guidedMix } = vibeset;
  const { loginCred } = login;

  const [playlistData, setPlaylistData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        dispatch(setDemoUsedFlag(true));
        await dispatch(basicSearchCount()).then((response) =>
          dispatch(setCountLimit(response?.payload?.count))
        );

        const payload = {
          baseURL: import.meta.env.VITE_VIBESET_PLAYLIST_BASE_URL,
          url: mode === QUICK_MIX ? '/generateNatural' : '/generateGuided',
          method: 'post',
          headers: { 'Media-Type': 'application/json' },
          data: mode === QUICK_MIX ? quickMix : guidedMix
        };

        const result = await dispatch(fetchPlayList(payload));

        if (Array.isArray(result?.payload?.data)) {
          const dataWithIds = result.payload.data.map((song, index) => ({
            ...song,
            stableId: `${song.name}-${song.artist}-${index}`
          }));
          setPlaylistData(dataWithIds);

          dispatch(
            exportToTidal({
              setlist: dataWithIds.map(({ name, artist }) => ({ name, artist: artist || '' }))
            })
          );
          dispatch(
            exportToSpotify({
              baseURL: import.meta.env.VITE_VIBESET_SPOTIFY_API_URL,
              url: '/convertToSpotify',
              method: 'post',
              headers: { 'Media-Type': 'application/json' },
              data: { setlist: dataWithIds }
            })
          );

          if (loginCred?.subscription_details?.is_basic_plan) {
            dispatch(basicSearchStore());
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch(showToast({ message: 'Failed to fetch playlist data', type: 'error' }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, mode, quickMix, guidedMix, loginCred]);

  return { playlistData, setPlaylistData, isLoading };
};

export default usePlaylistData;