const calculateParallaxConfig = (wrapperHeight, viewportHeight, parallaxStrength = 1) => {
  if (!wrapperHeight || !viewportHeight || parallaxStrength <= 0) {
    return {
      start: 'top top',
      end: 'bottom bottom',
      yRange: [0, 0],
      outerStyle: { height: '100%' }
    };
  }

  const totalHeight = wrapperHeight * (1 + 2 * parallaxStrength);
  const yRange = wrapperHeight * parallaxStrength;
  const isWrapperTallerThanViewport = wrapperHeight > viewportHeight;

  return {
    start: isWrapperTallerThanViewport ? 'top top' : `${-(viewportHeight - wrapperHeight)}px top`,
    end: isWrapperTallerThanViewport ? 'bottom bottom' : `bottom ${wrapperHeight}px`,
    yRange: [-yRange, yRange],
    outerStyle: { height: `${totalHeight}px` }
  };
};

export default calculateParallaxConfig;
