import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';

const ResultChart = ({ data, color1, color2 }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const chartConfig = useMemo(
    () => ({
      type: 'line',
      data: {
        labels: Array.from({ length: data.length }, (_, i) => i + 1),
        datasets: [
          {
            data: data,
            borderWidth: 4,
            borderColor: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) return color1;
              return getGradient(ctx, chartArea, color1, color2);
            },
            fill: {
              target: 'origin',
              above: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;
                if (!chartArea) return 'rgba(0,212,255,0.1)';
                return getGradient(ctx, chartArea, 'rgba(0,212,255,0.1)', 'rgba(215,228,231,0.1)');
              }
            }
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: 2 },
        scales: {
          y: {
            min: 0, // Set to 0 to ensure y-axis always starts from 0
            beginAtZero: true,
            display: true,
            ticks: { display: false },
            grid: { color: 'rgba(0,212,255,0.1)' },
            border: { display: false }
          },
          x: { display: false }
        },
        tension: 0.4,
        plugins: {
          legend: { display: false },
          labels: { display: false }
        },
        elements: { point: { radius: 0 } },
        animation: {
          duration: 300,
          easing: 'easeOutQuad'
        },
        transitions: {
          active: {
            animation: {
              duration: 300
            }
          }
        }
      }
    }),
    [color1, color2]
  );

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext('2d');
    if (!ctx) return;

    if (!chartInstance.current) {
      chartInstance.current = new Chart(ctx, chartConfig);
    }

    const updateChart = () => {
      if (chartInstance.current) {
        chartInstance.current.data.labels = Array.from({ length: data.length }, (_, i) => i + 1);
        chartInstance.current.data.datasets[0].data = data;
        chartInstance.current.options.scales.y.min = 0;
        chartInstance.current.update('none'); // Use 'none' mode to update without animation
      }
    };

    updateChart();

    const resizeObserver = new ResizeObserver(() => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    });

    resizeObserver.observe(chartRef.current);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      resizeObserver.disconnect();
    };
  }, [data, chartConfig]);

  return <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

ResultChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired
};

function getGradient(ctx, chartArea, color1, color2) {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(0, color1);
  gradient.addColorStop(0.5, color2);
  gradient.addColorStop(1, color1);
  return gradient;
}

export default React.memo(ResultChart);
