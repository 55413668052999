import React from 'react';
import NotFound from '../components/NotFound';
import {
  GUIDED_MIX_HOME_PAGE,
  HOME_PAGE,
  NOT_FOUND_PAGE,
  QUICK_MIX_HOME_PAGE,
  ABOUT,
  ACCOUNT,
  ALLINONE,
  LOGIN,
  RESET,
  FORGOT,
  CHANGE_PASSWORD,
  SUBSCRIPTION_PLANS,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  SONG_INFO_HISTORY,
  LANDING_PAGE,
  SONG_DETAILS_HISTORY,
  PROFILE,
  PLAN_INFO_HISTORY
} from '../constants/PageName';
import SetListGuidedHome from '../views/SetListGuidedHome';
import SetListNaturalHome from '../views/SetListNaturalHome';
import Account from '../views/Account';
import About from '../views/About';
import AllInOne from '../views/AllInOne';
import Vibeset from '../views/Vibeset';
import Login from '../views/Login';
import ResetPassword from '../views/ResetPassword';
import ForgotPassword from '../views/ForgotPassword';
import ChangePassword from '../views/ChangePassword';
import SubscriptionPlans from '../views/SubscriptionPlans';
import PaymentSuccess from '../views/PaymentSuccess';
import PaymentFailed from '../views/PaymentFailed';
import SongInfoHistory from '../views/SongInfoHistory';
import LandingPage from '../views/LandingPage';
import SongDetailsHistory from '../views/SongDetailsHistory';
import MyProfile from '../views/MyProfile';
import PlanInfoHistory from '../views/planInfoHistory';

export const routes = [
  {
    id: 1,
    name: 'chooseAdvanture',
    path: HOME_PAGE,
    component: <Vibeset />,
    isProtected: null
  },
  {
    id: 2,
    name: 'setlistNatural',
    path: QUICK_MIX_HOME_PAGE,
    component: <SetListNaturalHome />,
    isProtected: true
  },
  {
    id: 3,
    name: 'setlistGuided',
    path: GUIDED_MIX_HOME_PAGE,
    component: <SetListGuidedHome />,
    isProtected: true
  },
  {
    id: 4,
    name: 'account',
    path: ACCOUNT,
    component: <Account />,
    isProtected: false
  },
  {
    id: 5,
    name: 'login',
    path: LOGIN,
    component: <Login />,
    isProtected: false
  },
  {
    id: 6,
    name: 'resetPassword',
    path: RESET,
    component: <ResetPassword />,
    isProtected: false
  },
  {
    id: 7,
    name: 'forgotPassword',
    path: FORGOT,
    component: <ForgotPassword />,
    isProtected: false
  },
  {
    id: 6,
    name: 'changepassword',
    path: CHANGE_PASSWORD,
    component: <ChangePassword />,
    isProtected: true
  },
  {
    id: 8,
    name: 'notFound',
    path: NOT_FOUND_PAGE,
    component: <NotFound />,
    isProtected: null
  },
  {
    id: 9,
    name: 'subscriptionPlans',
    path: SUBSCRIPTION_PLANS,
    component: <SubscriptionPlans />,
    isProtected: false
  },
  {
    id: 10,
    name: 'paymentSuccess',
    path: PAYMENT_SUCCESS,
    component: <PaymentSuccess />,
    isProtected: true
  },
  {
    id: 11,
    name: 'paymentFailed',
    path: PAYMENT_FAILED,
    component: <PaymentFailed />,
    isProtected: true
  },
  {
    id: 12,
    name: 'songInfoHistory',
    path: SONG_INFO_HISTORY,
    component: <SongInfoHistory />,
    isProtected: true
  },
  {
    id: 12,
    name: 'planInfoHistory',
    path: PLAN_INFO_HISTORY,
    component: <PlanInfoHistory />,
    isProtected: null
  },
  {
    id: 13,
    name: 'LandingPage',
    path: LANDING_PAGE,
    component: <LandingPage />,
    isProtected: null
  },
  {
    id: 14,
    name: 'songDetailsHistory',
    path: SONG_DETAILS_HISTORY,
    component: <SongDetailsHistory />,
    isProtected: true
  },
  {
    id: 15,
    name: 'myProfile',
    path: PROFILE,
    component: <MyProfile />,
    isProtected: true
  },
  {
    id: 15,
    name: 'about',
    path: ABOUT,
    component: <About />,
    isProtected: false
  },
  {
    id: 16,
    name: 'allInOne',
    path: ALLINONE,
    component: <AllInOne />,
    isProtected: true
  }
];
