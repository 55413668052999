import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const HTTP = axios.create({
  baseURL: import.meta.env.VITE_VIBESET_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const createAPIUtils = (actions) => {
  const createAPIAsyncThunk = (apiName, apiConfig) => {
    return createAsyncThunk(apiName, async (formData, { dispatch, getState }) => {
      try {
        const config = { ...apiConfig };
        if (config.getStateToken) {
          const token = getState().login.loginCred?.token;
          config.headers.Authorization = `Token ${token}`;
        }
        let url = replaceVariables(config.url, getState(), formData?.tokenID);
        let response = null;
        if (config.method !== 'get')
          response = await HTTP[config.method](url, formData, { headers: config.headers });
        else {
          response = await HTTP[config.method](url, { headers: config.headers });
        }
        return response.data;
      } catch (error) {
        if (error.response.status == 401) {
          dispatch(
            actions.showToast({
              message: 'User session expired',
              type: 'error'
            })
          );
          dispatch(actions.updateLoginCredential(null));
          dispatch(actions.resetClickedRoute(null));
          dispatch(actions.updateUnAuth(true));
          dispatch(actions.setRedirectedRoute('log-out'));
        } else {
          dispatch(
            actions.setFieldError(error.response?.data || { non_field_errors: 'Something went wrong!' })
          );
          throw error;
        }
      }
    });
  };

  const replaceVariables = (url, state, token) => {
    let replacedUrl = url.replace(/{{([a-zA-Z0-9_]+)}}/g, (match, variable) => {
      if (variable) {
        return token || match;
      }
      return variable || match;
    });
    return replacedUrl;
  };

  return {
    createAPIAsyncThunk
  };
};