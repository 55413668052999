import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { updateAutocompleteTags } from '@/views/AllInOne/store';

import Panel from '@/views/AllInOne/atoms/Panel';
import Title from '@/components/new/Title';

import Autocomplete from '@/components/new/Autocomplete';
import { getSelectedTags } from '@/components/new/Autocomplete/store';

function ArtistSongPanel({ className }) {
  useEffect(() => {
    const updateTags = () => {
      const selectedTags = getSelectedTags();
      updateAutocompleteTags(selectedTags);
    };

    updateTags();
    const intervalId = setInterval(updateTags, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Panel className={className}>
      <Title className='mt-6 font-mono text-3xl uppercase lg:text-4xl'>
        <h2>Artist/song selection</h2>
      </Title>
      <div className='my-4 px-4 text-lg md:text-xl xl:text-2xl'>
        <Autocomplete />
      </div>
    </Panel>
  );
}

ArtistSongPanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(ArtistSongPanel);
