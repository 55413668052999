import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.scss';

const Ribbon = ({ children, className }) => {
    return (
      <span className={clsx(styles.ribbon, className)}>
        {children}
      </span>
    );
  };

Ribbon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Ribbon.displayName = 'Ribbon';

export default Ribbon;