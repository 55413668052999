import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { addGuidedMix, setCurrentPage } from '@/redux/slice/vibeset';

import { useFormik } from 'formik';

import { Box, TextField, Grid, Hidden } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Letsvibebutton from '@/components/layout/Button/Letsvibe';
import Text from '@/components/new/Text';

import { BuildyousetValidationSchema } from '@/validation/Formvalidation';

import portfolioLanding from '@/assets/styles/img/Portfolio_landing.png';

import { TABLE_DATA, LIST_NATURAL, QUICK_MIX, FEEL_OUT } from '@/constants/PageName';

function BuildYourSet() {
  const dispatch = useDispatch();
  const vibeSetGuidedMix = useSelector((state) => state.vibeset?.guidedMix);
  const vibeSetMode = useSelector((state) => state.vibeset?.mode);
  const loggedInuserName = useSelector((state) => state.login?.loginCred);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      artist: vibeSetGuidedMix.song_artist || ''
    },
    validationSchema: BuildyousetValidationSchema,
    onSubmit: (value) => {
      if (vibeSetMode == QUICK_MIX) {
        dispatch(setCurrentPage(LIST_NATURAL));
      } else {
        const dataGuided = {
          genre: vibeSetGuidedMix.genre,
          bpm: vibeSetGuidedMix.bpm,
          email: loggedInuserName?.username,
          song_artist: value.artist,
          valence: vibeSetGuidedMix.valence
        };
        dispatch(addGuidedMix(dataGuided));
        dispatch(setCurrentPage(TABLE_DATA));
      }
    }
  });

  // Function to navigate to a specific page
  const goBackHandler = () => {
    dispatch(setCurrentPage(FEEL_OUT));
  };

  return (
    <Box className='form-card child-main fade-in one text-white'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box>
            <Box>
              <Text.Title tag='h1' variant='md'>
                {t('setlist-guided.build')}
              </Text.Title>
              <Box className='blue-divider'></Box>
              <Text.Lead style={{ marginBlock: '1.5em' }}>{t('setlist-guided.add')}</Text.Lead>
            </Box>
            <TextField className='form-input-email text-white build-text' placeholder={t('setlist-guided.placeholder.draft')} variant='outlined' name='artist' value={formik.values.artist} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <Box className='mt-btn-box d-flex'>
              <Letsvibebutton className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white' onClick={() => goBackHandler()}>
                {t('common.back')}
              </Letsvibebutton>
              <Letsvibebutton onClick={formik.handleSubmit} className={`btn-text-blue btn-bg-teal-blue primary ${formik.values.artist ? '' : 'btn-color-change'}`} disabled={!formik.isValid}>
                {t('common.letsVibe')} <ArrowForwardIcon />
              </Letsvibebutton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Hidden mdDown>
            <Box display='flex' justifyContent='end' alignItems='start' className='buildYourSet-Img'>
              <img srcSet={portfolioLanding} alt={t('setlist-guided.alt.landing')} width={504} height={485} />
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BuildYourSet;
