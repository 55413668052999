import React from 'react';
import PropTypes from 'prop-types';
import { motion, useTransform } from 'framer-motion';

import styles from './styles.module.scss';

import VisualBPM from './visual_BPM.png';
import VisualDanceability from './visual_Danceability.png';
import VisualKey from './visual_Key.png';
import VisualMood from './visual_Mood.png';

const visualImages = [VisualBPM, VisualDanceability, VisualKey, VisualMood];

const VisualImage = React.memo(({ image, index, progress }) => {
  const start = index * 0.2;
  const end = start + 0.2;

  const opacity = useTransform(progress, [start, end], [0, 1]);
  const y = useTransform(progress, [start, end], [-100, 0]);
  const scale = useTransform(progress, [start, end], [0.4, 1]);

  return (
    <motion.img
      src={image}
      alt={`Visual ${index}`}
      className={`${styles.visual} ${styles[`visual_${index}`]}`}
      style={{ opacity, y, scale }}
    />
  );
});

VisualImage.displayName = 'VisualImage';
VisualImage.propTypes = {
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  progress: PropTypes.object.isRequired
};

export { visualImages, VisualImage };
