import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateLoginCredential } from '../../redux/slice/login';
import { resetClickedRoute, setRedirectedRoute } from '../../redux/slice/clickedRoute';
import { ACCOUNT_LOGIN } from '../../constants/PageName';
import { showToast } from '../../redux/slice/toastSlice';

const HTTP = axios.create({
  baseURL: import.meta.env.VITE_VIBESET_BACKEND_API_URL, // Assuming your backend server is running on the same host
  headers: {
    'Content-Type': 'application/json'
  }
});

export const fetchSubscriptionPlans = createAsyncThunk(
  'fetchSubscriptionPlans',
  async (formData, { dispatch, navigate }) => {
    try {
      const response = await HTTP.get('/plan_fetch/', formData);
      if (response.status == 200) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

// Action
export const subscriptionPayment = createAsyncThunk(
  'subscriptionPayment',
  async (payload, { dispatch, navigate, getState }) => {
    try {
      const token = getState().login.loginCred?.token;
      const response = await HTTP.post(
        '/stripe_subscription_payment_intent_or_sub_create/',
        payload,
        {
          headers: {
            Authorization: 'Token ' + token
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(
          showToast({
            message: 'User session expired',
            type: 'error'
          })
        );
        dispatch(updateLoginCredential(null));
        dispatch(resetClickedRoute(null));
        dispatch(setRedirectedRoute('log-out'));
        navigate(ACCOUNT_LOGIN);
      } else {
        throw error.response.data;
      }
    }
  }
);

const initialStateValue = {
  isLoading: false,
  isError: false,
  planId: '',
  paymentStarted: false,
  paymentCompleted: false,
  paymentStatus: false,
  subscriptionPlans: []
};

const paymnentDetailSlice = createSlice({
  name: 'paymnentdetail',
  initialState: { ...initialStateValue },
  reducers: {
    setPlanId: (state, action) => {
      state.planId = action.payload;
    },
    setSubscriptionPlans: (state, action) => {
      state.subscriptionPlans = action.payload;
    },
    resetSubscriptionData: (state) => {
      state.isLoading = initialStateValue.isLoading;
      state.isError = initialStateValue.isError;
      state.planId = initialStateValue.planId;
      state.paymentStarted = initialStateValue.paymentStarted;
      state.paymentCompleted = initialStateValue.paymentCompleted;
      state.paymentStatus = initialStateValue.paymentStatus;
      state.subscriptionPlans = initialStateValue.subscriptionPlans;
    },
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    }
  }
});

export const { resetFormData, setPlanId, setSubscriptionPlans, updateLoader } =
  paymnentDetailSlice.actions;

export default paymnentDetailSlice.reducer;
