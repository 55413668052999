import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_DJ, SIGN_UP_SUCCESS, ADD_DOB } from '../../constants/PageName';
import { updateFormData } from '../../redux/slice/createaccount';
import { setCurrentPage } from '../../redux/slice/vibeset';
import { DobValidationSchema } from '../../validation/Formvalidation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

function Dob({ contentRef, buttonRef, setPrevoiusPage, isPrevious }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.createaccount.formData);
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 13);
  const validationSchema = DobValidationSchema;
  const errorObject = useSelector((state) => state.createaccount.fieldErrors);

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    setPrevoiusPage(ADD_DOB);
    dispatch(setCurrentPage(page));
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let date = dayjs(formik.values.dob).format('YYYY-MM-DD');
      // let date = dayjs(formik.values.dob).$d.toISOString().split('T')[0];

      dispatch(updateFormData({ ...formik.values, dob: date }));
      goToPageHandler(SIGN_UP_SUCCESS);
    }
  });

  return (
    <>
      <div className='create-account content-animation' ref={contentRef}>
        <div className='heading'>
          <h1 className='text-white'>{t('dj-detail.tallToRide')}</h1>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className='dob-label'>
                <h5 className='text-white'>{t('dj-detail.enterDob')}</h5>
              </div>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <div className='custom-date-picker'>
                  <DatePicker
                    className={
                      (formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob
                        ? 'error-border form-input-fonts'
                        : 'form-input-fonts'
                    }
                    openTo='month'
                    views={['month']}
                    value={formik.values.dob ? dayjs(formik.values.dob) : null}
                    onChange={(newValue) => {
                      formik.setFieldValue('dob', newValue);
                    }}
                    error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob}
                    helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob}
                  />
                  <DatePicker
                    className={
                      (formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob
                        ? 'error-border form-input-fonts'
                        : 'form-input-fonts'
                    }
                    openTo='day'
                    views={['day']}
                    value={formik.values.dob ? dayjs(formik.values.dob) : null}
                    onChange={(newValue) => formik.setFieldValue('dob', newValue)}
                    error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob}
                    helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob}
                  />
                  <DatePicker
                    className={
                      (formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob
                        ? 'error-border form-input-fonts'
                        : 'form-input-fonts'
                    }
                    openTo='year'
                    views={['year']}
                    value={formik.values.dob ? dayjs(formik.values.dob) : null}
                    onChange={(newValue) => formik.setFieldValue('dob', newValue)}
                    error={(formik.touched.dob && Boolean(formik.errors.dob)) || errorObject?.dob}
                    helperText={(formik.touched.dob && formik.errors.dob) || errorObject?.dob}
                    disableFuture
                  />
                </div>
              </DemoContainer>
              <span className='text-danger'> {formik?.errors?.dob || errorObject?.dob}</span>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
      {!isPrevious && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='footer-button animation' ref={buttonRef}>
            <Button
              disableRipple
              variant='outlined'
              className='outline-back-button'
              onClick={() => goToPageHandler(ADD_DJ)}
            >
              <ArrowBackOutlinedIcon /> {t('common.goBack')}
            </Button>
            <Button
              disableRipple
              variant='contained'
              className='fill-button'
              type='submit'
              onClick={formik.handleSubmit}
            >
              {t('common.submit')} <ArrowForwardIcon />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}

export default Dob;
