import React from 'react';
import PropTypes from 'prop-types';

const Exclusive = ({ className }) => (
  <svg viewBox='0 0 47 45' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path d='M23.8187 35.841L10.9604 42.6014L13.4167 28.2827L3 18.1431L17.375 16.0597L23.8042 3.03265L30.2333 16.0597L44.6083 18.1431L34.1917 28.2827L36.6479 42.6014L23.8187 35.841Z' stroke='currentColor' strokeWidth='4.16667' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

Exclusive.propTypes = {
  className: PropTypes.string
};

export default Exclusive;
