import React from 'react';
import PropTypes from 'prop-types';

import { useStore } from '@tanstack/react-store';
import { store, updateMood } from '@/views/AllInOne/store';

import Panel from '@/views/AllInOne/atoms/Panel';
import Title from '@/components/new/Title';

import styles from '@/views/AllInOne/styles.module.scss';
import clsx from 'clsx';

const moodItems = [
  { key: 'moody', label: 'Moody', accent: 'acid-jazz' },
  { key: 'chill', label: 'Chill', accent: 'chill' },
  { key: 'happy', label: 'Happy', accent: 'dance' },
  { key: 'ethereal', label: 'Ethereal', accent: 'valence' },
  { key: 'intense', label: 'Intense', accent: 'electric' },
];

const MoodPanel = ({ className }) => {
  const activeMood = useStore(store, (state) => state.mood);

  return (
    <Panel className={className}>
      <Title className='mt-6 font-mono text-3xl uppercase lg:text-4xl'>
        <h2>Mood</h2>
      </Title>
      <div className='mx-4 mb-4 flex gap-4 text-hard-300 justify-between items-start'>
        {moodItems.map(({ key, label, accent }) => (
          <div key={key} className={clsx(styles.moods__item, styles[`mood__${key}`], { [styles.moods__item__active]: activeMood === key }, 'grid aspect-square flex-1 cursor-pointer select-none place-content-center border-3 font-mono font-black uppercase')} style={{ '--accent': `var(--color-${accent})` }} onClick={() => updateMood(key)}>
            {label}
          </div>
        ))}
      </div>
    </Panel>
  );
};

MoodPanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(MoodPanel);
