import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';

const Bubble = React.memo(
  React.forwardRef(({ bubble, index, onClick }, ref) => {
    const handleClick = useCallback(() => onClick(index), [index, onClick]);

    return (
      <motion.div
        className={styles.bubble}
        style={{
          left: `${bubble.x}px`,
          top: `${bubble.y}px`,
          transform: 'translate(-50%, -50%)',
          '--rotate': bubble.rotation,
        }}
        onClick={handleClick}
        animate={{
          background: bubble.active ? 'var(--bubble-tint)' : 'rgba(255, 255, 255, 0)',
          color: bubble.active ? 'var(--active-color)' : 'var(--bubble-tint)',
          padding: bubble.active ? '1.5em' : '0.75em',
        }}
        whileHover={{
          padding: bubble.active ? '1.5em' : '1em',
        }}
        transition={{ type: 'spring', stiffness: 300, mass: 1.5, bounce: 1.5, damping: 30 }}
      >
        <motion.div
          ref={ref}
          className='inline-block w-[var(--longest)] min-w-min whitespace-normal text-center'
          animate={{
            transform: `rotate(${bubble.active ? bubble.rotation : -bubble.rotation}deg)`,
          }}
          transition={{ type: 'spring', stiffness: 600, mass: 1.25, bounce: 1.5, damping: 15 }}
          style={{ '--longest': '0px' }}
        >
          {bubble.label}
        </motion.div>
      </motion.div>
    );
  })
);

Bubble.displayName = 'Bubble';

Bubble.propTypes = {
  bubble: PropTypes.shape({
    label: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    vx: PropTypes.number.isRequired,
    vy: PropTypes.number.isRequired,
    rotation: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Bubble;
