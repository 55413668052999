import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './redux/store.js';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Weights 300, 400, 500
import '@fontsource/dm-mono';
// Variable weights 100-900
import '@fontsource-variable/inter';

import App from './App.jsx';

import Toast from './components/toast/ToastMessage';

import './assets/styles/style.scss';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1400
    }
  }
});
dayjs.extend(customParseFormat);

export default theme;
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Toast />
          <App />
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
