import React from 'react';
import PropTypes from 'prop-types';

const SetGen = ({ className }) => (
  <svg viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path d='M7.56086 48.0309C8.32163 48.0499 9.07853 47.9165 9.78696 47.6385C10.4954 47.3605 11.141 46.9436 11.6858 46.4123C12.2306 45.8809 12.6636 45.2459 12.9592 44.5447C13.2548 43.8434 13.4071 43.0901 13.4071 42.3291C13.4071 41.5681 13.2548 40.8147 12.9592 40.1135C12.6636 39.4122 12.2306 38.7772 11.6858 38.2459C11.141 37.7146 10.4954 37.2976 9.78696 37.0196C9.07853 36.7417 8.32163 36.6083 7.56086 36.6273C6.07336 36.6645 4.6593 37.2816 3.62039 38.3468C2.58149 39.4121 2 40.8411 2 42.3291C2 43.817 2.58149 45.2461 3.62039 46.3113C4.6593 47.3766 6.07336 47.9936 7.56086 48.0309ZM33.2251 42.3273C33.9859 42.3463 34.7428 42.2129 35.4512 41.935C36.1597 41.657 36.8053 41.24 37.3501 40.7087C37.8949 40.1773 38.3279 39.5423 38.6235 38.8411C38.9191 38.1398 39.0714 37.3865 39.0714 36.6255C39.0714 35.8645 38.9191 35.1112 38.6235 34.4099C38.3279 33.7087 37.8949 33.0737 37.3501 32.5423C36.8053 32.011 36.1597 31.594 35.4512 31.3161C34.7428 31.0381 33.9859 30.9047 33.2251 30.9237C31.7376 30.961 30.3236 31.578 29.2847 32.6433C28.2458 33.7085 27.6643 35.1375 27.6643 36.6255C27.6643 38.1135 28.2458 39.5425 29.2847 40.6078C30.3236 41.673 31.7376 42.2901 33.2251 42.3273Z' stroke='currentColor' strokeWidth='3.57143' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.2644 42.3273V19.1773C13.2662 18.8645 13.3705 18.5609 13.5615 18.3132C13.7525 18.0654 14.0195 17.8872 14.3215 17.8059L18.8215 16.5166M13.2644 26.6452L27.7644 22.6166M38.9251 36.6273V31.5952M26.3894 14.138C25.1394 13.9202 25.1394 12.1202 26.3894 11.9023C28.6096 11.5166 30.6647 10.4784 32.2924 8.91999C33.9202 7.36158 35.0468 5.35365 35.5287 3.1523L35.6037 2.80587C35.8751 1.56658 37.6394 1.55944 37.9215 2.79516L38.0108 3.19873C38.5108 5.39032 39.6475 7.3852 41.2781 8.93254C42.9087 10.4799 44.9603 11.5106 47.1751 11.8952C48.4358 12.1166 48.4358 13.9237 47.1751 14.1452C44.9603 14.5297 42.9087 15.5604 41.2781 17.1078C39.6475 18.6551 38.5108 20.65 38.0108 22.8416L37.9215 23.2416C37.6358 24.4809 35.8751 24.4702 35.6037 23.2344L35.5287 22.888C35.0468 20.6867 33.9202 18.6787 32.2924 17.1203C30.6647 15.5619 28.6096 14.5237 26.3894 14.138Z' stroke='currentColor' strokeWidth='3.57143' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

SetGen.propTypes = {
  className: PropTypes.string
};

export default SetGen;
