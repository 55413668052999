import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setVibeMode } from '@/redux/slice/vibeset';

import SetlistCreation from '@/components/layout/Quick-mix/SetlistCreation';
import FeelItOut from '@/components/layout/Guided-mix/Feelitout';
import Buildyourset from '@/components/layout/Guided-mix/Buildyourset';
import SetlistGuided from '@/components/SetlistGuided';
import BpmRange from '@/components/BpmRange';
import Tabledata from '@/components/table';
import SetListNatural from '@/components/layout/Quick-mix/SetlistNatural';
import CreateAccount from '@/components/account/CreateAccount';

import { BPM_RANGE, BUILD_SET, FEEL_OUT, LETS_VIBE, LIST_CREATION, LIST_GUIDED, LIST_NATURAL, QUICK_MIX, TABLE_DATA, SIGN_UP_ACCOUNT } from '@/constants/PageName';

function SetListNaturalHome() {
  const currentPage = useSelector((state) => state.vibeset.currentPage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVibeMode(QUICK_MIX));
    if (currentPage !== LETS_VIBE) dispatch(setCurrentPage(LETS_VIBE));
  }, []);

  const ennumOfComponent = {
    [LETS_VIBE]: <SetListNatural />,
    [LIST_NATURAL]: <SetListNatural />,
    [LIST_CREATION]: <SetlistCreation />,
    [FEEL_OUT]: <FeelItOut />,
    [BUILD_SET]: <Buildyourset />,
    [LIST_GUIDED]: <SetlistGuided />,
    [BPM_RANGE]: <BpmRange />,
    [TABLE_DATA]: <Tabledata />,
    [SIGN_UP_ACCOUNT]: <CreateAccount />
  };
  return <div className='container mx-auto px-4'>{ennumOfComponent[currentPage]}</div>;
}

export default SetListNaturalHome;
