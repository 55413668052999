import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const sizeClasses = {
  0: 'text-lg md:text-xl lg:text-2xl xl:text-3xl',
  1: 'text-xl md:text-2xl lg:text-3xl xl:text-4xl',
  2: 'text-2xl md:text-3xl lg:text-4xl xl:text-5xl',
  3: 'text-3xl md:text-4xl lg:text-5xl xl:text-6xl',
  4: 'text-4xl md:text-5xl lg:text-6xl xl:text-7xl',
  5: 'text-5xl md:text-6xl lg:text-7xl xl:text-8xl',
  6: 'text-6xl md:text-7xl lg:text-8xl xl:text-9xl',
};

const Lead = React.forwardRef(({ children, className, size, ...props }, ref) => {
  const child = React.Children.only(children);
  const sizeClass = size !== null ? sizeClasses[size] : '';

  return React.cloneElement(child, {
    ref,
    className: twMerge('font-light tracking-tight mb-4 last:mb-0', sizeClass, className, child.props.className),
    ...props,
    ...child.props,
  });
});

Lead.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
};

Lead.displayName = 'Lead';

export default Lead;
