export const LETS_VIBE = 'lets-vibe';
export const LIST_NATURAL = 'list-natural';
export const LIST_CREATION = 'list-creation';
export const FEEL_OUT = 'feel-out';
export const BUILD_SET = 'build-set';
export const LIST_GUIDED = 'list-guided';
export const BPM_RANGE = 'bpm-range';
export const TABLE_DATA = 'table-data';
export const QUICK_MIX = 'quick-mix';
export const GUIDED_MIX = 'guided-mix';
export const SIGN_UP_ACCOUNT = 'sign-up';
export const ENTER_PAYMENT = 'enter-payment';
export const ADD_BILLING = 'add-billing';
export const ADD_DJ = 'add-dj';
export const ADD_DOB = 'add-dob';
export const SIGN_UP_SUCCESS = 'sign-up-success';
export const ACCOUNT_LOGIN = 'login';
export const ACCOUNT_RESET = 'resetPassword';
export const ACCOUNT_FORGOT = 'forgotPassword';

// Main Router Name
export const HOME_PAGE = '/choose-adventure';
export const QUICK_MIX_HOME_PAGE = '/setlist-natural';
export const GUIDED_MIX_HOME_PAGE = '/setlist-guided';
export const ABOUT = '/about';
export const ACCOUNT = '/account';
export const ALLINONE = '/all-in-one';
export const LOGIN = '/login';
export const FORGOT = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';
export const RESET = '/reset-password/:id';
export const NOT_FOUND_PAGE = '*';
export const SUBSCRIPTION_PLANS = '/subscription-plans';
export const PAYMENT_SUCCESS = '/payment-success';
export const PAYMENT_FAILED = '/payment-failed';
export const SONG_INFO_HISTORY = '/song-info-history';
export const PLAN_INFO_HISTORY = '/plan-info-history';
export const LANDING_PAGE = '/';
export const SONG_DETAILS_HISTORY = '/song-details-history/:id';
export const PROFILE = '/profile';

// Default Variable Value
export const BPM_DEFAULT_RANGE = '120 - 170';
