import React from 'react';
import PropTypes from 'prop-types';

import { X } from '@phosphor-icons/react';

const Tag = ({ tag, onDelete }) => (
  <div className='flex gap-2 px-2.5 py-2 rounded-1.5 bg-NRG leading-none text-dnb-blue'>
    <span className='font-display font-medium uppercase text-left'>
      <strong className='font-semibold'>{tag.artist}</strong>
      {tag.song !== 'N/A' && ' - ' + tag.song}
    </span>
    <X weight='bold' className='shrink-0 p-0.5 leading-none rounded-full hover:bg-hard hover:text-white cursor-pointer' onClick={() => onDelete(tag)} />
  </div>
);

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Tag;
