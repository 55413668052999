import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const TextEllipsis = React.forwardRef(({ children, className, lines = 1, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge(
        'overflow-hidden',
        lines === 1 ? 'overflow-hidden text-ellipsis whitespace-nowrap' : `line-clamp-${lines}`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

TextEllipsis.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  lines: PropTypes.number,
};

TextEllipsis.displayName = 'TextEllipsis';

export default TextEllipsis;