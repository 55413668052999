import React from 'react';
import PropTypes from 'prop-types';
import * as HoverCard from '@radix-ui/react-hover-card';
import { ChatCenteredDots, Info } from '@phosphor-icons/react';

const HoverTooltip = ({ content, isInsights = false }) => (
  <HoverCard.Root openDelay={100} closeDelay={300}>
    <HoverCard.Trigger asChild>
      <button className="rounded-full" aria-label="Show more information">
        {isInsights ? (
          <ChatCenteredDots className="text-2xl" />
        ) : (
          <Info className="text-xl" />
        )}
      </button>
    </HoverCard.Trigger>
    <HoverCard.Portal>
      <HoverCard.Content
        className="w-[260px] rounded bg-off-white p-5 text-hard z-[110]"
        side="bottom"
        sideOffset={6}
        align="end"
        alignOffset={-12}
      >
        {content}
        <HoverCard.Arrow className="fill-white" />
      </HoverCard.Content>
    </HoverCard.Portal>
  </HoverCard.Root>
);

HoverTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  isInsights: PropTypes.bool,
};

export default React.memo(HoverTooltip);