import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './styles.module.scss';
import FauxPlayer from '@/components/new/FauxPlayer';

import { X } from '@phosphor-icons/react';

const variants = {
  player: { backgroundColor: 'var(--color-hard)', color: 'var(--color-NRG)' },
  active: { backgroundColor: 'var(--color-hard)', color: 'var(--color-NRG)' },
  inactive: { backgroundColor: 'var(--color-NRG)', color: 'var(--color-dnb-blue)' },
};

const Tag = ({ item, state, setState, onPlayerStateChange }) => {
  const handleTagClick = useCallback(() => {
    if (state !== 'player') {
      setState('player');
      onPlayerStateChange();
    }
  }, [state, setState, onPlayerStateChange]);

  const handleXmarkClick = useCallback(
    (e) => {
      e.stopPropagation();
      setState(state === 'active' ? 'inactive' : 'active');
    },
    [state, setState]
  );

  const tagClassName = useMemo(() => clsx(styles.tag, styles[state]), [state]);

  return (
    <motion.div layout className={tagClassName} onClick={handleTagClick} variants={variants} initial='inactive' animate={state} transition={{ duration: 0.3 }}>
      <div className={styles.tag__artist}>
        <span>{item.artist}</span>
      </div>

      <div className={styles.tag__song}>
        {state === 'player' ?
          <span>&nbsp;-&nbsp;{item.song}</span>
        : <span></span>}
      </div>

      <div className={styles.tag__close}>
        {state !== 'inactive' ?
          <button className={styles.button} onClick={handleXmarkClick}>
            <X weight='bold' />
          </button>
        : ''}
      </div>

      <div className={styles.FauxPlayer}>
        {state === 'player' ?
          <FauxPlayer />
        : <span />}
      </div>
    </motion.div>
  );
};

Tag.propTypes = {
  item: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    song: PropTypes.string.isRequired,
  }).isRequired,
  state: PropTypes.oneOf(['player', 'active', 'inactive']).isRequired,
  setState: PropTypes.func.isRequired,
  onPlayerStateChange: PropTypes.func.isRequired,
};

export default Tag;
