import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  LIST_CREATION,
  QUICK_MIX,
  BUILD_SET,
  LETS_VIBE,
  SUBSCRIPTION_PLANS,
  LANDING_PAGE,
  HOME_PAGE
} from '../constants/PageName';
import {
  exportToSpotify,
  exportToTidal,
  fetchPlayList,
  basicSearchStore,
  basicSearchCount,
  setCurrentPage,
  songInfoStoreCall,
  setCountLimit
} from '../redux/slice/vibeset';
import avgBPM from '../assets/styles/img/avg-BPM.svg';
import ambienceLevel from '../assets/styles/img/ambience-level.svg';
import groove from '../assets/styles/img/groove.svg';
import ResultChart from './ResultChart';
import Letsvibebutton from '../components/layout/Button/Letsvibe';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { showToast } from '../redux/slice/toastSlice';
import { setVibeForFreeFlag, setDemoUsedFlag } from '../redux/slice/login';
import { useNavigate } from 'react-router-dom';
import ChooseAdventure from './layout/ChooseAdventure';

function DataTable() {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [chartValues, setChartValues] = useState({
    bpm: [0],
    valence: [0],
    dance: [0],
    bpmAvg: null,
    valanceAvg: null,
    danceAvg: null
  });

  // Function to navigate to a specific page
  const goBackHandler = () => {
    navigate(HOME_PAGE);
    // if (data.login?.vibeForFreeFlag) {
    //   dispatch(setVibeForFreeFlag(false));
    // }
    // if (data.vibeset.mode == QUICK_MIX) {
    //   dispatch(setCurrentPage(LETS_VIBE));
    // } else {
    //   dispatch(setCurrentPage(BUILD_SET));
    // }
    // dispatch(setCurrentPage(LIST_CREATION));
  };

  // Function to handle storing song information
  const songInfoStore = (platform = '', url) => {
    if (url != undefined && url.startsWith('https')) {
      window.open(url, '_target');

      let payload = {
        search:
          data.vibeset.mode == QUICK_MIX
            ? data.vibeset.quickMix.prompt
            : data.vibeset.guidedMix.song_artist,
        type_search: data.vibeset.mode == QUICK_MIX ? 1 : 2,
        styles: data.vibeset.mode == QUICK_MIX ? {} : data.vibeset.guidedMix.genre,
        mood: data.vibeset.mode == QUICK_MIX ? '' : data.vibeset.guidedMix.valence,
        type_export: platform == 'tidal' ? 1 : 2,
        playlist_id: url.split('/').pop(),
        songs: data?.vibeset?.playlist?.data || [],
        lower_energy_level:
          data.vibeset.mode == QUICK_MIX
            ? null
            : parseInt(data.vibeset.guidedMix.bpm.split('-')[0]),
        upper_energy_level:
          data.vibeset.mode == QUICK_MIX ? null : parseInt(data.vibeset.guidedMix.bpm.split('-')[1])
      };
      dispatch(songInfoStoreCall(payload))
        .then((response) => {
          dispatch(
            showToast({
              message: response.payload.message,
              type: 'success'
            })
          );
        })
        .catch((error) => {
          dispatch(
            showToast({
              message: url,
              type: 'error'
            })
          );
        });
    } else {
      dispatch(
        showToast({
          message: url,
          type: 'error'
        })
      );
    }
  };

  const loadingItems = Array.from({ length: 11 }, (_, index) => (
    <TableRow key={index}>
      {index == 0 ? (
        <TableCell className='text-teal-glow font-inter'>{t('table.hangtight')}</TableCell>
      ) : (
        <>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  // renders data in table
  const renderTableRows = () => {
    if (data.vibeset.isLoading) {
      return loadingItems;
    } else if (Array.isArray(data?.vibeset?.playlist?.data)) {
      return data?.vibeset?.playlist?.data?.map((song, index) => (
        <TableRow key={index}>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.name}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.artist}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.genre}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.tempo}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.valence}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.danceability}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <>
          <TableRow>
            <TableCell
              className='mobile-hide font-inter '
              style={{ color: 'white', textAlign: 'center' }}
            >
              {t('table.noDataPresent')}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };
  useEffect(() => {
    // return async () => {
    dispatch(setDemoUsedFlag(true));

    dispatch(basicSearchCount())
      .then((response) => {
        dispatch(setCountLimit(response?.payload?.count));
      })
      .catch((error) => {
        dispatch(
          showToast({
            message: url,
            type: 'error'
          })
        );
      });
    let payload = {
      baseURL: import.meta.env.VITE_VIBESET_PLAYLIST_BASE_URL,
      url: data.vibeset.mode == QUICK_MIX ? '/generateNatural' : '/generateGuided',
      method: 'post',
      headers: {
        'Media-Type': 'application/json'
      },
      data: data.vibeset.mode == QUICK_MIX ? data.vibeset.quickMix : data.vibeset.guidedMix
    };
    dispatch(fetchPlayList(payload))
      .then((result) => {
        let graphData = {
          bpm: [0],
          valence: [0],
          dance: [0],
          bpmAvg: null,
          valanceAvg: null,
          danceAvg: null
        };
        if (Array.isArray(result?.payload?.data)) {
          graphData.bpm = [0];
          graphData.valence = [0];
          graphData.dance = [0];
          result.payload.data.map((playlist, index) => {
            graphData.bpm.push(playlist.tempo);
            graphData.valence.push(playlist.valence);
            graphData.dance.push(playlist.danceability);
          });
        }
        graphData.bpmAvg = Math.floor(
          graphData.bpm.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
            graphData.bpm.length
        );
        graphData.valanceAvg = (
          graphData.valence.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
          graphData.valence.length
        ).toFixed(1);
        graphData.danceAvg = (
          graphData.dance.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
          graphData.dance.length
        ).toFixed(1);
        setChartValues(graphData);
        exportTidal(result);
        exportSpotify(result);
        if (data?.login?.loginCred?.subscription_details?.is_basic_plan) {
          dispatch(basicSearchStore());
        }
      })
      .catch((error) => {
        throw error;
      });
    // };
  }, []);

  // exports data to tidal
  const exportTidal = (result) => {
    let tidalPayload = {
      setlist: Array.isArray(result?.payload?.data)
        ? result.payload.data.map((song, index) => {
            return { name: song.name, artist: song.artist ? song.artist : '' };
          })
        : []
    };
    dispatch(exportToTidal(tidalPayload));
  };

  // exports data to spotify
  const exportSpotify = (result) => {
    let spotifyPayload = {
      baseURL: import.meta.env.VITE_VIBESET_SPOTIFY_API_URL,
      url: '/convertToSpotify',
      method: 'post',
      headers: {
        'Media-Type': 'application/json'
      },
      data: {
        setlist: Array.isArray(result?.payload?.data) ? result.payload.data : []
      }
    };
    dispatch(exportToSpotify(spotifyPayload));
  };

  return (
    <Box className='child-main setList fade-in one'>
      <Box className='result-table-heading text-white'>
        <Box className='pinktape_cya'> </Box>
        <Typography variant='h2' className='text-upper is-h2'>
          {data.vibeset.mode == QUICK_MIX ? t('table.boom') : t('table.solid')}
        </Typography>
        <Typography variant='h3' className='is-h2'>
          {data.vibeset.mode == QUICK_MIX ? t('table.ready') : t('table.dropped')}
        </Typography>
      </Box>
      <Box className='table-card'>
        <Box className='result-table-content'>
          <Divider className='custom-divider'></Divider>
          <div className='sub-heading'>
            <Typography variant='h6' className='text-white'>
              {t('table.belowReady')}
            </Typography>
            <Typography variant='h6' className='text-white'>
              {t('table.belowReadyBreak')}
            </Typography>
          </div>
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead className='font-inter'>
                <TableRow>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.name')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.artist')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.genre')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.tempo')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.mood')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.groove')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='font-inter'>{renderTableRows()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className='chart-parent'>
          <Box className='chart-card'>
            <Box
              className='d-flex chart-card-header'
              alignItems='center'
              justifyContent='space-between'
            >
              <img srcSet={avgBPM} alt={t('table.alt.landing')} width={69} height={68} />
              <Box className='d-flex' flexDirection='column' alignItems='end'>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report-number'>
                  {chartValues.bpmAvg && !isNaN(chartValues.bpmAvg) ? chartValues.bpmAvg : null}
                </Typography>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report'>
                  {t('table.averageBpm')}
                </Typography>
              </Box>
            </Box>
            <ResultChart data={chartValues.bpm} color1='#D2E823' color2='#32D583' />
          </Box>
          <Box className='chart-card'>
            <Box
              className='d-flex chart-card-header'
              alignItems='center'
              justifyContent='space-between'
            >
              <img srcSet={ambienceLevel} alt={t('table.alt.landing')} width={69} height={68} />
              <Box className='d-flex' flexDirection='column' alignItems='end'>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report-number'>
                  {chartValues.valanceAvg &&
                  !isNaN(chartValues.valanceAvg) &&
                  chartValues.valanceAvg > 0
                    ? chartValues.valanceAvg
                    : null}
                </Typography>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report'>
                  {t('table.ambience')}
                </Typography>
              </Box>
            </Box>
            <ResultChart data={chartValues.valence} color1='#7F56D9' color2='#D717E7' />
          </Box>
          <Box className='chart-card'>
            <Box
              className='d-flex chart-card-header'
              alignItems='center'
              justifyContent='space-between'
            >
              <img srcSet={groove} alt={t('table.alt.landing')} width={69} height={68} />
              <Box className='d-flex' flexDirection='column' alignItems='end'>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report-number'>
                  {chartValues.danceAvg && !isNaN(chartValues.danceAvg) && chartValues.danceAvg > 0
                    ? chartValues.danceAvg
                    : ''}
                </Typography>
                <Typography variant='h6' style={{ color: 'white' }} className='chart-report'>
                  {t('table.groove')}
                </Typography>
              </Box>
            </Box>
            <ResultChart data={chartValues.dance} color1='#FFFFFF' color2='#1AE8CE' />
          </Box>
        </Box>
      </Box>
      <Box className='mt-btn-box d-flex'>
        <Letsvibebutton
          className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
          onClick={() => goBackHandler()}
        >
          {t('common.generateNew')}
        </Letsvibebutton>
        {data.vibeset.tidalData.result && data?.vibeset?.playlist?.data.length ? (
          <Letsvibebutton
            className='btn-text-blue mr-15 btn-bg-teal-blue primary button btn-med-white link-button-effect'
            onClick={() => songInfoStore('tidal', data.vibeset.tidalData.result)}
          >
            {t('table.export')}
            <ArrowForwardIcon />
          </Letsvibebutton>
        ) : (
          ''
        )}
        {data?.vibeset?.spotifyData?.data?.spotify_url && data?.vibeset?.playlist?.data.length ? (
          <Letsvibebutton
            className='btn-text-blue mr-15 btn-bg-teal-blue primary button btn-med-white link-button-effect'
            onClick={() => songInfoStore('spotify', data.vibeset.spotifyData.data.spotify_url)}
          >
            {t('table.exportSpotify')}
            <ArrowForwardIcon />
          </Letsvibebutton>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
}

export default DataTable;
