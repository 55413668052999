import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../redux/slice/vibeset';
import { updateFormData } from '../../redux/slice/billingdetail';

import { useFormik } from 'formik';
import { BillingDetailValidationSchema } from '../../validation/Formvalidation';

import { Box, Button, Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { ADD_BILLING, ENTER_PAYMENT, SIGN_UP_SUCCESS } from '../../constants/PageName';

function BillingDetail({ contentRef, buttonRef, setPrevoiusPage, isPrevious }) {
  const { t } = useTranslation();
  const cityList = [
    { name: 'Mumbai', value: 1 },
    { name: 'Goa', value: 2 },
    { name: 'Ahmedabad', value: 3 },
    { name: 'Surat', value: 4 },
    { name: 'Delhi', value: 5 },
    { name: 'Kolkata', value: 6 },
    { name: 'Hyderabad', value: 7 },
    { name: 'Indore', value: 8 },
    { name: 'kashmir', value: 9 },
    { name: 'Shimla', value: 10 }
  ];
  const stateList = [
    { name: 'Gujarat', value: 1 },
    { name: 'Maharashtra', value: 2 },
    { name: 'Rajasthan', value: 3 },
    { name: 'UP', value: 4 },
    { name: 'MP', value: 5 },
    { name: 'West Bengal', value: 6 },
    { name: 'Haryana', value: 7 },
    { name: 'Assam', value: 8 },
    { name: 'Himachal pradesh', value: 9 },
    { name: 'Uttrakhand', value: 10 }
  ];
  const countryList = [
    { name: 'India', value: 1 },
    { name: 'Nepal', value: 2 },
    { name: 'Sri Lanka', value: 3 },
    { name: 'USA', value: 4 },
    { name: 'UK', value: 5 },
    { name: 'Oman', value: 6 },
    { name: 'China', value: 7 },
    { name: 'Japan', value: 8 },
    { name: 'Bhutan', value: 9 },
    { name: 'Australia', value: 10 }
  ];
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.billingdetail.formData);
  const validationSchema = BillingDetailValidationSchema;

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    dispatch(setCurrentPage(page));
  };

  // Function to handle the next click event
  const handleNextClick = (value) => {
    setPrevoiusPage(ADD_BILLING);
    goToPageHandler(SIGN_UP_SUCCESS);
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateFormData(values));
      handleNextClick();
    }
  });

  // Function to handle input change events
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
    formik.setFieldValue(name, updatedValue, true);
  };
  return (
    <>
      <div className='create-account content-animation' ref={contentRef}>
        <div className='heading'>
          <h1 className='text-white'>{t('billing-detail.enterDetail')}</h1>
        </div>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField className='form-input-email text-white' placeholder={t('billing-detail.placeholder.name')} variant='outlined' name='name' value={formik.values.name} onChange={handleInputChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField className='form-input-email text-white' placeholder={t('billing-detail.placeholder.address')} variant='outlined' name='address' value={formik.values.address} onChange={handleInputChange} error={formik.touched.address && Boolean(formik.errors.address)} helperText={formik.touched.address && formik.errors.address} />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Box>
              <FormControl className='form-input-email text-white' error>
                <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }} name='city' value={formik.values.city} onChange={handleInputChange} error={formik.touched.city && Boolean(formik.errors.city)}>
                  <MenuItem disabled value=''>
                    <em className='select-placeholder'>{t('billing-detail.placeholder.city')}</em>
                  </MenuItem>
                  {cityList.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.city && formik.errors.city}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Box>
              <FormControl className='form-input-email text-white' error>
                <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }} name='state' value={formik.values.state} onChange={handleInputChange} error={formik.touched.state && Boolean(formik.errors.state)}>
                  <MenuItem disabled value=''>
                    <em className='select-placeholder'>{t('billing-detail.placeholder.state')}</em>
                  </MenuItem>
                  {stateList.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.state && formik.errors.state}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {/* <Box> */}
            {/* <FormControl className='form-input-email text-white'> */}
            <TextField className='form-input-email text-white' placeholder={t('billing-detail.placeholder.zipcode')} variant='outlined' name='zipCode' value={formik.values.zipCode} onChange={handleInputChange} error={formik.touched.zipCode && Boolean(formik.errors.zipCode)} helperText={formik.touched.zipCode && formik.errors.zipCode} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <FormControl className='form-input-email text-white' error>
                <Select displayEmpty inputProps={{ 'aria-label': 'Without label' }} name='country' value={formik.values.country} onChange={handleInputChange} error={formik.touched.country && Boolean(formik.errors.country)}>
                  <MenuItem disabled value=''>
                    <em className='select-placeholder'>{t('billing-detail.placeholder.country')}</em>
                  </MenuItem>
                  {countryList.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {/* </form> */}
      </div>
      {!isPrevious && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='footer-button animation' ref={buttonRef}>
            <Button disableRipple variant='outlined' className='outline-back-button' onClick={() => goToPageHandler(ENTER_PAYMENT)}>
              <ArrowBackOutlinedIcon /> {t('common.goBack')}
            </Button>
            <Button disableRipple variant='contained' className='fill-button' type='submit' onClick={formik.handleSubmit}>
              {t('common.submit')} <ArrowForwardIcon />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}
export default BillingDetail;
