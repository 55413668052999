import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const smallClasses = 'text-2xl md:text-3xl lg:text-4xl xl:text-5xl';
const mediumClasses = 'text-3xl md:text-4xl lg:text-5xl xl:text-6xl';
const largeClasses = 'text-4xl md:text-5xl lg:text-6xl xl:text-7xl';

const getVariantClasses = (variant) => {
  switch (variant) {
    case 'sm':
      return smallClasses;
    case'md':
      return mediumClasses;
    case 'lg':
      return largeClasses;
    default:
      return mediumClasses;
  }
};

const Title = ({ children, tag = 'h2', variant = 'md', className, style }) => {
  const Tag = tag;
  const classes = clsx('mb-4 last:mb-0 font-display font-semibold text-balance tracking-tight', getVariantClasses(variant), className);

  return (
    <Tag className={classes} style={style}>
      {children}
    </Tag>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

Title.displayName = 'Title';

export default Title;
