import React from 'react';
import ChooseAdventure from '../components/layout/ChooseAdventure';
function Vibeset() {
  return (
    <>
      <ChooseAdventure></ChooseAdventure>
    </>
  );
}

export default Vibeset;
