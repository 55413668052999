import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert, IconButton } from '@mui/material';
import { hideToast } from '../../redux/slice/toastSlice';
import CloseIcon from '@mui/icons-material/Close';

const Toast = () => {
  const dispatch = useDispatch();
  const { isOpen, message, type } = useSelector((state) => state.toast);

  // Function to close alert message pop-up
  const handleClose = () => {
    dispatch(hideToast());
  };

  return (
    <>
      {(type == 'success' || type == 'error') && (
        <Snackbar
          open={isOpen}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity={type}
            variant='filled'
            sx={{ width: '100%' }}
            action={
              <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Toast;
