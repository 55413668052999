const songs = [
  {
    id: 0,
    song: 'i had some help (ft. morgan wallen)',
    artist: 'post malone, morgan wallen',
    tempo: 128,
    energy: 'high',
    mood: 'Happy, Uplifting',
    key: 'G Major'
  },
  {
    id: 1,
    song: 'not like us',
    artist: 'kendrick lamar',
    tempo: 101,
    energy: 'mid',
    mood: 'confrontational',
    key: 'C# Major'
  },
  {
    id: 2,
    song: 'one of wun',
    artist: 'gunna',
    tempo: 140,
    energy: 'high',
    mood: 'energetic',
    key: 'B flat minor'
  },
  {
    id: 3,
    song: 'boa',
    artist: 'megan thee stallion',
    tempo: 147,
    energy: 'high',
    mood: 'stylish',
    key: 'C Major'
  },
  {
    id: 4,
    song: 'gimmie a light',
    artist: 'ice spice',
    tempo: 156,
    energy: 'high',
    mood: 'party',
    key: 'C# Major'
  },
  {
    id: 5,
    song: 'fortnight (feat. post malone) - blond:ish remix',
    artist: 'taylor swift, post malone, blond:ish',
    tempo: 127,
    energy: 'mid',
    mood: 'mellow dance',
    key: 'B Major'
  },
  {
    id: 6,
    song: 'baddy on the floor',
    artist: 'jamie xx',
    tempo: 124,
    energy: 'high',
    mood: 'upbeat',
    key: 'F# Minor'
  },
  {
    id: 7,
    song: 'toro',
    artist: 'remi wolf',
    tempo: 109,
    energy: 'low',
    mood: 'upbeat',
    key: 'C# Major'
  },
  {
    id: 8,
    song: 'love you forever',
    artist: 'g-eazy',
    tempo: 170,
    energy: 'high',
    mood: 'upbeat',
    key: 'E Major'
  },
  {
    id: 9,
    song: 'sad girl',
    artist: 'tep no',
    tempo: 120,
    energy: 'mid',
    mood: 'stylish',
    key: 'F Minor'
  }
];

export default songs;
