import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  ADD_DOB,
  SIGN_UP_ACCOUNT,
  SIGN_UP_SUCCESS,
  SUBSCRIPTION_PLANS
} from '../../constants/PageName';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../redux/slice/vibeset';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { updateLoader, updateLoginCredential } from '../../redux/slice/login';
import { registerUser, resetFormData } from '../../redux/slice/createaccount';
import Loader from '../Loader';
import { showToast } from '../../redux/slice/toastSlice';

function SignupSucess({ contentRef, buttonRef, setPrevoiusPage, isPrevious }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.createaccount.formData);
  const loader = useSelector((state) => state.login.loading);

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    setPrevoiusPage(SIGN_UP_SUCCESS);
    dispatch(setCurrentPage(page));
  };

  // Function to handle the next click event
  const handleNextClick = async () => {
    try {
      dispatch(updateLoader(true));
      const action = await dispatch(registerUser(formData));

      const response = action.payload;
      if (response) {
        dispatch(updateLoader(false));
        dispatch(updateLoginCredential(response));
        dispatch(resetFormData(formData));
        navigate(SUBSCRIPTION_PLANS);
        dispatch(
          showToast({
            message: 'Account Created Successfully!',
            type: 'success'
          })
        );
      } else {
        setTimeout(() => {
          dispatch(updateLoader(false));
        }, 1000);
        goToPageHandler(SIGN_UP_ACCOUNT);
      }
    } catch (error) {}
  };

  return (
    <>
      {loader ? <Loader /> : ''}

      <div className='create-account'>
        <div className='heading content-animation' ref={contentRef}>
          <Box className='centerright'>
            <Box className='pinktape_cya'></Box>
            <Typography variant='h1' component='h1' className='ish1 text-upper '>
              {t('sign-up-success.title')}
            </Typography>
            <Typography variant='h2' component='h2' className='ish1below  text-white'>
              {t('sign-up-success.description')}
            </Typography>
          </Box>
        </div>
        {!isPrevious && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className='footer-button animation' ref={buttonRef}>
                <Button
                  disableRipple
                  variant='outlined'
                  className='outline-back-button'
                  onClick={() => goToPageHandler(ADD_DOB)}
                >
                  <ArrowBackOutlinedIcon /> {t('common.goBack')}
                </Button>
                <Button
                  disableRipple
                  variant='contained'
                  className='fill-button'
                  onClick={handleNextClick}
                >
                  {t('common.letsGo')} <ArrowForwardIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}

export default SignupSucess;
