import React from 'react';
import { useStore } from '@tanstack/react-store';
import { store } from '@/views/AllInOne/store';

import Title from '@/components/new/Title';
import Lead from '@/components/new/Lead';

import mediaBPM from '../assets/BPM.png';
import mediaDanceability from '../assets/Danceability.png';
import mediaKey from '../assets/Key.png';
import mediaValence from '../assets/Valence.png';

const SetlistHeader = () => {
  const { setlistTitle, mood, bpm, genre, isHarmonicActive, duration } = useStore(store);
  return (
    <div className='mb-10 flex items-start gap-12 lg:flex-row lg:items-center'>
      <div>
        <hgroup>
          <Title size={1} className='mb-6 font-mono text-xl font-normal uppercase'>
            <h3>{setlistTitle || 'My super sick set'}</h3>
          </Title>
          <Lead size={0} className='text-balance font-display tracking-wide'>
            <p>Customize your setlist by reordering, removing, replacing, or adding tracks. Vibeset will automatically suggest songs that match.</p>
          </Lead>
        </hgroup>
        <div className='mt-4 font-display text-xl'>
          <p>
            <strong>Mood</strong>: {mood || 'Not specified'}
          </p>
          <p>
            <strong>BPM</strong>: {bpm}
          </p>
          <p>
            <strong>Genre</strong>: {genre && genre.map((item, index) => (index !== genre.length - 1 ? <span key={index}>{item}, </span> : <span key={index}>{item}</span>))}
          </p>
          <p>
            <strong>Is Harmonic</strong>: {isHarmonicActive ? 'true' : 'false'}
          </p>
          <p>
            <strong>Duration</strong>: {duration}
          </p>
        </div>
      </div>
      <div className='inline-grid shrink-0 grid-cols-2 gap-4' aria-hidden={true} role='presentation'>
        <img src={mediaBPM} alt='BPM' className='h-36 w-36' />
        <img src={mediaDanceability} alt='Danceability' className='h-36 w-36' />
        <img src={mediaKey} alt='Key' className='h-36 w-36' />
        <img src={mediaValence} alt='Valence' className='h-36 w-36' />
      </div>
    </div>
  );
};

export default React.memo(SetlistHeader);
