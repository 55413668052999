import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from '@tanstack/react-store';
import { store, exportToTidal, exportToSpotify } from '@/views/AllInOne/store';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { ResizableTableContainer, ColumnResizer, Table, TableHeader, TableBody, Column, Row, Cell } from 'react-aria-components';
import { useDragAndDrop } from 'react-aria-components';

import { ArrowsCounterClockwise, ArrowRight, PlusCircle, Trash, ArrowsOutLineHorizontal, ShareFat, SpinnerGap, XCircle, DotsSixVertical } from '@phosphor-icons/react';

import PopoverContent from '@/views/AllInOne/atoms/PopoverContent';
import SetlistHeader from '@/views/AllInOne/molecules/SetlistHeader';

import TextEllipsis from '@/components/new/TextEllipsis';

import styles from '../styles.module.scss';

const SetlistTable = ({ onDeleteTrack, onReplaceTrack, onAddTrack, onReorderTracks }) => {
  const { sanitizedVibeset, sanitizedLeftovers, tidalExportLoading, spotifyExportLoading, exportError, tidalPlaylistUrl, spotifyPlaylistUrl, setlistModified } = useStore(store);

  const totalDuration = React.useMemo(() => {
    return sanitizedVibeset.reduce((total, track) => total + (track.duration_ms || 0), 0);
  }, [sanitizedVibeset]);

  const hasLeftovers = sanitizedLeftovers.length > 0;

  const handleExportToTidal = async () => {
    console.log('Exporting to Tidal');
    await exportToTidal();
    console.log('Tidal export completed, store state:', store.state);
  };

  const handleExportToSpotify = async () => {
    console.log('Exporting to Spotify');
    await exportToSpotify();
    console.log('Spotify export completed, store state:', store.state);
  };

  const { dragAndDropHooks } = useDragAndDrop({
    getItems: (keys) => {
      console.log('getItems keys:', keys);
      return Array.from(keys).map((key) => {
        const track = sanitizedVibeset.find((t) => t.id === key);
        return {
          'text/plain': `${track.track} - ${track.artist_0}`,
          'application/json': JSON.stringify({
            id: key,
            track: track.track,
            artist: track.artist_0,
          }),
        };
      });
    },
    onReorder(e) {
      console.log('onReorder event:', JSON.stringify(e, null, 2));
      const draggedKey = Array.from(e.keys)[0];
      const targetKey = e.target.key;
      const position = e.target.dropPosition;

      console.log('Reorder attempt:', { draggedKey, targetKey, position });

      onReorderTracks(draggedKey, targetKey, position);
    },
    renderDragPreview(items) {
      const item = JSON.parse(items[0]['application/json']);
      return (
        <div className={styles.dragPreview}>
          <div className={styles.dragPreviewTrack}>{item.track}</div>
          <div className={styles.dragPreviewArtist}>{item.artist}</div>
        </div>
      );
    },
  });

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  if (!sanitizedVibeset?.length) {
    return (
      <div className='mb-6'>
        <h3 className='mb-2 text-xl font-bold'>Vibeset</h3>
        <p>No tracks available.</p>
      </div>
    );
  }

  return (
    <div className='mb-6 overflow-x-auto'>
      <SetlistHeader />

      <ResizableTableContainer className={styles.setlistTable}>
        <Table aria-label='Vibeset Setlist' dragAndDropHooks={dragAndDropHooks} selectionMode='none'>
          <TableHeader className='font-display text-xl'>
            <Column defaultWidth={50} maxWidth={50}></Column>
            <Column isRowHeader defaultWidth={250} maxWidth={350} minWidth={150}>
              <div className='flex items-center justify-between text-center font-semibold capitalize'>
                Track
                <ColumnResizer className='shrink-0 cursor-ew-resize text-xl'>
                  <ArrowsOutLineHorizontal weight='bold' />
                </ColumnResizer>
              </div>
            </Column>
            <Column defaultWidth={250} maxWidth={350} minWidth={150}>
              <div className='flex items-center justify-between text-center font-semibold capitalize'>
                Artist
                <ColumnResizer className='shrink-0 cursor-ew-resize text-xl'>
                  <ArrowsOutLineHorizontal weight='bold' />
                </ColumnResizer>
              </div>
            </Column>
            <Column maxWidth={110} minWidth={110}>
              <div className='text-center font-semibold capitalize'>Duration</div>
            </Column>
            <Column>
              <div className='font-semibold capitalize'>Genre</div>
            </Column>
            <Column>
              <div className='font-semibold capitalize'>Energy</div>
            </Column>
            <Column>
              <div className='font-semibold capitalize'>Mood</div>
            </Column>
            <Column>
              <div className='font-semibold capitalize'>Key</div>
            </Column>
            <Column maxWidth={65} minWidth={65}>
              <div className='text-center font-semibold capitalize'>BPM</div>
            </Column>
            <Column maxWidth={85} minWidth={85}>
              <div className='text-center font-semibold capitalize'>Insights</div>
            </Column>
          </TableHeader>
          <TableBody items={sanitizedVibeset}>
            {(item) => (
              <Row key={item.id}>
                <Cell>
                  <div className='text-center'>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <button className='rounded-full p-1 text-center ring-off-white hover:ring-1 data-[state=open]:ring-1'>
                          <DotsSixVertical size={20} />
                        </button>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenu.Content className='w-[180px] rounded-3 bg-off-white p-1 font-semibold text-hard' side='bottom' sideOffset={6} align='start' alignOffset={-12}>
                          <DropdownMenu.Item className='flex items-center gap-2 rounded-3 py-2 pl-3 pr-2 text-sm leading-none text-red-700 outline-none hover:bg-red-500 hover:text-off-white' onSelect={() => onDeleteTrack(item.id)}>
                            <Trash weight='bold' className='text-lg' />
                            Delete Track
                          </DropdownMenu.Item>
                          <DropdownMenu.Item className='flex items-center gap-2 rounded-3 py-2 pl-3 pr-2 text-sm leading-none text-chill-700 outline-none hover:bg-chill-500 hover:text-off-white' onSelect={() => onReplaceTrack(item.id)} disabled={!hasLeftovers}>
                            <ArrowsCounterClockwise weight='bold' className='text-lg' />
                            Replace Track
                          </DropdownMenu.Item>

                          <DropdownMenu.Arrow className='fill-white' />
                        </DropdownMenu.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                  </div>
                </Cell>
                <Cell>
                  <TextEllipsis>{item.track}</TextEllipsis>
                </Cell>
                <Cell>
                  <TextEllipsis>{item.artist_0}</TextEllipsis>
                </Cell>
                <Cell className='whitespace-nowrap text-center font-mono lowercase'>{formatDuration(item.duration_ms)}</Cell>
                <Cell className='capitalize'>
                  <div className='flex items-center gap-2'>
                    <TextEllipsis>{item.genre_0}</TextEllipsis>
                  </div>
                </Cell>
                <Cell className='capitalize'>
                  <TextEllipsis>{item.energy}</TextEllipsis>
                </Cell>
                <Cell className='capitalize'>
                  <div className='flex items-center gap-2'>
                    <TextEllipsis>{item.mood_0}</TextEllipsis>
                  </div>
                </Cell>
                <Cell className='capitalize'>
                  <TextEllipsis>{item.music_key}</TextEllipsis>
                </Cell>
                <Cell className='text-center font-bold capitalize'>
                  <TextEllipsis>{item.tempo}</TextEllipsis>
                </Cell>
                <Cell className='text-center align-middle'>
                  {item.dj_insight ?
                    <PopoverContent content={item.dj_insight} isInsights={true} />
                  : <XCircle size={24} className='mx-auto text-gray-500' />}
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </ResizableTableContainer>

      <div className='mt-4 flex items-center justify-between'>
        <div className='whitespace-nowrap font-mono lowercase'>Total Duration: {formatDuration(totalDuration)}</div>
        <div className='flex gap-4'>
          {tidalExportLoading ?
            <button disabled className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500'>
              Exporting to Tidal <SpinnerGap weight='bold' className='animate-spin text-xl' />
            </button>
          : tidalPlaylistUrl && !setlistModified ?
            <a href={tidalPlaylistUrl} target='_blank' rel='noopener noreferrer' className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500 hover:border-valence-500 hover:bg-valence-500 hover:text-hard'>
              Open Tidal Playlist <ArrowRight weight='bold' className='text-xl' />
            </a>
          : <button onClick={handleExportToTidal} className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500 hover:border-valence-500 hover:bg-valence-500 hover:text-hard'>
              Export to Tidal <ShareFat weight='fill' className='text-xl' />
            </button>
          }

          {spotifyExportLoading ?
            <button disabled className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500'>
              Exporting to Spotify <SpinnerGap weight='bold' className='animate-spin text-xl' />
            </button>
          : spotifyPlaylistUrl && !setlistModified ?
            <a href={spotifyPlaylistUrl} target='_blank' rel='noopener noreferrer' className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500 hover:border-valence-500 hover:bg-valence-500 hover:text-hard'>
              Open Spotify Playlist <ArrowRight weight='bold' className='text-xl' />
            </a>
          : <button onClick={handleExportToSpotify} className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-valence-500 hover:border-valence-500 hover:bg-valence-500 hover:text-hard'>
              Export to Spotify <ShareFat weight='fill' className='text-xl' />
            </button>
          }

          <button onClick={onAddTrack} className='flex items-center gap-4 rounded-full border-1 border-current px-4 py-2 text-electric-500 hover:border-electric-500 hover:bg-electric-500 hover:text-hard' aria-label='Add track from leftovers' disabled={!hasLeftovers}>
            Add another track <PlusCircle className='text-xl' />
          </button>
        </div>
      </div>
      {exportError && <div className='mt-2 text-red-500'>{exportError}</div>}
    </div>
  );
};

SetlistTable.propTypes = {
  onDeleteTrack: PropTypes.func.isRequired,
  onReplaceTrack: PropTypes.func.isRequired,
  onAddTrack: PropTypes.func.isRequired,
  onReorderTracks: PropTypes.func.isRequired,
};

export default React.memo(SetlistTable);
