import React, { useState, useCallback, useMemo } from 'react';
import { useMotionValue } from 'framer-motion';

import songs from './songs';
import SetListTable from './SetListTable';
import { visualImages, VisualImage } from './VisualImages';
import styles from './styles.module.scss';

import GSAPParallaxSection from '@/components/new/GSAPParallaxSection';

// Test

const SONG_TO_MOVE = 1;

const SetList = () => {
  const [items, setItems] = useState(songs);
  const progress = useMotionValue(0);
  const isParallaxActive = useMotionValue(false);

  const getNewIndex = useCallback((progressValue) => {
    if (progressValue < 0.2) return 1;
    if (progressValue < 0.35) return 2;
    if (progressValue < 0.5) return 3;
    if (progressValue < 0.65) return 4;
    if (progressValue < 0.8) return 5;
    return 6;
  }, []);

  const handleScroll = useCallback(
    (progressValue) => {
      progress.set(progressValue);
      isParallaxActive.set(progressValue > 0 && progressValue < 1);

      const movingItemId = songs[SONG_TO_MOVE].id;
      const currentIndex = items.findIndex((item) => item.id === movingItemId);
      const newIndex = getNewIndex(progressValue);

      if (newIndex !== currentIndex) {
        setItems((prevItems) => {
          const newItems = [...prevItems];
          const [movedItem] = newItems.splice(currentIndex, 1);
          newItems.splice(newIndex, 0, movedItem);
          return newItems;
        });
      }
    },
    [getNewIndex, items]
  );

  const memoizedVisualImages = useMemo(
    () =>
      visualImages.map((image, index) => (
        <VisualImage key={image} image={image} index={index} progress={progress} />
      )),
    [progress]
  );

  return (
    <GSAPParallaxSection onProgressChange={handleScroll} className={styles.wrapper}>
      {memoizedVisualImages}
      <SetListTable
        items={items}
        setItems={setItems}
        songToMove={SONG_TO_MOVE}
        progress={progress}
        isParallaxActive={isParallaxActive}
      />
    </GSAPParallaxSection>
  );
};

export default SetList;