import { createSlice } from '@reduxjs/toolkit';

const InitialStateValue = {
  formData: {
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  }
};

const billingDetailSlice = createSlice({
  name: 'billingdetail',
  initialState: { ...InitialStateValue },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = InitialStateValue.formData;
    }
  }
});

export const { updateFormData, resetFormData } = billingDetailSlice.actions;

export default billingDetailSlice.reducer;
