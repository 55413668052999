/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      // https://tailwindcss.com/docs/background-image
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      borderRadius: {
        0: '0',
        0.5: '0.125rem',
        1: '0.25rem',
        1.5: '0.375rem',
        2: '0.5rem',
        2.5: '0.625rem',
        3: '0.75rem',
        3.5: '0.875rem',
        4: '1rem',
        5: '1.25rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '2rem',
        9: '2.25rem',
        10: '2.5rem',
        11: '2.75rem',
        12: '3rem',
        14: '3.5rem',
        16: '4rem',
        20: '5rem',
        24: '6rem',
        28: '7rem',
        32: '8rem',
        36: '9rem',
        40: '10rem',
        44: '11rem',
        48: '12rem',
        52: '13rem',
        56: '14rem',
        60: '15rem',
        64: '16rem',
        72: '18rem',
        80: '20rem',
        96: '24rem',
      },
      colors: {
        hard: {
          50: '#D9DADC',
          100: '#B4B5BA',
          200: '#8E9197',
          300: '#696C75',
          400: '#434752',
          500: '#1e2330',
          DEFAULT: '#1e2330',
          600: '#191D28',
          700: '#141720',
          800: '#0F1118',
          900: '#0A0B10',
          950: '#050508',
        },

        'dnb-blue': {
          50: '#D5D7EC',
          100: '#ACB0DA',
          200: '#8289C8',
          300: '#5962B6',
          400: '#2F3BA4',
          500: '#061492',
          DEFAULT: '#061492',
          600: '#051079',
          700: '#040D61',
          800: '#030A49',
          900: '#020630',
          950: '#010318',
        },

        'acid-jazz': {
          50: '#E6DDFC',
          100: '#CDBCF9',
          200: '#B49BF7',
          300: '#9B7AF4',
          400: '#8259F1',
          500: '#6938EF',
          DEFAULT: '#6938EF',
          600: '#572EC7',
          700: '#46259F',
          800: '#341C77',
          900: '#23124F',
          950: '#110927',
        },

        chill: {
          50: '#D7E7FC',
          100: '#B1CFF9',
          200: '#8AB7F7',
          300: '#639FF4',
          400: '#3C87F1',
          500: '#1570EF',
          DEFAULT: '#1570EF',
          600: '#115DC7',
          700: '#0E4A9F',
          800: '#0A3877',
          900: '#07254F',
          950: '#031227',
        },

        gaudi: {
          50: '#DDE1FD',
          100: '#BBC4FB',
          200: '#99A7F9',
          300: '#7789F7',
          400: '#556CF5',
          500: '#344FF4',
          DEFAULT: '#344FF4',
          600: '#2B41CB',
          700: '#2234A2',
          800: '#1A277A',
          900: '#111A51',
          950: '#080D28',
        },

        valence: {
          50: '#D8FBF6',
          100: '#B2F7EE',
          200: '#8CF3E6',
          300: '#66EFDE',
          400: '#40EBD6',
          500: '#1AE8CE',
          DEFAULT: '#1AE8CE',
          600: '#15C1AB',
          700: '#119A89',
          800: '#0D7467',
          900: '#084D44',
          950: '#042622',
        },

        NRG: {
          50: '#F7FBDA',
          100: '#F0F7B5',
          200: '#E8F391',
          300: '#E1EF6C',
          400: '#D9EB47',
          500: '#D2E823',
          DEFAULT: '#D2E823',
          600: '#AFC11D',
          700: '#8C9A17',
          800: '#697411',
          900: '#464D0B',
          950: '#232605',
        },

        electric: {
          50: '#EEFED4',
          100: '#DDFEAA',
          200: '#CCFE7F',
          300: '#BBFE55',
          400: '#AAFE2A',
          500: '#99FE00',
          DEFAULT: '#99FE00',
          600: '#7FD300',
          700: '#66A900',
          800: '#4C7F00',
          900: '#335400',
          950: '#192A00',
        },

        ethereal: {
          50: '#001E1E',
          100: '#003C3D',
          200: '#005B5C',
          300: '#00797B',
          400: '#00979A',
          500: '#00B6B9',
          DEFAULT: '#00B6B9',
          600: '#2AC2C4',
          700: '#55CED0',
          800: '#7FDADC',
          900: '#AAE6E7',
          950: '#D4F2F3',
        },

        peaks: {
          50: '#DCF8EA',
          100: '#BAF1D5',
          200: '#98EAC1',
          300: '#76E3AC',
          400: '#54DC97',
          500: '#32D583',
          DEFAULT: '#32D583',
          600: '#29B16D',
          700: '#218E57',
          800: '#196A41',
          900: '#10472B',
          950: '#082315',
        },

        dance: {
          50: '#F8D8FB',
          100: '#F1B1F7',
          200: '#EB8BF3',
          300: '#E464EF',
          400: '#DD3DEB',
          500: '#D717E7',
          DEFAULT: '#D717E7',
          600: '#B313C0',
          700: '#8F0F9A',
          800: '#6B0B73',
          900: '#47074D',
          950: '#230326',
        },

        'off-white': {
          50: '#FEFCF8',
          100: '#FEFAF1',
          200: '#FEF7EA',
          300: '#FDF5E3',
          400: '#FDF2DC',
          500: '#FDF0D5',
          DEFAULT: '#FDF0D5',
          600: '#D2C8B1',
          700: '#A8A08E',
          800: '#7E786A',
          900: '#545047',
          950: '#2A2823',
        },
      },
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      0.5: '0.5px',
      1: '1px',
      1.5: '1.5px',
      2: '2px',
      2.5: '2.5px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      7: '7px',
      8: '8px',
      9: '9px',
      10: '10px',
      11: '11px',
      12: '12px',
      13: '13px',
      14: '14px',
      15: '15px',
      16: '16px',
    },
    fontFamily: {
      display: ['var(--font-custom-display)', 'ui-sans-serif', 'system-ui', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"'],
      sans: ['var(--font-custom-sans)', 'ui-sans-serif', 'system-ui', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', '"Noto Color Emoji"'],
      serif: ['var(--font-custom-serif)', 'ui-serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: ['var(--font-custom-mono)', 'ui-monospace', 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'],
    },
  },
  plugins: [],
};
