import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { addGuidedMix, setCurrentPage } from '@/redux/slice/vibeset';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Letsvibebutton from '@/components/layout/Button/Letsvibe';
import BPMSlider from '@/components/new/BPMSlider';
import Text from '@/components/new/Text';

import { FEEL_OUT, LETS_VIBE, LIST_NATURAL, QUICK_MIX } from '@/constants/PageName';

const BpmRange = React.memo(() => {
  const dispatch = useDispatch();
  const vibeSetGuidedMix = useSelector((state) => state.vibeset?.guidedMix);
  const vibeSetMode = useSelector((state) => state.vibeset?.mode);
  const { t } = useTranslation();

  const initialBpmRange = useMemo(() => {
    return vibeSetGuidedMix.bpm.split(' - ').map(Number);
  }, [vibeSetGuidedMix.bpm]);

  const [bpmRange, setBpmRange] = useState(initialBpmRange);

  useEffect(() => {
    setBpmRange(initialBpmRange);
  }, [initialBpmRange]);

  const handleSliderChange = useCallback((newValue) => {
    setBpmRange(newValue);
  }, []);

  const setBpmHandler = useCallback(() => {
    if (vibeSetMode === QUICK_MIX) {
      dispatch(setCurrentPage(LIST_NATURAL));
    } else {
      const dataGuided = {
        ...vibeSetGuidedMix,
        bpm: bpmRange.join(' - ')
      };
      dispatch(addGuidedMix(dataGuided));
      dispatch(setCurrentPage(FEEL_OUT));
    }
  }, [dispatch, vibeSetMode, vibeSetGuidedMix, bpmRange]);

  const goBackHandler = useCallback(() => {
    dispatch(setCurrentPage(LETS_VIBE));
  }, [dispatch]);

  return (
    <div className='child-main bpm-main fade-in one text-white'>
      <Text.Title tag='h1' variant='md'>
        {t('setlist-guided.dial')}
      </Text.Title>
      <Text.Lead style={{ marginBlockEnd: '3em' }}>{t('setlist-guided.slider')}</Text.Lead>

      <BPMSlider start={bpmRange} onChange={handleSliderChange} />

      <div className='mt-btn-box d-flex'>
        <Letsvibebutton className='btn-med-white mr-15 bg-trans border-btn-teal-glow text-white' onClick={goBackHandler}>
          {t('common.back')}
        </Letsvibebutton>
        <Letsvibebutton onClick={setBpmHandler} className='btn-bg-teal-blue primary'>
          {t('setlist-guided.selectMood')} <ArrowForwardIcon />
        </Letsvibebutton>
      </div>
    </div>
  );
});

BpmRange.displayName = 'BpmRange';

export default BpmRange;
