import React from 'react';
import PropTypes from 'prop-types';

import Panel from '@/views/AllInOne/atoms/Panel';

import Duration from '@/views/AllInOne/molecules/Duration';
import Harmonic from '@/views/AllInOne/molecules/Harmonic';

const DialsPanel = ({ className }) => (
  <Panel className={className}>
    <div className='flex h-full flex-col justify-evenly gap-4 px-4'>
      <Duration />
      <Harmonic />
    </div>
  </Panel>
);

DialsPanel.propTypes = {
  className: PropTypes.string,
};

export default React.memo(DialsPanel);
