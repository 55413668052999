import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../redux/slice/vibeset';
import { SIGN_UP_ACCOUNT, ADD_DOB, ADD_DJ } from '../../constants/PageName';
import { updateFormData, resetFieldErrors } from '../../redux/slice/createaccount';
import { useFormik } from 'formik';
import { DjValidationSchema } from '../../validation/Formvalidation';
import gsap from 'gsap';
import { InputBox } from '../mui-components/Account';

function Dj({ contentRef, buttonRef, setPrevoiusPage, previousPage, isPrevious }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.createaccount.formData);
  const errorObject = useSelector((state) => state.createaccount.fieldErrors);
  const validationSchema = DjValidationSchema;

  React.useEffect(() => {
    if (previousPage === SIGN_UP_ACCOUNT) {
      gsap.fromTo(
        '.button-animation',
        {
          x: 100,
          y: 0,
          gap: 10
        },
        { y: 0, x: 0, duration: 1.5, ease: 'elastic' }
      );

      gsap.fromTo(
        '.button-animation-2',
        {
          x: -50,
          y: 0,
          gap: 10
        },
        { y: 0, x: 0, duration: 1.5, ease: 'elastic' }
      );
    }
  }, []);

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    setPrevoiusPage(ADD_DJ);
    dispatch(setCurrentPage(page));
  };

  // Function to handle the next click event
  const handleNextClick = async () => {
    try {
      dispatch(updateFormData(formik.values));
      goToPageHandler(ADD_DOB);
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleNextClick();
    }
  });

  // Function to handle input change events
  const handleChange = (e) => {
    formik.handleChange(e);
    dispatch(resetFieldErrors());
  };

  return (
    <>
      <div className='create-account content-animation' ref={!isPrevious ? contentRef : null}>
        <div className='heading'>
          <h1 className='text-white'>{t('dj-detail.enterDetail')}</h1>
        </div>
        {/* <form onSubmit={handleNextClick}> */}
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputBox
              className={
                (formik.touched.dj && Boolean(formik.errors.dj)) || errorObject?.dj
                  ? 'error-border form-input-email text-white'
                  : 'form-input-email text-white'
              }
              placeholder={t('dj-detail.placeholder.dj')}
              variant='outlined'
              name='dj'
              value={formik.values.dj}
              onChange={handleChange}
              error={(formik.touched.dj && Boolean(formik.errors.dj)) || errorObject?.dj}
              helperText={(formik.touched.dj && formik.errors.dj) || errorObject?.dj}
            />
          </Grid>
        </Grid>
        {/* </form> */}
      </div>
      {!isPrevious && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className='footer-button animation' ref={!isPrevious ? buttonRef : null}>
            <Button
              disableRipple
              variant='outlined'
              className='outline-back-button'
              onClick={() => goToPageHandler(SIGN_UP_ACCOUNT)}
            >
              <span
                className='button-animation d-flex'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackOutlinedIcon />
              </span>
              <span className='button-animation-2'>{t('common.goBack')}</span>
            </Button>
            <Button
              disableRipple
              variant='contained'
              className='fill-button'
              type='submit'
              onClick={!isPrevious && formik.handleSubmit}
            >
              {t('common.next')} <ArrowForwardIcon />
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
}

export default Dj;
