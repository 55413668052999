import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
  formData: {
    cardHolderName: '',
    cardNumber: '',
    expMonth: null,
    expYear: '',
    cvv: ''
  }
};

const paymnentDetailSlice = createSlice({
  name: 'paymnentdetail',
  initialState: { ...initialStateValue },
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialStateValue.formData;
    }
  }
});

export const { updateFormData, resetFormData } = paymnentDetailSlice.actions;

export default paymnentDetailSlice.reducer;
