import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableFooter,
  TablePagination,
  Button
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { setCurrentPage, songDetailHistory } from '../redux/slice/vibeset';
import Loader from '../components/Loader';
import { SONG_INFO_HISTORY } from '../constants/PageName';

function SongDetailsHistory() {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // pagination
  const [songsList, setSongsList] = useState([]);
  const [songsCount, setSongsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { id: id } = useParams();

  // Function to navigate to a specific page
  const goToPageHandler = (page) => {
    navigate(page);
  };

  // Function to handle page change
  const handlePageChange = (event, newPage) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set new page
    setPage(newPage);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    // Set loader state to indicate loading
    setLoader(true);
    // Set rows per page based on event target value
    setRowsPerPage(event.target.value);
    // Set page to 0
    setPage(0);
    // Set timeout to update loader state after delay
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  // Function to redirect to song details history page
  const redirectToSongDetails = (id) => {
    navigate('song-details-history/:' + id);
  };

  useEffect(() => {
    const getData = async () => {
      let payload = {
        page: page + 1,
        records: rowsPerPage,
        id: id
      };
      dispatch(songDetailHistory(payload))
        .then((result) => {
          let resultSet = [];
          result.payload.results.forEach((song, index) => {
            // initialize resultSet[index] as an object before assigning values, assign values to properties of resultSet[index]
            resultSet[index] = {};
            resultSet[index].name =
              song.name !== null && song.name !== undefined && song.name !== '' ? song.name : '-';
            resultSet[index].artist =
              song.artist !== null && song.artist !== undefined && song.artist !== ''
                ? song.artist
                : '-';
            resultSet[index].genre =
              song.genre !== null && song.genre !== undefined && song.genre !== ''
                ? song.genre
                : '-';
            resultSet[index].tempo =
              song.tempo !== null && song.tempo !== undefined && song.tempo !== ''
                ? song.tempo
                : '-';
            resultSet[index].mood =
              song.mood !== null && song.mood !== undefined && song.mood !== '' ? song.mood : '-';
            resultSet[index].groove =
              song.groove !== null && song.groove !== undefined && song.groove !== ''
                ? song.groove
                : '-';
          });
          setSongsList(resultSet);
          setSongsCount(result.payload.count);
        })
        .catch((error) => {});
    };
    getData();
  }, [page, rowsPerPage]);

  const loadingItems = Array.from({ length: 11 }, (_, index) => (
    <TableRow key={index}>
      {index == 0 ? (
        <TableCell className='text-teal-glow font-inter'>{t('table.hangtight')}</TableCell>
      ) : (
        <>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
          <TableCell className='text-white'>
            <div className='table-loader-data song-load'></div>
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  const renderTableRows = () => {
    if (data.vibeset.isLoading) {
      return loadingItems;
    } else if (songsList.length) {
      return songsList?.map((song, index) => (
        <TableRow key={index}>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.name}
          </TableCell>
          <TableCell className='font-inter' style={{ color: 'white' }}>
            {song.artist}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.genre}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.tempo}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.mood}
          </TableCell>
          <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
            {song.groove}
          </TableCell>
        </TableRow>
      ));
    } else {
      return (
        <>
          <TableRow>
            <TableCell
              className='mobile-hide font-inter '
              style={{ color: 'white', textAlign: 'center' }}
            >
              {t('table.noDataPresent')}
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  return (
    <Box className='child-main history-table setList fade-in one'>
      {loader ? <Loader /> : ''}
      <Box className='result-table-heading text-white song-info-history'>
        <Box className='pinktape_cya'> </Box>
        <Typography variant='h2' className='text-upper is-h2'>
          {t('table.detailHistory')}
        </Typography>
        <Button
          disableRipple
          variant='outlined'
          className='btn-med-white btn-med-white mr-15 bg-trans border-btn-teal-glow text-white'
          onClick={() => goToPageHandler(SONG_INFO_HISTORY)}
        >
          {' '}
          {t('common.goBack')}
        </Button>
      </Box>
      <Box className='table-card'>
        <Box className='result-table-content'>
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead className='font-inter'>
                <TableRow>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.name')}
                  </TableCell>
                  <TableCell className='font-inter' style={{ color: 'white' }}>
                    {t('table.header.artist')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.tempo')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.genre')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.mood')}
                  </TableCell>
                  <TableCell className='mobile-hide font-inter' style={{ color: 'white' }}>
                    {t('table.header.groove')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='font-inter'>{renderTableRows()}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                    onPageChange={handlePageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={songsCount}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='text-white'
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default SongDetailsHistory;
