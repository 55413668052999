import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export default function getTailwindValue(path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], fullConfig.theme);
}

// Usage example:
// const redColor = getTailwindValue('colors.red.700')
